import React from "react";
import {Field, FormSection} from "redux-form";
import {ButtonToolbar, Button, ButtonGroup} from "reactstrap";
import {required} from "../../../../shared/validations";
import {withTranslation} from "react-i18next";
import InputElement from "../../../../shared/components/form/Input";
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE} from "../variables";
import DataTableElement from "../../../../shared/components/common/DataTable";
import ToggleElement from "../../../../shared/components/form/Toggle";
import PartnerAddProductForm from "./PartnerAddProductForm";
import SelectElement from "../../../../shared/components/form/Select";
import ModalConfirmation from "../../../../shared/components/common/ModalConfirmation";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CloseIcon from "mdi-react/CloseIcon";
import TrashCanOutlineIcon from "mdi-react/TrashCanOutlineIcon";

class PartnerProductDataTable extends React.Component {
    state = {
        rowsPerPage: ROWS_PER_PAGE,
        pageSize: PAGE_SIZE,
        currentPage: CURRENT_PAGE,
        productsDefaultPage: 1,
        productsGoToFinalPage: false,
    };

    handleFilterData = () => {
        this.props.handleFilterData('products');
    }

    handleFilterClear = () => {
        this.props.handleFilterClear('products');
    }

    renderProductsTableColumns() {
        const {t, isEnabledFilterOptions, prodFormValues} = this.props;

        return [
            {
                name: t('partnerForm->tableColumnName'),
                selector: 'name',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.products.name"
                            id="filter.products.name"
                            component={InputElement}
                        />
                        :
                        <Field
                            name={`products[${row.internalId}].name`}
                            id={`products[${row.internalId}].name`}
                            component={InputElement}
                            validate={required}
                        />
                )
            },
            {
                name: t('partnerForm->tableColumnReference'),
                selector: 'reference',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.products.reference"
                            id="filter.products.reference"
                            component={InputElement}
                        />
                        :
                        <>
                            <Field
                                name={`products[${row.internalId}].reference`}
                                id={`products[${row.internalId}].reference`}
                                component={InputElement}
                                validate={required}
                                onBlur={() => this.props.checkProductsValues(row.internalId, 'reference')}
                            />
                            {
                                prodFormValues && prodFormValues[row.internalId].referenceNotValid &&
                                <span className="form__form-custom-error">
                                    {t('partnerForm->labelCustomErrorSameValue', {
                                        attribute: t('partnerForm->tableColumnReference'),
                                        attributePlural: ''
                                    })}
                                </span>
                            }
                        </>
                )

            },
            {
                name: t('partnerForm->tableColumnActive'),
                button: true,
                maxWidth: '200px',
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.products.isEnabled"
                            id="filter.products.isEnabled"
                            component={SelectElement}
                            options={isEnabledFilterOptions}
                            defaultValue={isEnabledFilterOptions[0]}
                        />
                        :
                        <Field
                            component={ToggleElement}
                            id={`products[${row.internalId}].isEnabled`}
                            name={`products[${row.internalId}].isEnabled`}
                            onChange={() => this.setState({changed: true})}
                        />
                )
            },
            {
                button: true,
                cell: row =>
                    <ButtonToolbar>
                        <ButtonGroup className="btn-group--icons" dir="ltr">
                            {
                                row.id === -1 ?
                                    <>
                                        <Button outline onClick={this.handleFilterData}
                                                title={t('common:buttonTitleAttributes->filter')}>
                                            <MagnifyIcon/>
                                        </Button>
                                        <Button outline color="danger" onClick={this.handleFilterClear}
                                                title={t('common:buttonTitleAttributes->clear')}>
                                            <CloseIcon/>    
                                        </Button>
                                    </>
                                    :
                                    <ModalConfirmation
                                        backdrop="static"
                                        color="danger"
                                        firstBtnText={t('modal:modal->buttonNo')}
                                        secondBtnText={t('modal:modal->buttonYes')}
                                        title={t('modal:modalDeleteTitle')}
                                        btnTitle={t('common:buttonTitleAttributes->delete')}
                                        btn={<TrashCanOutlineIcon/>}
                                        message={<p>{t('modal:modalDeleteAllMessage')}</p>}
                                        handleOnClick={() => this.props.removeItem(row, 'products')}
                                        closeOnAction
                                    />
                            }
                        </ButtonGroup>
                    </ButtonToolbar>
            }
        ]
    }

    render() {
        const {rowsPerPage, pageSize, productsDefaultPage, productsGoToFinalPage} = this.state;
        const {t, theme, products, productsToggleCleared, newProdFormValues} = this.props;

        return (
            <>
                {
                    //products.length > 0 &&
                    <div className="react-datatable__filters">
                        <DataTableElement
                            keyField="internalId"
                            theme={theme}
                            columns={this.renderProductsTableColumns()}
                            data={products}
                            paginationDefaultPage={productsDefaultPage}
                            paginationResetDefaultPage={productsGoToFinalPage}
                            paginationRowsPerPageOptions={rowsPerPage}
                            paginationPerPage={pageSize}
                            selectableRows
                            onSelectedRowsChange={this.props.handleSelectedRowsChange}
                            contextActions={this.props.handleContextActions}
                            contextMessage={{
                                singular: t('partnerForm->productSelectedRowsSingular'),
                                plural: t('partnerForm->productSelectedRowsPlural')
                            }}
                            receivePerRowsChange
                            handlePerRowsChange={this.props.handlePerRowsChange}
                            withFilters
                            selectableRowDisabled={row => row.id === -1}
                            clearSelectedRows={productsToggleCleared}
                        />
                    </div>

                }
                <FormSection name="newProduct">
                    <PartnerAddProductForm checkProductsValues={this.props.checkProductsValues} onAddProduct={product => this.props.addItem(product, 'products')} newProdFormValues={newProdFormValues}/>
                </FormSection>
            </>
        )
    }
}

export default withTranslation('partner')(PartnerProductDataTable);
