import React from "react";
import axios from "axios";
import history from "../../history";

export const FETCH_SERVICECAT = "FETCH_SERVICECAT";
export const FETCH_SERVICECATS = "FETCH_SERVICECATS";
export const CREATE_SERVICECAT = "CREATE_SERVICECAT";
export const DONE_SERVICECAT = "DONE_SERVICECAT";
export const ERROR_SERVICECAT = "ERROR_SERVICECAT";
export const PENDING_SERVICECAT = "PENDING_SERVICECAT";
export const EDIT_SERVICECAT = "EDIT_SERVICECAT";
export const TOGGLE_SERVICECAT = "TOGGLE_SERVICECAT";
export const DELETE_SERVICECAT = "DELETE_SERVICECAT";

export const fetchServiceCats = (page, pageSize, name) => async (dispatch) => {
    dispatch({type: PENDING_SERVICECAT});
    try {
        const params = {"page": page, "pageSize": pageSize, "language": "", "name": name};
        const response = await axios.get("/Categories/GetCategories", {
            headers: { language: localStorage.getItem('i18nextLng') },
            params: params,
        });
        if (response) {
            dispatch({type: FETCH_SERVICECATS, payload: response.data});
            dispatch({type: DONE_SERVICECAT, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICECAT, payload: e.message});
    }
};

export const fetchServiceCat = (id) => async (dispatch) => {
    dispatch({type: PENDING_SERVICECAT});
    try {
        const response = await axios.get(`/Categories/GetById/${id}`);
        if (response) {
            dispatch({type: FETCH_SERVICECAT, payload: response.data});
            dispatch({type: DONE_SERVICECAT});
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICECAT, payload: e.message});
    }
};

export const createServiceCat = formValues => async dispatch => {
    try {
        const response = await axios.post('/Categories', formValues);
        if (response) {
            dispatch({type: CREATE_SERVICECAT, payload: 'operationSuccess'});
            history.replace("/vvservices/categories");
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICECAT, payload: e.message});
    }
};


export const editServiceCat = formValues => async dispatch => {
    try {
        const response = await axios.put('/Categories', formValues);
        if (response) {
            dispatch({type: EDIT_SERVICECAT, payload: 'operationSuccess'});
            history.replace('/vvservices/categories');
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICECAT, payload: e.message});
    }
};

export const toggleServiceCat = formValues => async dispatch => {
    dispatch({type: PENDING_SERVICECAT});
    try {
        const response = await axios.patch('/Categories', {Id: formValues.id, IsEnabled: !formValues.isEnabled});
        if (response) {
            formValues.isEnabled = !formValues.isEnabled;
            dispatch({type: TOGGLE_SERVICECAT, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICECAT, payload: e.message});
    }
};

export const deleteServiceCat = (id, type, pageSize, currentPage) => async (dispatch) => {
    dispatch({type: PENDING_SERVICECAT});
    try {
        const response = await axios.delete(`/Categories/${id}`);
        if (response) {
            dispatch({type: DELETE_SERVICECAT, payload: "operationSuccess"});
            if (type === "list") {
                dispatch(fetchServiceCats(currentPage, pageSize));
            } else {
                history.replace("/vvservices/categories");
            }
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICECAT, payload: e.message});
    }
};
