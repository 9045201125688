import resources from './resources';

const detectionOptions = {
    order: ['cookie', 'localStorage', 'navigator', 'path', 'subdomain', 'queryString', 'htmlTag'],
    lookupFromPathIndex: 0
};

export const config = {
    fallbackLng: 'pt-PT',
    detection: detectionOptions,
    supportedLngs:['pt-PT'],
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false
    },
    resources,
    react: {
        wait: true
    },
    keySeparator: '->'
};

export {resources};