import React from "react";
import { FormSection } from "redux-form";
import { ButtonToolbar, Button, ButtonGroup } from "reactstrap";
import { withTranslation } from "react-i18next";
import { ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE } from "../variables";
import DataTableElement from "../../../../shared/components/common/DataTable";
import LocationServicesForm from "./LocationServicesForm";
import ModalConfirmation from "../../../../shared/components/common/ModalConfirmation";
import TrashCanOutlineIcon from "mdi-react/TrashCanOutlineIcon";

class LocationServicesTable extends React.Component {
    state = {
        rowsPerPage: ROWS_PER_PAGE,
        pageSize: PAGE_SIZE,
        currentPage: CURRENT_PAGE,
        servicesDefaultPage: 1,
        servicesGoToFinalPage: false,
    };

    handleToggle = (row) => {
        if (row.isPrimary)
            return;
        this.props.toggleService(row);
        this.renderServicesTableColumns();
    }

    renderServicesTableColumns() {
        const { t } = this.props;

        return [
            {
                name: t('locationForm->servicesTableName'),
                selector: 'name',
                sortable: true,
                wrap: true
            },
            {
                name: t("locationForm->servicesTablePrimary"),
                button: true,
                maxWidth: "50px",
                cell: (row) => (
                    <label className='toggle-btn customizer__toggle' htmlFor={`toggle_${row.id}`}>
                        <input
                            className='toggle-btn__input'
                            type='checkbox'
                            name={`toggle_${row.id}`}
                            id={`toggle_${row.id}`}
                            checked={row.isPrimary}
                            value={row}
                            onChange={() => this.handleToggle(row)}
                        />
                        <span className='toggle-btn__input-label' />
                    </label>
                ),
            },
            {
                button: true,
                cell: row =>
                    <ButtonToolbar>
                        <ButtonGroup className="btn-group--icons" dir="ltr">
                            <ModalConfirmation
                                backdrop="static"
                                color="danger"
                                firstBtnText={t('modal:modal->buttonNo')}
                                secondBtnText={t('modal:modal->buttonYes')}
                                title={t('modal:modalDeleteTitle')}
                                btnTitle={t('common:buttonTitleAttributes->delete')}
                                btn={<TrashCanOutlineIcon />}
                                message={<p>{t('modal:modalDeleteMessage')}</p>}
                                handleOnClick={() => this.props.removeService(row)}
                                closeOnAction
                            />
                        </ButtonGroup>
                    </ButtonToolbar>
            }
        ]
    }

    render() {
        const { rowsPerPage, pageSize, servicesDefaultPage, servicesGoToFinalPage } = this.state;
        const { t, theme, services, addService, serviceTypesOptions } = this.props;

        return (
            <>
                {
                    <div className="react-datatable__filters">
                        <DataTableElement
                            keyField="id"
                            theme={theme}
                            columns={this.renderServicesTableColumns()}
                            data={services}
                            paginationDefaultPage={servicesDefaultPage}
                            paginationResetDefaultPage={servicesGoToFinalPage}
                            paginationRowsPerPageOptions={rowsPerPage}
                            paginationPerPage={pageSize}
                        />
                    </div>

                }
                {
                    <FormSection name="addService">
                        <LocationServicesForm
                            addService={addService}
                            serviceTypesOptions={serviceTypesOptions}
                            selectedServices={services}
                        />
                    </FormSection>
                }
            </>
        )
    }
}
export default withTranslation('location')(LocationServicesTable);
