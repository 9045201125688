import React from "react";
import {Field} from "redux-form";
import {Row, Col, Button} from "reactstrap";
import {withTranslation} from "react-i18next";
import InputElement from "../../../../shared/components/form/Input";

class PartnerAddPOSForm extends React.Component {
    state = {
        newPOS: {},
    };

    addPOS = () => {
        this.setState({newPOS: {}})
        this.props.onAddPOS(this.state.newPOS);
    }

    render() {
        const {newPOS} = this.state;
        const {t, newPOSFormValues} = this.props;

        return (
            <>
                <p className='bold-text'>{t("partnerForm->labelNewPOS")}</p>
                <Row className="align-items-end">
                    <Col sm={4}>
                        <Field
                            name="posName"
                            id="posName"
                            component={InputElement}
                            label={t('partnerForm->tableColumnName')}
                            onChange={e => this.setState(state => ({
                                newPOS: {
                                    ...state.newPOS,
                                    posName: e.target.value
                                }
                            }))}
                            className={newPOSFormValues && newPOSFormValues.nameNotValid ? 'mb-0' : ''}
                            onBlur={() => this.props.checkPointOfSaleValues(-1, 'name', newPOS)}
                        />
                        {
                            newPOSFormValues && newPOSFormValues.nameNotValid &&
                            <span className="form__form-group-error">
                                {t('partnerForm->labelCustomErrorSameValue', {
                                    attribute: t('partnerForm->tableColumnName'),
                                    attributePlural: 's'
                                })}
                            </span>
                        }
                    </Col>
                    <Col sm={3}>
                        <Field
                            name="posReference"
                            id="posReference"
                            component={InputElement}
                            label={t('partnerForm->tableColumnReference')}
                            onChange={e => this.setState(state => ({
                                newPOS: {
                                    ...state.newPOS,
                                    posReference: e.target.value
                                }
                            }))}
                            className={newPOSFormValues && newPOSFormValues.referenceNotValid ? 'mb-0' : ''}
                            onBlur={() => this.props.checkPointOfSaleValues(-1, 'reference', newPOS)}
                        />
                        {
                            newPOSFormValues && newPOSFormValues.referenceNotValid &&
                            <span className="form__form-group-error">
                                {t('partnerForm->labelCustomErrorSameValue', {
                                    attribute: t('partnerForm->tableColumnReference'),
                                    attributePlural: 's'
                                })}
                            </span>
                        }
                    </Col>
                    <Col sm={2}>
                        <Field
                            name="posLatitude"
                            id="posLatitude"
                            component={InputElement}
                            label={t('partnerForm->tableColumnLatitude')}
                            isLatitudeLongitude
                            onChange={value => this.setState(state => ({
                                newPOS: {
                                    ...state.newPOS,
                                    posLatitude: value
                                }
                            }))}
                            className={newPOSFormValues && newPOSFormValues.latitudeNotValid ? 'mb-0' : ''}
                            onBlur={() => this.props.checkPointOfSaleValues(-1, 'latitude', newPOS)}
                        />
                        {
                            newPOSFormValues && newPOSFormValues.latitudeNotValid &&
                            <span className="form__form-group-error">
                                {t('partnerForm->labelCustomErrorSameValue', {attribute: t('partnerForm->labelCoordinates'), attributePlural: 's'})}
                            </span>
                        }
                    </Col>
                    <Col sm={2}>
                        <Field
                            name="posLongitude"
                            id="posLongitude"
                            component={InputElement}
                            label={t('partnerForm->tableColumnLongitude')}
                            isLatitudeLongitude
                            onChange={value => this.setState(state => ({
                                newPOS: {
                                    ...state.newPOS,
                                    posLongitude: value
                                }
                            }))}
                            onBlur={() => this.props.checkPointOfSaleValues(-1, 'longitude', newPOS)}
                        />
                    </Col>
                    <Col sm={1}>
                        <Button
                            id="btnAddPOS"
                            color="primary"
                            type="button"
                            onClick={this.addPOS}
                            disabled={
                                !newPOS.posName || (newPOSFormValues && newPOSFormValues.nameNotValid)
                                || !newPOS.posReference || (newPOSFormValues && newPOSFormValues.referenceNotValid)
                                || !newPOS.posLatitude || (newPOSFormValues && newPOSFormValues.latitudeNotValid)
                                || !newPOS.posLongitude || (newPOSFormValues && newPOSFormValues.longitudeNotValid)
                            }
                        >
                            {t('partnerForm->btnAdd')}
                        </Button>
                    </Col>
                </Row>
            </>

        );
    }
}

export default withTranslation("partner")(PartnerAddPOSForm);


