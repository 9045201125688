import React from 'react';
import {Link} from 'react-router-dom';
import {Field} from 'redux-form';
import {Button, ButtonToolbar, ButtonGroup} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import DataTableElement from '../../../../shared/components/common/DataTable';
import InputElement from '../../../../shared/components/form/Input';
import SelectElement from '../../../../shared/components/form/Select';
import Moment from "react-moment";
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'; 

class VoucherCampaignsDataTable extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state;
    }

    handlePageChange = (currentPage, pageSize) => {
        this.setState({currentPage, pageSize});
        this.props.handlePageChange(currentPage, pageSize);
    };

    handlePerRowsChange = (currentPage, pageSize) => {
        this.setState({currentPage, pageSize});
        this.props.handlePageChange(currentPage, pageSize);
    };

    handleFilterData = () => {
        this.props.handleFilterData();
    }

    handleFilterClear = () => {
        this.props.handleFilterClear();
    }

    renderTableColumns() {
        const {t, isEnabledFilterOptions} = this.props;

        return [
            {
                name: t('voucherCampaigns->tableColumnName'),
                selector: 'name',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.name"
                            id="filter.name"
                            component={InputElement}
                        />
                        :
                        row.name
                )
            },
            {
                name: t('voucherCampaigns->tableColumnOnGoingCampaign'),
                selector: 'partnerCampaignInfo.hasActiveCampaigns',
                sortable: true,
                maxWidth: '250px',
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.hasActiveCampaigns"
                            id="filter.hasActiveCampaigns"
                            component={SelectElement}
                            options={isEnabledFilterOptions}
                            defaultValue={isEnabledFilterOptions[1]}
                        />
                        :
                        row.partnerCampaignInfo.hasActiveCampaigns ?
                            <span>
                                {t('voucherCampaigns->tableCellOnGoingCampaignYes')} ({row.partnerCampaignInfo.activeCampaignEndDate ? <>{t('voucherCampaigns->tableCellCampaignUntil')} <Moment format="DD/MM/YYYY" date={row.partnerCampaignInfo.activeCampaignEndDate}/></> : <span>&infin;</span>})
                            </span>
                            : t('voucherCampaigns->tableCellOnGoingCampaignNo')
                )
            },
            {
                button: true,
                minWidth: '200px',
                cell: row => (
                    row.id === -1 ?
                        <ButtonToolbar>
                            <ButtonGroup className="btn-group--icons" dir="ltr">
                                <Button outline onClick={this.handleFilterData} title={t('common:buttonTitleAttributes->filter')}>
                                    <MagnifyIcon/>
                                </Button>
                                <Button outline color="danger" onClick={this.handleFilterClear} title={t('common:buttonTitleAttributes->clear')}>
                                    <CloseIcon/>
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                        :
                        <Link
                            to={`/vv/voucher/campaigns/${row.name}/${row.partnerId}`}>{t('voucherCampaigns->tableBtnSeeCampaigns')}
                            <ChevronRightIcon className="ml-2"/>
                        </Link>
                )
            }
        ]
    }

    render() {
        const {data, paginationTotalRows, theme, rowsPerPage, pageSize, currentPage, paginationDefaultPage} = this.props;

        return (
            <div className="form react-datatable__filters">
                <DataTableElement
                    theme={theme}
                    keyField="id"
                    columns={this.renderTableColumns()}
                    data={data}
                    defaultSortField="name"
                    noHeader
                    paginationServer
                    paginationTotalRows={paginationTotalRows}
                    handlePerRowsChange={this.handlePerRowsChange}
                    handlePageChange={this.handlePageChange}
                    paginationRowsPerPageOptions={rowsPerPage}
                    paginationPerPage={pageSize}
                    currentPage={currentPage}
                    receivePerRowsChange
                    withFilters
                    paginationDefaultPage={paginationDefaultPage}
                />
            </div>
        )
    }
}

export default withTranslation('voucher')(VoucherCampaignsDataTable);