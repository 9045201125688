import React from 'react';
import {Link} from 'react-router-dom';
import {Field} from 'redux-form';
import {Button, ButtonToolbar, ButtonGroup} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import DataTableElement from '../../../../shared/components/common/DataTable';
import InputElement from '../../../../shared/components/form/Input';
import SelectElement from '../../../../shared/components/form/Select';
import PencilOutlineIcon from 'mdi-react/PencilOutlineIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import CloseIcon from 'mdi-react/CloseIcon';

class PartnerListDataTable extends React.Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state;
    }

    handlePageChange = (currentPage, pageSize) => {
        this.setState({currentPage, pageSize});
        this.props.handlePageChange(currentPage, pageSize);
    };

    handlePerRowsChange = (currentPage, pageSize) => {
        this.setState({currentPage, pageSize});
        this.props.handlePageChange(currentPage, pageSize);
    };

    handleFilterData = () => {
        this.props.handleFilterData();
    }

    handleFilterClear = () => {
        this.props.handleFilterClear();
    }

    renderTableColumns() {
        const {t, isEnabledFilterOptions} = this.props;

        return [
            {
                name: t('partnerList->tableColumnName'),
                selector: 'name',
                sortable: true,
                cell: row => row.id === -1
                    ?
                    <Field
                        name="filter.name"
                        id="filter.name"
                        component={InputElement}
                    />
                    :
                    row.name
            },
            {
                name: t('partnerList->tableColumnProducts'),
                selector: 'productsInfo.countItems',
                sortable: true,
                cell: row => row.id === -1 ? null : row.productsInfo.totalItems > 0 ? `${row.productsInfo.countItems} (${t('partnerList->tableRowLabelOf')} ${row.productsInfo.totalItems})` : t('common:labelNotAvailable')

            },
            {
                name: t('partnerList->tableColumnPOS'),
                selector: 'pointsOfSaleInfo.countItems',
                sortable: true,
                cell: row => row.id === -1 ? null : row.pointsOfSaleInfo.totalItems > 0 ? `${row.pointsOfSaleInfo.countItems} (${t('partnerList->tableRowLabelOf')} ${row.pointsOfSaleInfo.totalItems})` : t('common:labelNotAvailable')

            },
            {
                name: t('partnerList->tableColumnActive'),
                button: true,
                maxWidth: '200px',
                cell: row =>
                    row.id === -1 ?
                        <Field
                            name="filter.isEnabled"
                            id="filter.isEnabled"
                            component={SelectElement}
                            options={isEnabledFilterOptions}
                            defaultValue={isEnabledFilterOptions[0]}
                        />
                        :
                        <label className="toggle-btn customizer__toggle" htmlFor={`toggle_${row.id}`}>
                            <input
                                className="toggle-btn__input"
                                type="checkbox"
                                name={`toggle_${row.id}`}
                                id={`toggle_${row.id}`}
                                checked={row.isEnabled}
                                value={row}
                                onChange={() => this.props.handleToggle(row)}
                            />
                            <span className="toggle-btn__input-label"/>
                        </label>
            },
            {
                button: true,
                cell: row => (
                    row.id === -1 ?
                        <ButtonToolbar>
                            <ButtonGroup className="btn-group--icons" dir="ltr">
                                <Button
                                    outline
                                    onClick={this.handleFilterData}
                                    title={t('common:buttonTitleAttributes->filter')}>
                                    <MagnifyIcon/>
                                </Button>
                                <Button
                                    outline
                                    color="danger"
                                    onClick={this.handleFilterClear}
                                    title={t('common:buttonTitleAttributes->clear')}>
                                    <CloseIcon/>
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                        :
                        <ButtonToolbar>
                            <ButtonGroup className="btn-group--icons" dir="ltr">
                                <Link 
                                    to={`/vv/partner/${row.partnerId}/edit/${row.id}`}
                                    className="btn btn-outline-secondary"
                                    title={t('common:buttonTitleAttributes->edit')}
                                >
                                    <PencilOutlineIcon/>
                                </Link>
                            </ButtonGroup>
                        </ButtonToolbar>
                )
            }
        ]
    }

    render() {
        const {data, paginationTotalRows, theme, rowsPerPage, pageSize, currentPage, paginationDefaultPage} = this.props;

        return (
            <div className="form react-datatable__filters">
                <DataTableElement
                    keyField="id"
                    theme={theme}
                    data={data}
                    columns={this.renderTableColumns()}
                    defaultSortField="name"
                    noHeader
                    paginationServer
                    paginationTotalRows={paginationTotalRows}
                    handlePerRowsChange={this.handlePerRowsChange}
                    handlePageChange={this.handlePageChange}
                    paginationRowsPerPageOptions={rowsPerPage}
                    paginationPerPage={pageSize}
                    currentPage={currentPage}
                    receivePerRowsChange
                    withFilters
                    paginationDefaultPage={paginationDefaultPage}
                />
            </div>
        )
    }
}

export default withTranslation('partner')(PartnerListDataTable);