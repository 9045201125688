import React from "react";
import axios from "axios";
import history from "../../history";

export const FETCH_LOCATION = "FETCH_LOCATION";
export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const CREATE_LOCATION = "CREATE_LOCATION";
export const DONE_LOCATION = "DONE_LOCATION";
export const ERROR_LOCATION = "ERROR_LOCATION";
export const PENDING_LOCATION = "PENDING_LOCATION";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const TOGGLE_LOCATION = "TOGGLE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";

export const fetchLocations = (page, pageSize, name) => async (dispatch) => {
    dispatch({type: PENDING_LOCATION});
    try {
        const params = {"page": page, "pageSize": pageSize, "language": "", "name": name};
        const response = await axios.get("/Locations/GetLocations", {
            headers: { language: localStorage.getItem('i18nextLng') },
            params: params,
        });
        if (response) {
            dispatch({type: FETCH_LOCATIONS, payload: response.data});
            dispatch({type: DONE_LOCATION, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_LOCATION, payload: e.message});
    }
};

export const fetchLocation = (id) => async (dispatch) => {
    dispatch({type: PENDING_LOCATION});
    try {
        const response = await axios.get(`/Locations/GetById/${id}`);
        if (response) {
            dispatch({type: FETCH_LOCATION, payload: response.data});
            dispatch({type: DONE_LOCATION});
        }
    } catch (e) {
        dispatch({type: ERROR_LOCATION, payload: e.message});
    }
};

export const createLocation = formValues => async dispatch => {
    try {
        const response = await axios.post('/Locations', formValues);
        if (response) {
            dispatch({type: CREATE_LOCATION, payload: 'operationSuccess'});
            history.replace("/vvservices/location");
        }
    } catch (e) {
        dispatch({type: ERROR_LOCATION, payload: e.message});
    }
};


export const editLocation = formValues => async dispatch => {
    try {
        const response = await axios.put('/Locations', formValues);
        if (response) {
            dispatch({type: EDIT_LOCATION, payload: 'operationSuccess'});
            history.replace('/vvservices/location');
        }
    } catch (e) {
        dispatch({type: ERROR_LOCATION, payload: e.message});
    }
};

export const toggleLocation = formValues => async dispatch => {
    dispatch({type: PENDING_LOCATION});
    try {
        const response = await axios.patch('/Locations', {Id: formValues.id, IsEnabled: !formValues.isEnabled});
        if (response) {
            formValues.isEnabled = !formValues.isEnabled;
            dispatch({type: TOGGLE_LOCATION, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_LOCATION, payload: e.message});
    }
};

export const deleteLocation = (id, type, pageSize, currentPage) => async (dispatch) => {
    dispatch({type: PENDING_LOCATION});
    try {
        const response = await axios.delete(`/Locations/${id}`);
        if (response) {
            dispatch({type: DELETE_LOCATION, payload: "operationSuccess"});
            if (type === "list") {
                dispatch(fetchLocations(currentPage, pageSize));
            } else {
                history.replace("/vvservices/location");
            }
        }
    } catch (e) {
        dispatch({type: ERROR_LOCATION, payload: e.message});
    }
};
