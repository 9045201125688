import React from "react";
import {Col, Row} from "reactstrap";
import {Field} from "redux-form";
import {withTranslation} from "react-i18next";
import {required, requiredOneOfField} from "../../../../shared/validations";
import DateTimePickerElement from "../../../../shared/components/form/DateTimePicker";
import CheckBoxElement from "../../../../shared/components/form/CheckBox";

class VoucherTimePeriod extends React.Component {
    state = {
        days: {}
    }
    
    render() {
        const {t, initialValues, isEditable} = this.props;
        const {days: {monday, tuesday, wednesday, thursday, friday, saturday, sunday}} = this.state;
        
        return (
            <>
                <div className='form__form-group'>
                    <span className='form__form-group-label'>{t("voucherForm->labelDays")}</span>
                    <div className='form__form-group-field'>
                        <Field
                            id="voucherCampaignsActiveHours.monday"
                            name="voucherCampaignsActiveHours.monday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.voucherCampaignsActiveHours.monday}
                            label={t('common->labelMonday')}
                            className="colored-click"
                            validate={
                                monday ||
                                tuesday ||
                                wednesday ||
                                thursday ||
                                friday ||
                                saturday ||
                                sunday ? [] : [requiredOneOfField]
                            }
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, monday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="voucherCampaignsActiveHours.tuesday"
                            name="voucherCampaignsActiveHours.tuesday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.voucherCampaignsActiveHours.tuesday}
                            label={t('common->labelTuesday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, tuesday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="voucherCampaignsActiveHours.wednesday"
                            name="voucherCampaignsActiveHours.wednesday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.voucherCampaignsActiveHours.wednesday}
                            label={t('common->labelWednesday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, wednesday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="voucherCampaignsActiveHours.thursday"
                            name="voucherCampaignsActiveHours.thursday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.voucherCampaignsActiveHours.thursday}
                            label={t('common->labelThursday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, thursday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="voucherCampaignsActiveHours.friday"
                            name="voucherCampaignsActiveHours.friday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.voucherCampaignsActiveHours.friday}
                            label={t('common->labelFriday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, friday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="voucherCampaignsActiveHours.saturday"
                            name="voucherCampaignsActiveHours.saturday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.voucherCampaignsActiveHours.saturday}
                            label={t('common->labelSaturday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, saturday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="voucherCampaignsActiveHours.sunday"
                            name="voucherCampaignsActiveHours.sunday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.voucherCampaignsActiveHours.sunday}
                            label={t('common->labelSunday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, sunday: value}}))}
                            disabled={!isEditable}
                        />
                    </div>
                </div>
                <Row>
                    <Col sm={6}>
                        <Field
                            name="voucherCampaignsActiveHours.beginTime"
                            id="voucherCampaignsActiveHours.beginTime"
                            component={DateTimePickerElement}
                            showTimeSelect
                            label={t('voucherForm->labelBeginTime')}
                            showTimeSelectOnly
                            dateFormat="HH:mm"
                            startDate={new Date(initialValues.voucherCampaignsActiveHours.beginTime)}
                            validate={required}
                            disabled={!isEditable}
                        />
                    </Col>
                    <Col sm={6}>
                        <Field
                            name="voucherCampaignsActiveHours.endTime"
                            id="voucherCampaignsActiveHours.endTime"
                            component={DateTimePickerElement}
                            showTimeSelect
                            label={t('voucherForm->labelEndTime')}
                            showTimeSelectOnly
                            dateFormat="HH:mm"
                            startDate={new Date(initialValues.voucherCampaignsActiveHours.endTime)}
                            validate={required}
                            disabled={!isEditable}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default withTranslation("voucher")(VoucherTimePeriod);

