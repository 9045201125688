import _ from 'lodash';
import {
    CREATE_VOUCHER,
    DELETE_VOUCHER,
    DONE_VOUCHER,
    EDIT_VOUCHER,
    ERROR_VOUCHER,
    FETCH_VOUCHER,
    FETCH_VOUCHERS,
    FETCH_VOUCHER_LIST,
    PENDING_VOUCHER,
    TOGGLE_VOUCHER,
    IMPORT_VOUCHER,
    FETCH_VOUCHER_VERSION_LIST,
    EXPORT_VOUCHER_CAMPAIGN,
    FETCH_VOUCHERS_LIST,
    LIST_VOUCHERS_EXPORT
} from '../actions/voucherActions';

const initialState = {
    done: {
        status: false,
        message: null
    },
    data: {
        vouchers: [],
        totalItems: null
    },
    importedFile: {},
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_VOUCHERS:
            return {
                ...state,
                data: {
                    vouchers: _.mapKeys(action.payload.partners, 'id'),
                    totalItems: action.payload.total
                }
            };
        case FETCH_VOUCHER_LIST:
            return {
                ...state,
                data: {
                    vouchers: action.payload.voucherCampaign,
                    totalItems: action.payload.total
                }
            };
        case FETCH_VOUCHER:
            return {
                ...state,
                data: {
                    vouchers: {[action.payload.id]: action.payload}
                }
            };
        case CREATE_VOUCHER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case EDIT_VOUCHER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case TOGGLE_VOUCHER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DELETE_VOUCHER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DONE_VOUCHER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case PENDING_VOUCHER:
            return {...state, done: {status: false, message: null}, error: null};
        case ERROR_VOUCHER:
            return {...state, done: {status: true, message: null}, error: action.payload};
        case IMPORT_VOUCHER:
            return {...state, importedFile: action.payload};
        case FETCH_VOUCHER_VERSION_LIST:
            return {
                ...state,
                data: {
                    vouchers: action.payload.itemsList,
                    totalItems: action.payload.total
                }
            };
        case EXPORT_VOUCHER_CAMPAIGN:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case FETCH_VOUCHERS_LIST:
            return {
                ...state,
                data: {
                    vouchers: action.payload.itemsList,
                    totalItems: action.payload.totalItems
                }
            };
        case LIST_VOUCHERS_EXPORT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        default:
            return state;
    }
}