import axios from 'axios';

export const FETCH_POINTSOFSALES = 'FETCH_POINTSOFSALES';
export const FETCH_POINTSOFSALE = 'FETCH_POINTSOFSALE';
export const CREATE_POINTSOFSALE = 'CREATE_POINTSOFSALE';
export const EDIT_POINTSOFSALE = 'EDIT_POINTSOFSALE';
export const TOGGLE_POINTSOFSALE = 'TOGGLE_POINTSOFSALE';
export const DELETE_POINTSOFSALE = 'DELETE_POINTSOFSALE';
export const DONE_POINTSOFSALE = 'DONE_POINTSOFSALE';
export const PENDING_POINTSOFSALE = 'PENDING_POINTSOFSALE';
export const ERROR_POINTSOFSALE = 'ERROR_POINTSOFSALE';
export const FETCH_ALL_POINTSOFSALE = 'FETCH_ALL_POINTSOFSALE';

export const fetchPointsOfSales = (partnerId, page, pageSize) => async dispatch => {
    dispatch({type: PENDING_POINTSOFSALE});
    try {
        const params = { "partnerId": partnerId, "page": page, "pageSize": pageSize };
        const response = await axios.get('/PointsOfSale/GetPointsOfSale', { headers: { language: localStorage.getItem('i18nextLng')}, params: params});
        if (response) {
            dispatch({type: FETCH_POINTSOFSALES, payload: response.data});
            dispatch({type: DONE_POINTSOFSALE, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_POINTSOFSALE, payload: e.message});
    }
};

export const createPointsOfSale = formValues => async dispatch => {
    try {
        const response = await axios.post('/PointsOfSale', formValues);
        if (response) {
            dispatch({type: CREATE_POINTSOFSALE, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_POINTSOFSALE, payload: e.message});
    }
};

export const editPointsOfSale = formValues => async dispatch => {
    try {
        const response = await axios.put('/PointsOfSale', formValues);
        if (response) {
            dispatch({type: EDIT_POINTSOFSALE, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_POINTSOFSALE, payload: e.message});
    }
};

export const togglePointsOfSale = formValues => async dispatch => {
    dispatch({type: PENDING_POINTSOFSALE});
    try {
        const response = await axios.patch('/PointsOfSale', {Id: formValues.id, IsEnabled: !formValues.isEnabled});
        if (response) {
            formValues.isEnabled = !formValues.isEnabled;
            dispatch({type: TOGGLE_POINTSOFSALE, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_POINTSOFSALE, payload: e.message});
    }
};

export const deletePointsOfSale = posArray => async dispatch => {
    dispatch({type: PENDING_POINTSOFSALE});
    try {
        const response = await axios.delete(`/PointsOfSale`, { data: posArray });
        if (response) {
            dispatch({type: DELETE_POINTSOFSALE, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_POINTSOFSALE, payload: e.message});
    }
};

export const fetchAllPointsOfSale = (partnerId) => async dispatch => {
    dispatch({type: PENDING_POINTSOFSALE});
    try {
        const params = {partnerId};
        const response = await axios.get('/PointsOfSale/GetAll', { headers: { language: localStorage.getItem('i18nextLng')}, params: params});
        if (response) {
            dispatch({type: FETCH_ALL_POINTSOFSALE, payload: response.data});
            dispatch({type: DONE_POINTSOFSALE, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_POINTSOFSALE, payload: e.message});
    }
};