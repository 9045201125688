import React from "react";
import {connect} from "react-redux";
import {Field, reduxForm, formValueSelector, getFormSyncErrors} from "redux-form";
import {Link} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    ButtonToolbar,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import { required, minLength11, maxLength12, maxLength175 } from "../../../shared/validations";
import { cloneDeep } from "lodash";
import classnames from "classnames";
import {withTranslation} from "react-i18next";
import CheckBoxElement from "../../../shared/components/form/CheckBox";
import LoadingElement from "../../../shared/components/common/Loading";
import SelectElement from "../../../shared/components/form/Select";
import HiddenElement from "../../../shared/components/form/Hidden";
import ModalConfirmation from "../../../shared/components/common/ModalConfirmation";
import InputElement from "../../../shared/components/form/Input";
import TextAreaElement from "../../../shared/components/form/TextArea";
import axios from "axios";
import {POSTAL_CODE_MASK} from "./variables";
import LocationServicesTable from "./LocationFormTabs/LocationServicesTable";
import LocationRelatedTable from "./LocationFormTabs/LocationRelatedTable";

class LocationForm extends React.Component {
    state = {
        isLoading: false,
        activeTab: 0,
        activeLowerTab: 0,
        lowerTabs: [
            this.props.t('locationForm->lowerTabServices'),
            this.props.t('locationForm->lowerTabRelatedLocations')
        ],
        languages: this.props.languages,
        freeways: {
            data: [],
            done: false
        },
        serviceType: -1,
        services: this.props.initialValues.serviceTypes ? cloneDeep(this.props.initialValues.serviceTypes) : [],
        relatedLocations: this.props.initialValues.relatedLocations ? cloneDeep(this.props.initialValues.relatedLocations) : [],
    };

    componentDidMount = () => {
        // Check current primaryService 
        const primaryService = this.props.initialValues.serviceTypes ? this.props.initialValues.serviceTypes.find(s => s.isPrimary) : null;
        this.setServiceType(primaryService);
    }

    setServiceType = (primaryService) => {
        // Set the current service type
        if (!primaryService) {
            this.setState({
                serviceType: -1
            });
            return;
        }
        const primaryServiceId = primaryService.id.toString();
        const service = this.props.serviceTypesOptions.find(s => s.key === primaryServiceId);
        this.setState({
            serviceType: service ? parseInt(service.type) : -1
        });
    }

    onSubmit = (formValues) => {
        const { type } = this.props;
        const { services, relatedLocations, serviceType } = this.state;

        let formToPost = {
            isEnabled: formValues.isEnabled,
            latitude: formValues.latitude,
            longitude: formValues.longitude,
            translations: formValues.translations,
            relatedLocations: relatedLocations,
            serviceTypes: services
        };

        if (serviceType === 0 || serviceType === 2 || serviceType === 13 || serviceType == 27) {
            formValues.address && (formToPost.address = formValues.address);
            formValues.postalCode && (formToPost.postalCode = formValues.postalCode);
            formValues.city && (formToPost.city = formValues.city);
        }

        if (serviceType === 1) {
            formToPost.freewayId = parseInt(formValues.freewayId.value);
            formToPost.speedLimit = formValues.speedLimit;
            formToPost.kmPoint = formValues.kmPoint;
            formToPost.kmPoint = formValues.kmPoint;
        }

        serviceType === 2 && (formToPost.isPartner = formValues.isPartner);
        type === "edit" && (formToPost.id = formValues.id);

        this.props.onSubmit(formToPost);
    };

    handleModalDelete = (id) => {
        this.props.onDelete(id);
    };

    toggleTab = (tab) => {
        const {activeTab} = this.state;
        activeTab !== tab && this.setState({activeTab: tab});
    };

    toggleLowerTab = (tab) => {
        const { activeLowerTab } = this.state;
        activeLowerTab !== tab && this.setState({ activeLowerTab: tab });
    };

    checkErrorInTabs() {
        const {formSyncErrors} = this.props;

        if (formSyncErrors.translations) {
            formSyncErrors.translations.some((item, i) => {
                if (item) {
                    this.state.activeTab !== i && this.setState({activeTab: i});
                    return true;
                }
            })
        }
    }

    addService = (service) => {
        // don't allow duplicates
        if (this.state.services.some(s => s.id === service.value))
            return;
        const newService = {
            id: parseInt(service.value),
            name: service.label,
            isPrimary: this.state.services.length === 0
        }
        if (this.state.services.length === 0)
            this.setServiceType(newService);
        this.setState({
            services: [...this.state.services, newService]
        });
    }

    addRelatedLocation = (location) => {
        if (this.state.relatedLocations.some(l => l.id === location.value))
            return;
        this.setState({
            relatedLocations: [
                ...this.state.relatedLocations,
                {
                    id: parseInt(location.value),
                    name: location.label
                }
            ]
        });
    }

    removeService = (service) => {
        const newServices = this.state.services.filter(s => s.id !== service.id);
        if (service.isPrimary) {
            if (newServices.length > 0) {
                newServices[0].isPrimary = true;
                this.setServiceType(newServices[0]);
            } else {
                this.setServiceType(null);
            }
        }
        this.setState({
            services: newServices
        });
    }

    removeRelatedLocation = (location) => {
        this.setState({
            relatedLocations: this.state.relatedLocations.filter(l => l.id !== location.id)
        })
    }

    toggleService = (service) => {
        if (service.isPrimary)
            return;
        this.setServiceType(service);
        this.setState({
            services: this.state.services.map(s => {
                if (s.id === service.id)
                    s.isPrimary = true;
                else
                    s.isPrimary = false;
                return s;
            })
        });
    }

    async fetchHighwaysOptions() {
        if (this.state.freeways.data.length === 0) {
            try {
                const response = await axios.get('/Locations/GetFreeways');
                if (response) {
                    const options = response.data.map(item => {
                        return {
                            label: item.name,
                            value: `${item.id}`
                        }
                    });
                    this.setState({freeways: {data: options, done: true}});
                    const {initialValues} = this.props;
                    initialValues.freewayId &&
                    options.map(item => parseInt(item.value) === parseInt(initialValues.freewayId.value) && this.props.change('freewayId', item));
                }
            } catch (e) {
                //render error
                console.log(e);
            }
        }
    }

    renderTabHeader() {
        const {activeTab, languages} = this.state;

        return (
            languages.map((item, index) => {
                return (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({active: activeTab === index})} onClick={() => this.toggleTab(index)}>
                            {item.label}
                        </NavLink>
                    </NavItem>
                )
            })
        );
    }

    renderTabContent() {
        const { languages, serviceType} = this.state;
        const {t} = this.props;

        return (
            languages.map((item, index) => {
                return (
                    <TabPane key={index} tabId={index}>
                        <Field
                            name={`translations[${index}].language`}
                            id={`translations[${index}].language`}
                            component={HiddenElement}
                            hiddenValue={item.value}
                        />
                        <Field
                            name={`translations[${index}].name`}
                            id={`translations[${index}].name`}
                            component={InputElement}
                            label={serviceType === 1 ? t('locationForm->labelHighwayName') : t('locationForm->labelName')}
                            placeholder={serviceType === 1 ? t('locationForm->labelHighwayName') : ''}
                            validate={[required, maxLength175]}
                        />
                        {
                            (serviceType !== 1) &&
                            <>
                                <Field
                                    name={`translations[${index}].schedule`}
                                    id={`translations[${index}].schedule`}
                                    component={TextAreaElement}
                                    label={t('locationForm->labelSchedule')}
                                />
                                <Field
                                    name={`translations[${index}].additionalInfo`}
                                    id={`translations[${index}].additionalInfo`}
                                    component={TextAreaElement}
                                    label={t('locationForm->labelAdditionalInfo')}
                                />
                            </>
                        }
                        {
                            serviceType === 1 &&
                            <>
                                <Row>
                                    <Col sm={6}>
                                        <Field
                                            name={`translations[${index}].directionFrom`}
                                            id={`translations[${index}].directionFrom`}
                                            component={InputElement}
                                            label={t('locationForm->labelDirectionFrom')}
                                            placeholder={t('locationForm->labelDirectionFromPlaceholder')}
                                            validate={required}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Field
                                            name={`translations[${index}].directionTo`}
                                            id={`translations[${index}].directionTo`}
                                            component={InputElement}
                                            label={t('locationForm->labelDirectionTo')}
                                            placeholder={t('locationForm->labelDirectionToPlaceholder')}
                                            validate={required}
                                        />
                                    </Col>
                                </Row>
                            </>
                        }
                    </TabPane>
                )
            })
        );
    }

    renderColGeneral() {
        const {t, initialValues: {isEnabled}} = this.props;
        const { freeways, serviceType } = this.state;

        if (this.props.initialValues.freewayId && freeways.data.length === 0) {
            this.fetchHighwaysOptions();
        }
        
        return (
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">{t('locationForm->formTitle1')}</h5>
                    </div>
                    <Field
                        name="isEnabled"
                        id="isEnabled"
                        component={CheckBoxElement}
                        defaultChecked={isEnabled}
                        label={t('locationForm->labelEnabled')}
                        className="colored-click"
                    />
                    {
                        serviceType === 1 &&
                        <>
                            <Field
                                name="freewayId"
                                id="freewayId"
                                component={SelectElement}
                                options={freeways.data}
                                label={t('locationForm->labelFreeway')}
                                placeholder={t('common:labelSelectOptionPlaceholder')}
                                isDisabled={!freeways.done}
                                isLoading={!freeways.done}
                                validate={required}
                            />
                            <Field
                                name="speedLimit"
                                id="speedLimit"
                                component={InputElement}
                                label={t('locationForm->labelSpeedLimit')}
                                placeholder={t('locationForm->labelSpeedLimitPlaceholder')}
                                numeric
                                validate={required}
                            />
                            <Field
                                name="kmPoint"
                                id="kmPoint"
                                component={InputElement}
                                label={t('locationForm->labelKmPoint')}
                                placeholder={t('locationForm->labelKmPointPlaceholder')}
                                numeric
                                validate={required}
                            />
                        </>
                    }
                    <Row>
                        <Col sm={6}>
                            <Field
                                name="latitude"
                                id="latitude"
                                component={InputElement}
                                label={t('locationForm->labelLatitude')}
                                placeholder={t('locationForm->labelLatitudePlaceholder')}
                                numeric
                                autoComplete="off"
                                validate={[required, maxLength12]}
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name="longitude"
                                id="longitude"
                                component={InputElement}
                                label={t('locationForm->labelLongitude')}
                                placeholder={t('locationForm->labelLongitudePlaceholder')}
                                numeric
                                autoComplete="off"
                                validate={[required, maxLength12]}
                            />
                        </Col>
                    </Row>
                    {
                        serviceType === 2 &&
                        <Field
                            name="isPartner"
                            id="isPartner"
                            component={CheckBoxElement}
                            label={t('locationForm->labelIsPartner')}
                            className="colored-click"
                        />
                    }
                    {
                        (serviceType === 0 || serviceType === 2 || serviceType === 13 || serviceType == 27) &&
                        <>
                            <Field
                                name="address"
                                id="address"
                                component={InputElement}
                                label={t('locationForm->labelAddress')}
                            />
                            <Row>
                                <Col sm={6}>
                                    <Field
                                        name="postalCode"
                                        id="postalCode"
                                        component={InputElement}
                                        label={t('locationForm->labelPostalCode')}
                                        mask={POSTAL_CODE_MASK}
                                        guide={true}
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Field
                                        name="city"
                                        id="city"
                                        component={InputElement}
                                        label={t('locationForm->labelCity')}
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                </CardBody>
            </Card>
        );
    }

    renderColTranslations() {
        const {t} = this.props;
        const {activeTab} = this.state;

        return (
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">{t('locationForm->formTitle2')}</h5>
                    </div>
                    <div className="tabs tabs--justify tabs--bordered-bottom">
                        <div className="tabs__wrap">
                            <Nav tabs>
                                {this.renderTabHeader()}
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                {this.renderTabContent()}
                            </TabContent>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }

    renderLowerTabHeader() {
        const { activeLowerTab, lowerTabs } = this.state;
        return (
            lowerTabs.map((tab, index) => {
                return (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({ active: activeLowerTab === index })}
                            onClick={() => this.toggleLowerTab(index)}
                        >
                            {tab}
                        </NavLink>
                    </NavItem>
                )
            })
        );
    }

    renderLowerTabs() {


        const { services, relatedLocations} = this.state;
        const { id, theme, serviceTypesOptions} = this.props;

        return (
            <>
                <TabPane tabId={0}>
                    <LocationServicesTable
                        theme={theme}
                        services={services}
                        serviceTypesOptions={serviceTypesOptions}
                        addService={this.addService}
                        removeService={this.removeService}
                        toggleService={this.toggleService}
                    />
                </TabPane>
                <TabPane tabId={1}>
                    <LocationRelatedTable
                        theme={theme}
                        relatedLocations={relatedLocations}
                        serviceTypesOptions={serviceTypesOptions}
                        addRelatedLocation={this.addRelatedLocation}
                        removeRelatedLocation={this.removeRelatedLocation}
                        locationId={id}
                    />
                </TabPane>
            </>
        )
    }

    render() {
        const { t, id, btnText, type } = this.props;
        const { activeLowerTab, isLoading, services } = this.state;

        return (
            <Container>
                <LoadingElement isLoading={isLoading} />
                <form className="form d-block" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate>
                    <Row>
                        <Col md={6}>
                            {this.renderColGeneral()}
                        </Col>
                        <Col md={6}>
                            {
                                services && services.length > 0 &&
                                this.renderColTranslations()
                            }
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <div className="tabs tabs--justify tabs--bordered-bottom">
                                <div className="tabs__wrap">
                                    <Nav tabs>
                                        {this.renderLowerTabHeader()}
                                    </Nav>
                                    <TabContent activeTab={activeLowerTab}>
                                        {this.renderLowerTabs()}
                                    </TabContent>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit" onClick={() => this.checkErrorInTabs()}>{btnText}</Button>
                        {
                            type === 'edit' && <ModalConfirmation
                                backdrop="static"
                                color="danger"
                                firstBtnText={t('modal:modal->buttonNo')}
                                secondBtnText={t('modal:modal->buttonYes')}
                                title={t('locationList->modalDeleteTitle')}
                                btn={t('locationForm->btnDelete')}
                                message={<p>{t('locationList->modalDeleteMessage')}</p>}
                                handleOnClick={() => this.handleModalDelete(id)}
                            />
                        }
                        <Link className="btn btn-secondary" to="/vvservices/location">
                            {t('locationForm->btnCancel')}
                        </Link>
                    </ButtonToolbar>
                </form>
            </Container>
        );
    }
}

const selector = formValueSelector("locationForm");

const mapStateToProps = (state) => {
    return {
        id: selector(state, "id"),
        theme: state.theme.className,
        serviceType: selector(state, 'serviceTypeId.type'),
        formSyncErrors: getFormSyncErrors('locationForm')(state)
    };
};
const translationWrapped = withTranslation(["location", "modal", "common"])(LocationForm);

const formWrapped = reduxForm({
    form: "locationForm",
})(translationWrapped);

export default connect(mapStateToProps)(formWrapped);
