import {
    CHANGE_SIDEBAR_VISIBILITY,
    CHANGE_MOBILE_SIDEBAR_VISIBILITY,
    CLEAR_CACHE,
    ERROR_CACHE,
    PENDING_MENU,
    FETCH_MENU,
    DONE_MENU,
    ERROR_MENU
} from '../actions/sidebarActions';

const initialState = {
    show: false,
    collapse: false,
    done: {
        status: false,
        message: null,
    },
    data: {
        done: false,
        menus:[]
    },
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_SIDEBAR_VISIBILITY:
            return {...state, collapse: !state.collapse};
        case CHANGE_MOBILE_SIDEBAR_VISIBILITY:
            return {...state, show: !state.show};
        case CLEAR_CACHE:
            return {...state, done: {status: true, message: action.payload}, error: null};    
        case ERROR_CACHE:
            return {...state, done: {status: true, message: null}, error: action.payload}; 
        case PENDING_MENU:
            return { ...state, data: { ...state.data, done: false }, error: null};
        case FETCH_MENU:
            return {...state, data: {...state.data, menus: action.payload}, error: null}; 
        case DONE_MENU:
            return {...state, data: {...state.data, done: true}, error: null};      
        case ERROR_MENU:
            return { ...state, data: { ...state.data, done: true }, error: action.payload};      
        default:
            return state;
    }
}

