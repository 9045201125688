import React from "react";
import {compose} from "redux";
import {Link} from "react-router-dom";
import {
    Col,
    Container,
    Row,
    Card,
    CardBody,
    Button,
    ButtonToolbar,
    ButtonGroup,
} from "reactstrap";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import {withTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {connect} from "react-redux";
import {
    fetchServiceCats,
    toggleServiceCat,
    deleteServiceCat,
} from "../../../redux/actions/serviceCatActions";
import ModalConfirmation from "../../../shared/components/common/ModalConfirmation";
import {store} from "react-notifications-component";
import "animate.css";
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE} from "./variables";
import { DATATABLE_DARK_MODE_STYLES, SEARCH_TIMEOUT } from "../../variables";
import LoadingElement from "../../../shared/components/common/Loading";



class ServiceCatList extends React.Component {
    state = {
        data: [],
        filterText: "",
        rowsPerPage: ROWS_PER_PAGE,
        pageSize: PAGE_SIZE,
        currentPage: CURRENT_PAGE,
    };

    componentDidMount() {
        this.props.fetchServiceCats(CURRENT_PAGE, PAGE_SIZE);
    }

    componentDidUpdate(prevProps) {
        const {categories, message, done, error} = this.props;

        prevProps.categories !== categories && this.setState({data: categories});
        prevProps.message !== message && message && prevProps.done !== done && this.renderNotification("success");
        prevProps.error !== error && error && this.renderNotification("danger");
    }

    renderNotification(type) {
        const {t, error} = this.props;
        store.addNotification({
            title: t(`notification:${type}->title`),
            message:
                type === "success"
                    ? t(`notification:${type}->message`)
                    : t(`notification:${type}->message`, {error}),
            type: type,
            insert: "bottom",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
                duration: 3000,
                showIcon: true,
            },
        });
    }

    handlePageChange = (page) => {
        const {pageSize} = this.state;
        this.setState({currentPage: page});
        this.props.fetchServiceCats(page, pageSize);
    };

    handleSearchWord(e) {
        this.setState({ filterText: e.target.value })

        clearTimeout(this.state.timeout);
        this.state.timeout = setTimeout(() => {
            this.setState({ newItems: this.props.fetchServiceCats(CURRENT_PAGE, PAGE_SIZE, this.state.filterText) });
        }, SEARCH_TIMEOUT);
    }


    handlePerRowsChange = (pageSize, page) => {
        this.setState({pageSize: pageSize, currentPage: page});
        this.props.fetchServiceCats(page, pageSize);
    };

    handleToggle = (row) => {
        this.props.toggleServiceCat(row);
    };

    handleModalDelete(id) {
        const {pageSize, currentPage} = this.state;
        this.props.deleteServiceCat(id, "list", pageSize, currentPage);
    }

    renderLoading = () => {
        return <LoadingElement isLoading={true} />;
    }

    renderTableHeader() {
        const {t} = this.props;

        return (
            <div className='card__title'>
                <h5 className='bold-text'>{t("serviceCatList->tableTitle")}</h5>
                <ButtonToolbar className='products-list__btn-toolbar-top'>
                    <div className='form'>
                        <div className='form__form-group products-list__search'>
                            <input
                                type='text'
                                id='search'
                                value={this.state.filterText}
                                onChange={this.handleSearchWord.bind(this)}
                                placeholder={t("serviceCatList->searchTextPlaceholder")}
                                autoComplete='off'
                            />
                            <MagnifyIcon/>
                        </div>
                    </div>
                    <Link className='btn btn-primary products-list__btn-add' to='/vvservices/categories/create'>
                        {t("serviceCatList->createNew")}
                    </Link>
                </ButtonToolbar>
            </div>
        );
    }

    renderTableColumns() {
        const {t} = this.props;
        return [
            {
                name: t("serviceCatList->tableColumnName"),
                selector: "name",
                sortable: true,
                wrap: true,
            },
            {
                name: t("serviceCatList->tableColumnColor1"),
                selector: "color1",
                sortable: true,
                wrap: true,
            },
            {
                name: t("serviceCatList->tableColumnOrder"),
                selector: "order",
                sortable: true,
                wrap: true,
            },
            {
                name: t("serviceCatList->tableColumnActive"),
                button: true,
                maxWidth: "50px",
                cell: (row) => (
                    <label className='toggle-btn customizer__toggle' htmlFor={`toggle_${row.id}`}>
                        <input
                            className='toggle-btn__input'
                            type='checkbox'
                            name={`toggle_${row.id}`}
                            id={`toggle_${row.id}`}
                            checked={row.isEnabled}
                            value={row}
                            onChange={() => this.handleToggle(row)}
                        />
                        <span className='toggle-btn__input-label'/>
                    </label>
                ),
            },
            {
                button: true,
                cell: (row) => (
                    <ButtonToolbar>
                        <ButtonGroup className='btn-group--icons' dir='ltr'>
                            <Link to={`/vvservices/categories/edit/${row.id}`}>
                                <Button outline>
                                    <span className='lnr lnr-pencil'/>
                                </Button>
                            </Link>
                            <ModalConfirmation
                                backdrop='static'
                                color='danger'
                                outline
                                firstBtnText={t("modal:modal->buttonNo")}
                                secondBtnText={t("modal:modal->buttonYes")}
                                title={t("serviceCatList->modalDeleteTitle")}
                                btn={<span className='lnr lnr-trash'/>}
                                message={<p>{t("serviceCatList->modalDeleteMessage")}</p>}
                                handleOnClick={() => this.handleModalDelete(row.id)}
                            />
                        </ButtonGroup>
                    </ButtonToolbar>
                ),
            },
        ];
    }

    renderTable() {
        const {rowsPerPage, pageSize} = this.state;
        const {t, done, totalItems, theme} = this.props;
        let filteredItems = [];

        if (done) {
            filteredItems = this.state.data
        }

        const paginationOptions = {
            rowsPerPageText: t("common:table->rowsPerPageText"),
            rangeSeparatorText: t("common:table->rangeSeparatorText"),
        };

        return (
            <>
                {this.renderTableHeader()}
                <DataTable
                    keyfield={filteredItems.id}
                    customStyles={theme === 'theme-dark' ? DATATABLE_DARK_MODE_STYLES : {}}
                    columns={this.renderTableColumns()}
                    data={filteredItems}
                    defaultSortField='friendlyName'
                    noDataComponent={t("common:table->noDataComponent")}
                    progressPending={!done}
                    pagination
                    paginationServer
                    progressComponent={this.renderLoading()}
                    paginationTotalRows={totalItems}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationComponentOptions={paginationOptions}
                    paginationRowsPerPageOptions={rowsPerPage}
                    paginationPerPage={pageSize}
                />
            </>
        );
    }

    render() {
        const {t} = this.props;

        return (
            <Container>
                <Row>
                    <Col>
                        <h3 className='page-title mb-4'>{t("serviceCatList->pageTitle")}</h3>
                        <Card>
                            <CardBody>{this.renderTable()}</CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        optionsError: state.selectOptions.error,
        categories: Object.values(state.servicesCats.data.categories),
        totalItems: state.servicesCats.data.totalItems,
        done: state.servicesCats.done.status,
        message: state.servicesCats.done.message,
        error: state.servicesCats.error,
        theme: state.theme.className
    };
};

export default compose(
    withTranslation(["servicesCat", "modal", "notification"]),
    connect(mapStateToProps, {
        fetchServiceCats,
        toggleServiceCat,
        deleteServiceCat,
    })
)(ServiceCatList);
