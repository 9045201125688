import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const TextAreaElement = (props) => {
    const {input, meta: {touched, error, submitFailed}, label, id, placeholder, disabled} = props;
    const {t} = useTranslation('validation');
    return (
        <div className="form__form-group">
            <label className="form__form-group-label" htmlFor={id}>{label}</label>
            <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                    <textarea {...input} id={id} disabled={disabled} placeholder={placeholder}/>
                    {touched && error && submitFailed &&
                    <span className="form__form-group-error">
                        {
                            error.includes('maxLength') ? t('validate->maxLength', {max: error.split('-')[1]}) : t(`validate->${error}`)
                        }
                    </span>
                    }
                </div>
            </div>
        </div>
    )
};

TextAreaElement.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        submitFailed: PropTypes.bool
    }),
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool
};

export default TextAreaElement;