import React from 'react';
import {Route, Switch} from 'react-router-dom';
import VoucherCampaigns from '../../../../Campaign/Voucher/VoucherCampaigns/';
import VoucherCampaignsList from '../../../../Campaign/Voucher/VoucherCampaignsList/';
import VoucherCreate from '../../../../Campaign/Voucher/VoucherCreate';
import VoucherEdit from '../../../../Campaign/Voucher/VoucherEdit';
import VoucherImport from '../../../../Campaign/Voucher/VoucherImport/';
import VoucherMonitoring from "../../../../Campaign/Voucher/VoucherMonitoring/";

export default () => (
    <Switch>
        <Route path="/vv/voucher/campaigns" exact component={VoucherCampaigns}/>
        <Route path="/vv/voucher/campaigns/:partnerName/:partnerId" exact component={VoucherCampaignsList}/>
        <Route path="/vv/voucher/campaigns/:partnerName/:partnerId/create" component={VoucherCreate}/>
        <Route path="/vv/voucher/campaigns/:partnerName/:partnerId/edit/:id" component={VoucherEdit}/>
        <Route path="/vv/voucher/campaigns/:partnerName/:partnerId/import-vouchers/:id" component={VoucherImport}/>
        <Route path="/vv/voucher/monitoring" exact component={VoucherMonitoring}/>
    </Switch>
);
