import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, ButtonToolbar, Modal} from 'reactstrap';
import classNames from 'classnames';
import {connect} from 'react-redux';
import CloseIcon from 'mdi-react/CloseIcon';

class ModalElement extends PureComponent {
    static propTypes = {
        title: PropTypes.string,
        body: PropTypes.element,
        color: PropTypes.string.isRequired,
        colored: PropTypes.bool,
        header: PropTypes.bool,
        btnOpenModal: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.string
        ]).isRequired,
        actionBtnText: PropTypes.string,
        backdrop: PropTypes.string,
        outline: PropTypes.bool,
        btnDisabled: PropTypes.bool,
        cancelBtnText: PropTypes.string.isRequired
    };

    static defaultProps = {
        title: '',
        message: '',
        colored: false,
        header: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
    }

    toggleModal = () => {
        this.setState(prevState => ({modal: !prevState.modal}));
    };

    render() {
        const {
            color, btnOpenModal, title, body, colored, header, outline, theme, backdrop, cancelBtnText, actionBtnText, handleOnClick
        } = this.props;
        const {modal} = this.state;

        const modalClass = classNames({
            'modal-dialog--colored': colored,
            'modal-dialog--header': header,
        });

        return (
            <div>
                <Button outline={outline} color={color} onClick={this.toggleModal}>{btnOpenModal}</Button>
                <Modal
                    isOpen={modal}
                    toggle={this.toggleModal}
                    backdrop={backdrop}
                    modalClassName={`ltr-support`}
                    className={`modal-dialog--${color} ${modalClass} ${theme}`}
                >
                    <div className="modal__header">
                        <button className="modal__close-btn" type="button" onClick={this.toggleModal}><CloseIcon/></button>
                        <h4 className="text-modal  modal__title">{title}</h4>
                    </div>
                    <div className="modal__body">
                        {body}
                    </div>
                    <ButtonToolbar className="modal__footer">
                        <Button className="modal_cancel" onClick={this.toggleModal}>{cancelBtnText}</Button>
                        {
                            actionBtnText &&
                            <Button className="modal_ok" outline={colored} color={color} onClick={() => {
                                handleOnClick();
                                this.toggleModal();
                            }}
                            >{actionBtnText}</Button>
                        }
                    </ButtonToolbar>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme.className,
    };
};

export default connect(mapStateToProps)(ModalElement);