import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { fetchAllPointsOfSale } from '../../../redux/actions/posActions';
import { fetchProducts } from '../../../redux/actions/productActions';
import {
    fetchTiers,
    fetchStockTypes,
    fetchTollBalanceCampaign,
    createTollBalanceCampaign,
    editTollBalanceCampaign,
    deleteTollBalanceCampaign
} from '../../../redux/actions/tollBalanceActions';
import { store } from "react-notifications-component";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { Button, Card, CardBody, Container } from "reactstrap";
import { cloneDeep, remove } from "lodash";
import Alert from "../../../shared/components/common/Alert";
import TollBalanceForm from "./TollBalanceForm/";
import LoadingElement from "../../../shared/components/common/Loading";

class TollBalanceEdit extends React.Component {
    state = {
        tiers: {
            data: [],
            done: false
        },
        stockUnitTypes: {
            data: [],
            done: false
        }
    };

    async componentDidMount() {
        const { partnerId, id, copyId } = this.props.match.params;
        this.props.fetchTollBalanceCampaign(copyId ? copyId : id)
        this.props.fetchAllPointsOfSale(partnerId);
        this.props.fetchProducts(partnerId, 1, 1000);
        await this.fetchStockUnitTypes();
        await this.fetchTiers();
    }

    async fetchTiers() {
        try {
            const response = await axios.get('/Campaigns/GetTiers', {
                headers: { language: 'pt-PT' },
            });
            if (response) {
                this.setState({ tiers: { data: response.data, done: true } });
            }
        } catch (e) {
            this.renderError();
        }
    };

    async fetchStockUnitTypes() {
        try {
            const response = await axios.get('/Campaigns/GetStockUnitTypes', {
                headers: { language: 'pt-PT' },
            });
            if (response) {
                this.setState({ stockUnitTypes: { data: response.data, done: true } });
            }
        } catch (e) {
            this.renderError();
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message && this.props.message) {
            store.addNotification({
                title: this.props.t("notification:success->title"),
                message: this.props.t("notification:success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                },
            });
        }
    }

    onSubmit = formValues => {
        const { copyId } = this.props.match.params;

        copyId ? this.props.createTollBalanceCampaign(formValues, '/copy') : this.props.editTollBalanceCampaign(formValues);
    };

    onDelete = () => {
        const { id, partnerName, partnerId } = this.props.match.params;
        this.props.deleteTollBalanceCampaign(id, "form", partnerName, partnerId);
    };

    normalizePOSData() {
        const { tollBalance, POS } = this.props;
        let POSSelected = cloneDeep(tollBalance.campaignPointsOfSale), POSCopy = cloneDeep(POS);

        POS.map(pos => POSSelected.map(selected => {
            if (parseInt(pos.key) === selected.pointOfSaleId) {
                selected.key = pos.key;
                selected.value = pos.value;
                remove(POSCopy, pos);
            }
            return selected;
        }));

        return { POSSelected, POSCopy };
    }

    normalizeProductsData() {
        const { tollBalance } = this.props;

        let productsSelected = [], symbol = [];
        tollBalance.campaignProducts.map((campaignProduct, index) => {
            const aux = { ...campaignProduct, internalId: index };
            return productsSelected.push(aux);
        });

        productsSelected.map(productSelected => {
            const id = productSelected.stockUnitType;
            productSelected.stockUnitType = {
                label: id === 1 ? '€' : '%',
                value: id,
            };
            symbol.push(id === 1 ? '€' : '%');
            productSelected.campaignProductValues.map(product => {
                if (product.fee != null)
                    product.fee = product.fee.toString()
            });
            return productSelected;
        }
        )

        return { productsSelected, symbol };
    }

    renderError(error) {
        const { t } = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color='danger' className='alert--bordered' icon>
                            <>
                                <p className='bold-text'>{t("alert:danger->title")}</p>
                                <p>{t("alert:danger->message", { error })}</p>
                                <Button size="sm" type="button" className="mb-0"
                                    onClick={() => this.componentDidMount()}>{t('alert:danger->tryAgain')}</Button>
                            </>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    }

    render() {
        const { t, tollBalance, tollBalanceDone, tollBalanceError, POSDone, POSError, products, productsDone, productsError } = this.props;
        const { copyId } = this.props.match.params;

        const { tiers, stockUnitTypes } = this.state;

        if (!tollBalanceDone || !POSDone || !productsDone || !tiers.done || !stockUnitTypes.done) {
            return <LoadingElement isLoading={true} />;
        }

        if (tollBalanceError || POSError || productsError) {
            return this.renderError(tollBalanceError || POSError || productsError);
        }

        let tollBalanceCopy = { ...tollBalance };

        copyId && (tollBalanceCopy.name = tollBalanceCopy.name + " " + t('tollBalanceForm->labelCopy'));

        const normalizedPOS = this.normalizePOSData();
        const normalizedProductsSelected = this.normalizeProductsData();

        tollBalanceCopy.campaignProducts = normalizedProductsSelected.productsSelected;

        return (
            <TollBalanceForm
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                partnerName={this.props.match.params.partnerName}
                partnerId={this.props.match.params.partnerId}
                btnText={copyId ? t('tollBalanceForm->btnCreate') : t('tollBalanceForm->btnSave')}
                POS={normalizedPOS.POSCopy}
                POSSelected={normalizedPOS.POSSelected}
                products={products}
                productsSelected={normalizedProductsSelected.productsSelected}
                symbol={normalizedProductsSelected.symbol}
                tiers={tiers.data}
                stockUnitTypes={stockUnitTypes.data}
                initialValues={tollBalanceCopy}
                type={copyId ? 'copy' : 'edit'}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        tollBalance: state.tollBalances.data.tollBalances[ownProps.match.params.copyId ? ownProps.match.params.copyId : ownProps.match.params.id],
        tollBalanceDone: state.tollBalances.done.status,
        tollBalanceError: state.tollBalances.error,
        POS: Object.values(state.pointsOfSale.data.pointsOfSale),
        POSDone: state.pointsOfSale.done.status,
        POSError: state.pointsOfSale.error,
        products: Object.values(state.products.data.products),
        productsDone: state.products.done.status,
        productsError: state.products.error,
        message: state.tollBalances.done.message
    };
};

export default compose(
    withTranslation(["tollBalance", "notification"]),
    connect(mapStateToProps, {
        fetchAllPointsOfSale,
        fetchProducts,
        fetchTiers,
        fetchStockTypes,
        fetchTollBalanceCampaign,
        createTollBalanceCampaign,
        editTollBalanceCampaign,
        deleteTollBalanceCampaign
    })
)(TollBalanceEdit);