import _ from 'lodash';
import {
    CREATE_LOCATION,
    FETCH_LOCATION,
    FETCH_LOCATIONS,
    PENDING_LOCATION,
    ERROR_LOCATION,
    DONE_LOCATION,
    DELETE_LOCATION,
    EDIT_LOCATION,
    TOGGLE_LOCATION
} from '../actions/locationActions';

const initialState = {
    done: {
        status: false,
        message: null
    },
    data: {
        locations: [],
        totalItems: null
    },
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LOCATIONS:
            return {
                ...state,
                data: {locations: _.mapKeys(action.payload.locations, 'id'), totalItems: action.payload.total}
            };
        case FETCH_LOCATION:
            return {...state, data: {locations: {[action.payload.id]: action.payload}}};
        case CREATE_LOCATION:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case EDIT_LOCATION:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case TOGGLE_LOCATION:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DELETE_LOCATION:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DONE_LOCATION:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case PENDING_LOCATION:
            return {...state, done: {status: false, message: null}, error: null};
        case ERROR_LOCATION:
            return {...state, done: {status: true, message: null}, error: action.payload};
        default:
            return state;
    }
}