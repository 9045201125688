import login from './pt-PT/login.json';
import common from './pt-PT/common';
//import common_en from './en-US/common';
import navigation from './pt-PT/navigation';
//import navigation_en from './en-US/navigation';
import banners from './pt-PT/banners'
import content from './pt-PT/content'
import modal from './pt-PT/modal';
import validation from './pt-PT/validation';
import notification from './pt-PT/notification';
import alert from './pt-PT/alert';
import services from './pt-PT/services';
import servicesCat from './pt-PT/servicesCat';
import location from './pt-PT/location';
import partner from './pt-PT/partner';
import campaign from './pt-PT/campaign.json';
import tollBalance from './pt-PT/tollBalance.json';
import voucher from './pt-PT/voucher.json';

export default {
    'pt-PT': {
        login,
        common,
        navigation,
        banners,
        content,
        modal,
        validation,
        notification,
        alert,
        services,
        servicesCat,
        location,
        partner,
        campaign,
        tollBalance,
        voucher
    }/*,
    'en-US': {
        common: common_en,
        navigation: navigation_en
    }*/
};