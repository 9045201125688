import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Col, Container, Row, Card, CardBody, ButtonToolbar, Button} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import {fetchPartners, togglePartner, deletePartner} from '../../../../redux/actions/partnerActions';
import {store} from 'react-notifications-component';
import 'animate.css';
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE} from '../variables';
import PartnerListDataTable from './DataTable';
import {formValueSelector, reduxForm} from 'redux-form';
import LoadingElement from "../../../../shared/components/common/Loading";
import Alert from "../../../../shared/components/common/Alert";

class PartnerList extends React.Component {
    constructor(props) {
        super(props);

        const {t} = props;
        this.state = {
            data: [],
            isEnabledFilterOptions: [
                {value: '', label: t('common:filters->labelSelectAll')},
                {value: true, label: t('common:filters->labelSelectEnabled')},
                {value: false, label: t('common:filters->labelSelectNotEnabled')}
            ],
            filterIsEnabled: null,
            filterName: null,
            rowsPerPage: ROWS_PER_PAGE,
            pageSize: PAGE_SIZE,
            currentPage: CURRENT_PAGE,
            paginationDefaultPage: 1
        };
    }


    componentDidMount() {
        this.handleFilterClear();
    }

    componentDidUpdate(prevProps) {
        const {partners, message, partnersDone, error} = this.props;

        prevProps.partners !== partners && this.setState({data: partners});
        prevProps.message !== message && message && prevProps.partnersDone !== partnersDone && this.renderNotification('success');
        prevProps.error !== error && error && this.renderNotification('danger');
    }

    renderNotification(type) {
        const {t, error} = this.props;
        store.addNotification({
            title: t(`notification:${type}->title`),
            message: type === 'success' ? t(`notification:${type}->message`) : t(`notification:${type}->message`, {error}),
            type: type,
            insert: "bottom",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
                duration: 3000,
                showIcon: true
            }
        });
    }

    handlePageChange = (currentPage, pageSize) => {
        const {filtered} = this.state;
        const {filterName, filterIsEnabled} = this.props;
        this.setState({currentPage, pageSize});
        let filters;
        filtered && (filters = {
            name: filterName,
            isEnabled: filterIsEnabled.value
        });
        this.props.fetchPartners(currentPage, pageSize, filters);
    };

    handleToggle = (row) => {
        this.props.togglePartner(row);
    };

    handleFilterData = () => {
        const {filterName, filterIsEnabled} = this.props;
        const {pageSize} = this.state;
        this.setState({currentPage: CURRENT_PAGE, filtered: true});
        this.props.fetchPartners(CURRENT_PAGE, pageSize, {name: filterName, isEnabled: filterIsEnabled.value});
    }

    handleFilterClear = () => {
        const {pageSize, isEnabledFilterOptions} = this.state;
        this.props.change('filter', {
            name: '',
            isEnabled: isEnabledFilterOptions[0]
        })
        this.setState({currentPage: CURRENT_PAGE, filtered: false});
        this.props.fetchPartners(CURRENT_PAGE, pageSize);
    }

    renderTableHeader() {
        const {t, location} = this.props;
        return (
            <div className="card__title">
                <h5 className="bold-text">{t('partnerList->tableTitle')}</h5>
                <ButtonToolbar className="products-list__btn-toolbar-top">
                    <Link className="btn btn-primary products-list__btn-add" to={`${location.pathname}/create`}>
                        {t('partnerList->createNew')}
                    </Link>
                </ButtonToolbar>
            </div>
        );
    }

    renderTable() {
        const {data, rowsPerPage, pageSize, currentPage, isEnabledFilterOptions} = this.state;
        const {totalItems, theme} = this.props;

        return (
            <>
                {this.renderTableHeader()}
                <PartnerListDataTable
                    theme={theme}
                    data={data}
                    defaultSortField="name"
                    noHeader
                    paginationServer
                    paginationTotalRows={totalItems}
                    handlePerRowsChange={this.handlePerRowsChange}
                    handlePageChange={this.handlePageChange}
                    rowsPerPage={rowsPerPage}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    paginationDefaultPage={currentPage}
                    handleToggle={this.handleToggle}
                    handleFilterData={this.handleFilterData}
                    handleFilterClear={this.handleFilterClear}
                    isEnabledFilterOptions={isEnabledFilterOptions}
                />
            </>
        )
    }

    renderError(error) {
        const {t} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color="danger" className="alert--bordered" icon>
                            <p className="bold-text">{t('alert:danger->title')}</p>
                            <p className="mb-2">{t('alert:danger->message', {error})}</p>
                            <Button size="sm" type="button" className="mb-0" onClick={() => this.handleFilterData()}>{t('alert:danger->tryAgain')}</Button>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    }

    render() {
        const {t, partnersDone, partnersError} = this.props;

        if (!partnersDone) {
            return <LoadingElement isLoading={true}/>;
        }

        if (partnersError) {
            return this.renderError(partnersError);
        }

        return (
            <Container>
                <Row>
                    <Col>
                        <h3 className="page-title mb-4">{t('partnerList->pageTitle')}</h3>
                        <Card>
                            <CardBody>
                                {this.renderTable()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const selector = formValueSelector('partnerForm');

const mapStateToProps = (state) => {
    return {
        partners: Object.values(state.partners.data.partners),
        totalItems: state.partners.data.totalItems,
        partnersDone: state.partners.done.status,
        partnersError: state.partners.error,
        message: state.partners.done.message,
        theme: state.theme.className,
        filterName: selector(state, 'filter.name'),
        filterIsEnabled: selector(state, 'filter.isEnabled')
    };
};

const translationWrapped = withTranslation(['partner', 'common', 'modal', 'notification'])(PartnerList);

const formWrapped = reduxForm({
    form: 'partnerForm',
})(translationWrapped);

export default connect(mapStateToProps, {
    fetchPartners,
    togglePartner,
    deletePartner
})(formWrapped);