import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormSyncErrors} from 'redux-form';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    ButtonToolbar,
    Button, NavItem, NavLink, Nav, TabContent, TabPane
} from 'reactstrap';
import {
    required,
    requiredTextEditor,
    greaterDate,
    maxLength100,
    percentage,
    invalidDaysUntilUse
} from '../../../../shared/validations';
import {withTranslation} from 'react-i18next';
import {cloneDeep} from 'lodash';
import InputElement from '../../../../shared/components/form/Input';
import CheckBoxElement from '../../../../shared/components/form/CheckBox';
import ModalConfirmation from '../../../../shared/components/common/ModalConfirmation';
import RadioButtonElement from '../../../../shared/components/form/RadioButton';
import DateTimePickerElement from '../../../../shared/components/form/DateTimePicker';
import SelectElement from '../../../../shared/components/form/Select';
import HiddenElement from '../../../../shared/components/form/Hidden';
import FileInput from '../../../../shared/components/form/FileInput';
import {ICON_MAX_SIZE, ICON_TYPE_FORMAT} from '../variables.js';
import TextEditor from '../../../../shared/components/form/TextEditor';
import VoucherTimePeriod from './VoucherTimePeriod';
import VoucherPOS from "./VoucherPOS";
import VoucherProducts from "./VoucherProducts";
import Alert from "../../../../shared/components/common/Alert";
import ImagePreview from "../../../../shared/components/form/ImagePreview";
import LoadingElement from "../../../../shared/components/common/Loading";
import moment from "moment";

class VoucherForm extends React.Component {
    state = {
        isLoading: false,
        activeTab: 0,
        activeLayoutTab: 0,
        tabs: [
            this.props.t('voucherForm->tabTimePeriod'),
            this.props.t('voucherForm->tabPOS'),
            this.props.t('voucherForm->tabRules'),
        ],
        isEditable: true,
        isFinished: false,
        isSelected: false,
        isPreLoaded: this.props.initialValues.isPreLoaded || false,
        isChangePoints: this.props.initialValues.campaignOfferType === 2 || false,
        isPercentage: (this.props.initialValues.voucherCampaignsBenefits && this.props.initialValues.voucherCampaignsBenefits.campaignBenefitType.value === 4) || false,
        isPromoCode: (this.props.initialValues.voucherCampaignsBenefits && this.props.initialValues.voucherCampaignsBenefits.campaignBenefitType.value === 5) || false,
        isBarcode: (this.props.initialValues.voucherCampaignLayoutTranslation && this.props.initialValues.voucherCampaignLayoutTranslation.map(translation => translation.codeFormat.value === 3)) || this.props.languages.map(language => false),
        benefitTypesOptions: this.props.benefitTypes.map(benefitType => (
            {
                label: this.props.t(`common->labelBenefitType${benefitType.name}`),
                value: benefitType.id,
            }
        )),
        offerTypesOptions: this.props.offerTypes,
        voucherCodeFormatsOptions: this.props.voucherCodeFormats.map(voucherCodeFormat => (
            {
                label: this.props.t(`voucherForm->labelVoucherCodeFormat${voucherCodeFormat.name}`),
                value: voucherCodeFormat.id,
            }
        )),
        barcodeFormatsOptions: this.props.barcodeFormats.map(barcodeFormat => (
            {
                label: barcodeFormat.name,
                value: barcodeFormat.id,
            }
        )),
        availablePOS: this.props.POS,
        selectedPOS: this.props.POSSelected || [],
        availableProducts: [],
        selectedProducts: this.props.productsSelected || [],
        compareDate: {},
        daysUntilUse: this.props.initialValues.daysUntilUse || null,
        images: (this.props.initialValues.voucherCampaignLayoutTranslation && this.props.initialValues.voucherCampaignLayoutTranslation.map(translation => translation.image)) || []
    };

    componentDidMount() {
        const {type, initialValues} = this.props;

        if (type === 'edit') {
            new Date(initialValues.beginDate).getTime() >= new Date().getTime() ? this.setState({isEditable: true}) : this.setState({isEditable: false});
            initialValues.endDate ? new Date().getTime() >= new Date(initialValues.endDate).getTime() ? this.setState({isFinished: true}) : this.setState({isFinished: false}) : this.setState({isFinished: false});
        }
    }

    toggleTab = (tab) => {
        const {activeTab} = this.state;
        activeTab !== tab && this.setState({activeTab: tab});
    };

    toggleLayoutTab = (tab) => {
        const {activeLayoutTab} = this.state;
        activeLayoutTab !== tab && this.setState({activeLayoutTab: tab});
    };

    checkErrorInTabs() {
        const {formSyncErrors} = this.props;
        const {activeLayoutTab, activeTab} = this.state;

        if (formSyncErrors.voucherCampaignLayoutTranslation) {
            formSyncErrors.voucherCampaignLayoutTranslation.some((item, i) =>
                item && activeLayoutTab !== i && this.setState({activeLayoutTab: i})
            );
        }

        formSyncErrors.voucherCampaignsActiveHours && activeTab !== 0 && this.setState({activeTab: 0});
    }

    onSubmit = formValues => {
        const {selectedPOS, selectedProducts, images, isChangePoints, isBarcode} = this.state;
        const {languages, partnerId, type} = this.props;

        this.setState({isLoading: true});

        //correct way to clone a multidimensional array or object with array without reference it (without changing original)!!!
        let formToPost = cloneDeep(formValues);

        formToPost = {
            ...formToPost,
            voucherCampaignPointOfSale: [],
            voucherCampaignProduct: [],
            partnerId
        }

        formToPost.voucherCampaignPointOfSale = cloneDeep(selectedPOS);
        formToPost.voucherCampaignPointOfSale.map(pos =>
            pos.pointOfSaleId = parseInt(pos.key)
        );

        formToPost.voucherCampaignProduct = cloneDeep(selectedProducts);

        languages.map((language, index) => {
            formToPost.voucherCampaignLayoutTranslation[index].codeFormat = formToPost.voucherCampaignLayoutTranslation[index].codeFormat.value;
            !isBarcode[index] ? delete formToPost.voucherCampaignLayoutTranslation[index].barCodeType : formToPost.voucherCampaignLayoutTranslation[index].barCodeType = formToPost.voucherCampaignLayoutTranslation[index].barCodeType.value;
            if (formToPost.voucherCampaignLayoutTranslation[index].image) {
                formToPost.voucherCampaignLayoutTranslation[index].image.imageData = images[index].imageData;
                formToPost.voucherCampaignLayoutTranslation[index].image.format = images[index].format;
            }
            if (type === 'edit') {
                this.props.initialValues.voucherCampaignLayoutTranslation[index].image && (formToPost.voucherCampaignLayoutTranslation[index].image.id = this.props.initialValues.voucherCampaignLayoutTranslation[index].image.id);
            }
            return language;
        });

        formToPost.isPreLoaded ? delete formToPost.voucherLimit : formToPost.voucherLimit = parseInt(formToPost.voucherLimit);
        formToPost.voucherCampaignsBenefits.maxUseValue ? (formToPost.voucherCampaignsBenefits.maxUseValue = parseFloat(formToPost.voucherCampaignsBenefits.maxUseValue)) : formToPost.voucherCampaignsBenefits.maxUseValue = null;
        formToPost.voucherCampaignsBenefits.value && (formToPost.voucherCampaignsBenefits.value = parseFloat(formToPost.voucherCampaignsBenefits.value));
        formToPost.voucherCampaignTierPointsExchange && formToPost.voucherCampaignTierPointsExchange.map(tier => tier.totalPoint = parseInt(tier.totalPoint));

        !isChangePoints && delete formToPost.voucherCampaignTierPointsExchange;
        formToPost.voucherCampaignsBenefits.campaignBenefitType = formToPost.voucherCampaignsBenefits.campaignBenefitType.value;
        formToPost.voucherCampaignsBenefits.campaignBenefitType !== 4 && delete formToPost.voucherCampaignsBenefits.maxUseValue;
        formToPost.voucherCampaignsBenefits.campaignBenefitType === 5 && delete formToPost.voucherCampaignsBenefits.value && delete formToPost.voucherCampaignsBenefits.maxUseValue;
        formToPost.voucherCampaignsBenefits.campaignBenefitType !== 5 && delete formToPost.voucherCampaignsBenefits.voucherPromoCodes;
        formToPost.daysUntilUse = parseInt(formToPost.daysUntilUse);

        this.props.onSubmit(formToPost);
    };

    handleModalDelete = () => {
        this.props.onDelete();
    };

    handleImageUpload(e, index) {
        if (e) {
            const {images} = this.state;
            const reader = new FileReader();
            reader.readAsDataURL(e.file);
            let imagesCopy = [...images];

            reader.onloadend = (e) => {
                imagesCopy[index] = {
                    imageData: e.target.result.split(",")[1],
                    format: e.target.result.split(",")[0]
                };
                this.setState({images: imagesCopy});
            };
        }
    }

    changeCodeInput(value, index) {
        let isBarcodeCopy = this.state.isBarcode;

        value === 3 ? isBarcodeCopy[index] = true : isBarcodeCopy[index] = false;
        this.setState({isBarcode: isBarcodeCopy});
    }

    addItem = (item, type) => {
        if (type === 'product') {
            const {availableProducts, selectedProducts} = this.state;
            let aux = availableProducts.find(prod => prod.id === item.value), toAddProduct = {};
            toAddProduct = {
                productId: aux.id,
                name: aux.name
            };

            this.props.change('productSearch', null);
            this.setState(state => ({
                selectedProducts: [...selectedProducts, toAddProduct],
                availableProducts: state.availableProducts.filter(prod => prod.value !== item.value)
            }));
        } else {
            const {availablePOS, selectedPOS} = this.state;
            const toAddPOS = availablePOS.find(pos => pos.key === item.key);

            this.setState(state => ({
                selectedPOS: [...selectedPOS, toAddPOS],
                availablePOS: state.availablePOS.filter(pos => pos.key !== item.key)
            }));
        }
    };

    addAllItems = type => {
        if (type === 'pos') {
            this.setState(state => ({
                selectedPOS: [...state.selectedPOS, ...state.availablePOS],
                availablePOS: []
            }));
        }
    };

    removeItem = (item, type) => {
        if (type === 'product') {
            this.setState(state => ({
                selectedProducts: state.selectedProducts.filter(prod => prod.id !== item.id)
            }));
        } else {
            const {selectedPOS} = this.state;
            const toRemovePOS = selectedPOS.find(pos => pos.key === item.key);
            this.setState(state => ({
                selectedPOS: state.selectedPOS.filter(pos => pos.key !== item.key),
                availablePOS: [...state.availablePOS, toRemovePOS],
            }));
        }
    };

    removeAllItems = type => {
        if (type === 'products') {
            this.setState({selectedProducts: []});
        } else {
            this.setState(state => ({
                selectedPOS: [],
                availablePOS: [...state.availablePOS, ...state.selectedPOS]
            }));
        }
    };

    renderColLayout() {
        const {t} = this.props;
        const {activeLayoutTab} = this.state;

        return (
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">{t('voucherForm->formTitleLayout')}</h5>
                    </div>
                    <div className="tabs tabs--justify tabs--bordered-bottom">
                        <div className="tabs__wrap">
                            <Nav tabs>
                                {this.renderTabLayoutHeader()}
                            </Nav>
                            <TabContent activeTab={activeLayoutTab}>
                                {this.renderLayoutTabs()}
                            </TabContent>
                        </div>
                    </div>
                </CardBody>
            </Card>
        )
    }

    renderTabHeader() {
        const {activeTab, tabs} = this.state;

        return (
            tabs.map((tab, index) => {
                return (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({active: activeTab === index})}
                            onClick={() => this.toggleTab(index)}
                        >
                            {tab}
                        </NavLink>
                    </NavItem>
                )
            })
        );
    }

    renderTabLayoutHeader() {
        const {languages} = this.props;
        const {activeLayoutTab} = this.state;

        return (
            languages.map((item, index) => {
                return (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({active: activeLayoutTab === index})}
                            onClick={() => {
                                this.toggleLayoutTab(index);
                            }}
                        >
                            {item.label}
                        </NavLink>
                    </NavItem>
                )
            })
        );
    }

    renderTabs() {
        const {t, initialValues, partnerId} = this.props;
        const {isEditable, availablePOS, selectedPOS, availableProducts, selectedProducts} = this.state;

        return (
            <>
                <TabPane tabId={0}>
                    <VoucherTimePeriod initialValues={initialValues} isEditable={isEditable}/>
                </TabPane>
                <TabPane tabId={1}>
                    <VoucherPOS
                        initialValues={initialValues}
                        availablePOS={availablePOS}
                        selectedPOS={selectedPOS}
                        addPOS={this.addItem}
                        addAllPOS={this.addAllItems}
                        removePOS={this.removeItem}
                        removeAllPOS={this.removeAllItems}
                        isEditable={isEditable}
                        t={t}
                    />
                </TabPane>
                <TabPane tabId={2}>
                    <VoucherProducts
                        initialValues={initialValues}
                        availableProducts={availableProducts}
                        selectedProducts={selectedProducts}
                        partnerId={partnerId}
                        fetchedProductsOptions={options => this.setState({availableProducts: options})}
                        addItem={this.addItem}
                        addAllItems={this.addAllItems}
                        removeItem={this.removeItem}
                        removeAllItems={this.removeAllItems}
                        isEditable={isEditable}
                        t={t}
                    />
                </TabPane>
            </>
        )
    }

    renderLayoutTabs() {
        const {t, languages} = this.props;
        const {isEditable, voucherCodeFormatsOptions, barcodeFormatsOptions, isBarcode, images} = this.state;

        return (
            languages.map((language, index) =>
                <TabPane key={index} tabId={index}>
                    <Field
                        name={`voucherCampaignLayoutTranslation[${index}].language`}
                        id={`voucherCampaignLayoutTranslation[${index}].language`}
                        component={HiddenElement}
                        hiddenValue={language.value}
                    />
                    <Field
                        name={`voucherCampaignLayoutTranslation[${index}].title`}
                        id={`voucherCampaignLayoutTranslation[${index}].title`}
                        component={InputElement}
                        label={t('voucherForm->labelName')}
                        validate={[required, maxLength100]}
                        disabled={!isEditable}
                    />
                    <Field
                        name={`voucherCampaignLayoutTranslation[${index}].description`}
                        id={`voucherCampaignLayoutTranslation[${index}].description`}
                        component={InputElement}
                        label={t('voucherForm->labelDescription')}
                        validate={[required, maxLength100]}
                        disabled={!isEditable}
                    />
                    <Field
                        name={`voucherCampaignLayoutTranslation[${index}].image`}
                        id={`voucherCampaignLayoutTranslation[${index}].image`}
                        component={FileInput}
                        label={t('voucherForm->labelImage')}
                        onChange={(e) => this.handleImageUpload(e, index)}
                        types={ICON_TYPE_FORMAT}
                        maxSize={ICON_MAX_SIZE}
                        disabled={!isEditable}
                    />
                    {
                        images[index] &&
                        <ImagePreview src={images[index].imageData} format={images[index].format}/>
                    }
                    <div className="mb-4">
                        <p className="bold-text">{t('voucherForm->imgNotes->title')}</p>
                        {t('voucherForm->imgNotes->notes', {returnObjects: true}).map((note, index) => <p key={index}>{note}</p>)}
                    </div>
                    <Field
                        name={`voucherCampaignLayoutTranslation[${index}].conditions`}
                        id={`voucherCampaignLayoutTranslation[${index}].conditions`}
                        component={TextEditor}
                        label={t('voucherForm->labelConditions')}
                        validate={requiredTextEditor}
                        disabled={!isEditable}
                    />
                    <Row>
                        <Col md={6}>
                            <Field
                                name={`voucherCampaignLayoutTranslation[${index}].codeFormat`}
                                id={`voucherCampaignLayoutTranslation[${index}].codeFormat`}
                                label={t('voucherForm->labelCodeFormat')}
                                component={SelectElement}
                                options={voucherCodeFormatsOptions}
                                onChange={e => this.changeCodeInput(e.value, index)}
                                placeholder={t('common:labelSelectOptionPlaceholder')}
                                validate={required}
                                disabled={!isEditable}
                            />
                        </Col>
                        {
                            isBarcode[index] &&
                            <Col md={6}>
                                <Field
                                    name={`voucherCampaignLayoutTranslation[${index}].barCodeType`}
                                    id={`voucherCampaignLayoutTranslation[${index}].barCodeType`}
                                    label={t('voucherForm->labelBarCodeType')}
                                    component={SelectElement}
                                    options={barcodeFormatsOptions}
                                    placeholder={t('common:labelSelectOptionPlaceholder')}
                                    validate={required}
                                    disabled={!isEditable}
                                />
                            </Col>
                        }
                    </Row>
                </TabPane>
            )
        )
    }

    renderColGeneral() {
        const {t, initialValues, tiers} = this.props;
        const {isEditable, isFinished, isSelected, isPreLoaded, isChangePoints, benefitTypesOptions, offerTypesOptions, isPercentage, isPromoCode, compareDate: {beginDate, endDate}, daysUntilUse} = this.state;

        return (
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">{t('voucherForm->formTitleGeneral')}</h5>
                    </div>
                    <Field
                        name="isEnabled"
                        id="isEnabled"
                        component={CheckBoxElement}
                        defaultChecked={initialValues.isEnabled}
                        label={t('voucherForm->labelEnabled')}
                        className="colored-click"
                        disabled={isFinished}
                    />
                    <Field
                        name="campaignTitle"
                        id="campaignTitle"
                        component={InputElement}
                        label={t('voucherForm->labelCampaignTitle')}
                        disabled={!isEditable}
                        validate={[required, maxLength100]}
                    />
                    <Alert color="info" className="alert--neutral alert-period" icon>
                        <p>
                            {t('voucherForm->labelInfoNoteTimePeriodMessage')}
                        </p>
                    </Alert>
                    <Row>
                        <Col sm={6}>
                            <Field
                                name="beginDate"
                                id="beginDate"
                                component={DateTimePickerElement}
                                label={t('voucherForm->labelBeginDate')}
                                showTimeSelect
                                dateFormat="dd/MM/yyyy, HH:mm"
                                startDate={new Date(initialValues.beginDate)}
                                validate={required}
                                onChange={e => this.setState(state => ({
                                    compareDate: {
                                        ...state.compareDate,
                                        beginDate: e
                                    }
                                }))}
                                minDate={new Date()}
                                disabled={!isEditable}
                            />
                        </Col>
                        <Col sm={6}>
                            <Field
                                name="endDate"
                                id="endDate"
                                component={DateTimePickerElement}
                                showTimeSelect
                                label={t('voucherForm->labelEndDate')}
                                dateFormat="dd/MM/yyyy, HH:mm"
                                startDate={initialValues.endDate ? new Date(initialValues.endDate) : null}
                                onChange={e => this.setState(state => ({
                                    compareDate: {
                                        ...state.compareDate,
                                        endDate: e
                                    }
                                }))}
                                minDate={new Date()}
                                disabled={!isEditable}
                            />
                            <Field
                                name="dateCheck"
                                id="dateCheck"
                                component={HiddenElement}
                                hiddenValue=""
                                validate={(endDate && moment(beginDate).diff(moment(endDate), 'days', true) >= 0) ? [greaterDate] : []}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className="mb-4">
                            <p className="mb-2">{t('voucherForm->labelType')}</p>
                            <Field
                                name="isPreLoaded"
                                id="voucherGenerated"
                                radioValue={false}
                                component={RadioButtonElement}
                                label={t('common->labelGenerated')}
                                className="colored-click"
                                onChange={() => this.setState({isPreLoaded: false})}
                                disabled={!isEditable}
                            />
                            <Field
                                name="isPreLoaded"
                                id="voucherPreloaded"
                                radioValue={true}
                                component={RadioButtonElement}
                                label={t('common->labelPreLoaded')}
                                className="colored-click"
                                onChange={() => this.setState({isPreLoaded: true})}
                                disabled={!isEditable}
                            />
                        </Col>
                        <Col sm={6}>
                            {
                                !isPreLoaded &&
                                <Field
                                    name="voucherLimit"
                                    id="voucherLimit"
                                    component={InputElement}
                                    label={t('voucherForm->labelLimit')}
                                    numeric
                                    disabled={!isEditable}
                                />
                            }
                        </Col>
                    </Row>
                    <Field
                        name="highlighted"
                        id="highlighted"
                        component={CheckBoxElement}
                        defaultChecked={initialValues.highlighted}
                        label={t('voucherForm->labelHighlighted')}
                        className="colored-click"
                        disabled={isFinished}
                    />
                    <Row>
                        <Col sm={6} className="mb-4">
                            <p className="mb-2">{t('voucherForm->labelOfferType')}</p>
                            {
                                offerTypesOptions.map(offerType =>
                                    <Field
                                        key={offerType.id}
                                        name="campaignOfferType"
                                        id={`campaignOfferType-${offerType.id}`}
                                        radioValue={offerType.id}
                                        component={RadioButtonElement}
                                        label={t(`voucherForm->labelOfferType${offerType.name}`)}
                                        className="colored-click"
                                        onChange={value => value === 2 ? this.setState({isChangePoints: true}) : this.setState({isChangePoints: false})}
                                        disabled={!isEditable}
                                    />
                                )
                            }
                        </Col>
                        {
                            isChangePoints &&
                            <>
                                {
                                    tiers.map((tier, index) =>
                                        <Col sm={3} key={index}>
                                            <Field
                                                name={`voucherCampaignTierPointsExchange[${index}].tierId`}
                                                id={`voucherCampaignTierPointsExchange[${index}].tierId`}
                                                component={HiddenElement}
                                                hiddenValue={tier.id}
                                            />
                                            <Field
                                                name={`voucherCampaignTierPointsExchange[${index}].totalPoint`}
                                                id={`voucherCampaignTierPointsExchange[${index}].totalPoint`}
                                                component={InputElement}
                                                label={tier.name}
                                                numeric
                                                disabled={!isEditable}
                                            />
                                        </Col>
                                    )
                                }
                            </>
                        }
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Field
                                name="voucherCampaignsBenefits.campaignBenefitType"
                                id="voucherCampaignsBenefits.campaignBenefitType"
                                label={t('voucherForm->labelBenefit')}
                                component={SelectElement}
                                options={benefitTypesOptions}
                                placeholder={t('common:labelSelectOptionPlaceholder')}
                                validate={required}
                                onChange={e => {
                                    e.value >= 1 ? this.setState({isSelected: true}) : this.setState({isSelected: false});
                                    e.value === 4 ? this.setState({isPercentage: true}) : this.setState({isPercentage: false});
                                    e.value === 5 ? this.setState({isPromoCode: true}) : this.setState({isPromoCode: false});
                                }}
                                disabled={!isEditable}
                            />
                        </Col>
                        {
                            !isPromoCode &&
                            <Col sm={isPercentage ? 3 : 6}>
                                <Field
                                    name="voucherCampaignsBenefits.value"
                                    id="voucherCampaignsBenefits.value"
                                    component={InputElement}
                                    label={t('voucherForm->labelBenefitValue') + (isSelected ? (isPercentage ? ' (%)' : ' (€)') : '')}
                                    numeric
                                    validate={isPercentage ? [required, percentage] : [required]}
                                    disabled={!isEditable}
                                />
                            </Col>
                        }
                        {
                            isPromoCode &&
                            <Col sm={6}>
                                <Field
                                    name="voucherCampaignsBenefits.voucherPromoCodes[0].promoCode"
                                    id="voucherCampaignsBenefits.voucherPromoCodes[0].promoCode"
                                    component={InputElement}
                                    label={t('voucherForm->labelPromoCode')}
                                    validate={required}
                                    disabled={!isEditable}
                                />
                            </Col>
                        }
                        {
                            isPercentage &&
                            <Col sm={3}>
                                <Field
                                    name="voucherCampaignsBenefits.maxUseValue"
                                    id="voucherCampaignsBenefits.maxUseValue"
                                    component={InputElement}
                                    label={t('voucherForm->labelBenefitMaxValue')}
                                    numeric
                                    disabled={!isEditable}
                                />
                            </Col>
                        }
                    </Row>
                    {
                        endDate &&
                        <Row>
                            <Col sm={6}>
                                <Field
                                    name="daysUntilUse"
                                    id="daysUntilUse"
                                    component={InputElement}
                                    label={t('voucherForm->labelDaysUntilUse')}
                                    numeric
                                    disabled={isFinished}
                                    onChange={e => this.setState({daysUntilUse: e})}
                                    validate={daysUntilUse > moment(endDate).diff(moment(beginDate), 'days') ? [invalidDaysUntilUse] : []}
                                />
                            </Col>
                        </Row>
                    }
                </CardBody>
            </Card>
        );
    }

    render() {
        const {t, partnerId, partnerName, btnText, type, initialValues} = this.props;
        const {activeTab, isLoading, isFinished} = this.state;
        
        return (
            <Container>
                <LoadingElement isLoading={isLoading}/>
                <h3 className="page-title mb-4">{partnerName}</h3>
                <form className="form d-block" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Row>
                        <Col md={6}>
                            {this.renderColGeneral()}
                        </Col>
                        <Col md={6}>
                            {this.renderColLayout()}
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <div className="tabs tabs--justify tabs--bordered-bottom">
                                <div className="tabs__wrap">
                                    <Nav tabs>
                                        {this.renderTabHeader()}
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        {this.renderTabs()}
                                    </TabContent>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <ButtonToolbar className="form__button-toolbar">
                        {
                            !isFinished &&
                            <Button color="primary" type="submit"
                                    onClick={() => this.checkErrorInTabs()}>{btnText}</Button>
                        }
                        {
                            type === 'edit' && !isFinished && <ModalConfirmation
                                backdrop="static"
                                color="danger"
                                firstBtnText={t('modal:buttonNo')}
                                secondBtnText={t('modal:buttonYes')}
                                title={t('modal:modalDeleteTitle')}
                                btn={t('modal:btnDelete')}
                                message={<p>{t('modal:modalDeleteMessage')}</p>}
                                handleOnClick={this.handleModalDelete}
                                disabled={new Date(initialValues.beginDate) <= new Date() && (initialValues.endDate ? new Date(initialValues.endDate) >= new Date() : true) && initialValues.isEnabled}
                            />
                        }
                        <Link className="btn btn-secondary"
                              to={`/vv/voucher/campaigns/${partnerName}/${partnerId}`}>
                            {
                                !isFinished ?
                                    t('voucherForm->btnCancel')
                                    :
                                    t('voucherForm->btnBack')
                            }
                        </Link>
                    </ButtonToolbar>
                </form>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        theme: state.theme.className,
        languages: state.theme.data,
        formSyncErrors: getFormSyncErrors('voucherForm')(state)
    };
};

const translationWrapped = withTranslation(['voucher', 'modal', 'common'])(VoucherForm);

const formWrapped = reduxForm({
    form: 'voucherForm',
})(translationWrapped);

export default connect(mapStateToProps)(formWrapped);
