import React from "react";
import {Field, FormSection} from "redux-form";
import {ButtonToolbar, Button, ButtonGroup} from "reactstrap";
import {maxLength12, required, coordinates} from "../../../../shared/validations";
import {withTranslation} from "react-i18next";
import InputElement from "../../../../shared/components/form/Input";
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE} from "../variables";
import DataTableElement from "../../../../shared/components/common/DataTable";
import PartnerAddPOSForm from "./PartnerAddPOSForm";
import ToggleElement from "../../../../shared/components/form/Toggle";
import SelectElement from "../../../../shared/components/form/Select";
import ModalConfirmation from "../../../../shared/components/common/ModalConfirmation";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CloseIcon from "mdi-react/CloseIcon";
import TrashCanOutlineIcon from "mdi-react/TrashCanOutlineIcon";

class PartnerPOSDataTable extends React.Component {
    state = {
        rowsPerPage: ROWS_PER_PAGE,
        pageSize: PAGE_SIZE,
        currentPage: CURRENT_PAGE,
        POSDefaultPage: 1,
        POSGoToFinalPage: false
    };

    handleFilterData = () => {
        this.props.handleFilterData('pos');
    }

    handleFilterClear = () => {
        this.props.handleFilterClear('pos');
    }

    renderPOSTableColumns() {
        const {t, isEnabledFilterOptions, posFormValues} = this.props;

        return [
            {
                name: t('partnerForm->tableColumnName'),
                selector: 'name',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.pos.name"
                            id="filter.pos.name"
                            component={InputElement}
                        />
                        :
                        <>
                            <Field
                                name={`pos[${row.internalId}].name`}
                                id={`pos[${row.internalId}].name`}
                                component={InputElement}
                                validate={required}
                                onBlur={() => this.props.checkPointOfSaleValues(row.internalId, 'name')}
                            />
                            {
                                posFormValues && posFormValues[row.internalId].nameNotValid &&
                                <span className="form__form-custom-error">
                                    {t('partnerForm->labelCustomErrorSameValue', {attribute: t('partnerForm->tableColumnName'), attributePlural: ''})}
                                </span>
                            }
                        </>
                )
            },
            {
                name: t('partnerForm->tableColumnReference'),
                selector: 'reference',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.pos.reference"
                            id="filter.pos.reference"
                            component={InputElement}
                        />
                        :
                        <>
                            <Field
                                name={`pos[${row.internalId}].reference`}
                                id={`pos[${row.internalId}].reference`}
                                component={InputElement}
                                validate={required}
                                onBlur={() => this.props.checkPointOfSaleValues(row.internalId, 'reference')}
                            />
                            {
                                posFormValues && posFormValues[row.internalId].referenceNotValid &&
                                <span className="form__form-custom-error">
                                    {t('partnerForm->labelCustomErrorSameValue', {attribute: t('partnerForm->tableColumnReference'), attributePlural: ''})}
                                </span>
                            }
                        </>
                )
            },
            {
                name: t('partnerForm->tableColumnLatitude'),
                selector: 'latitude',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.pos.latitude"
                            id="filter.pos.latitude"
                            component={InputElement}

                        />
                        :
                        <>
                            <Field
                                name={`pos[${row.internalId}].latitude`}
                                id={`pos[${row.internalId}].latitude`}
                                component={InputElement}
                                isLatitudeLongitude
                                validate={[required, coordinates, maxLength12]}
                                onBlur={() => this.props.checkPointOfSaleValues(row.internalId, 'latitude')}
                            />
                            {
                                posFormValues && posFormValues[row.internalId].latitudeNotValid &&
                                <span className="form__form-custom-error">
                                    {t('partnerForm->labelCustomErrorSameValue', {attribute: t('partnerForm->labelCoordinates'), attributePlural: 's'})}
                                </span>
                            }
                        </>
                )
            },
            {
                name: t('partnerForm->tableColumnLongitude'),
                selector: 'longitude',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.pos.longitude"
                            id="filter.pos.longitude"
                            component={InputElement}
                        />
                        :
                        <Field
                            name={`pos[${row.internalId}].longitude`}
                            id={`pos[${row.internalId}].longitude`}
                            component={InputElement}
                            isLatitudeLongitude
                            validate={[required, coordinates, maxLength12]}
                            onBlur={() => this.props.checkPointOfSaleValues(row.internalId, 'longitude')}
                        />
                )
            },
            {
                name: t('partnerForm->tableColumnActive'),
                button: true,
                maxWidth: '200px',
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.pos.isEnabled"
                            id="filter.pos.isEnabled"
                            component={SelectElement}
                            options={isEnabledFilterOptions}
                            defaultValue={isEnabledFilterOptions[0]}
                        />
                        :
                        <Field
                            id={`pos[${row.internalId}].isEnabled`}
                            name={`pos[${row.internalId}].isEnabled`}
                            component={ToggleElement}
                            onChange={() => this.setState({changed: true})}
                        />
                )
            },
            {
                button: true,
                cell: row =>
                    <ButtonToolbar>
                        <ButtonGroup className="btn-group--icons" dir="ltr">
                            {
                                row.id === -1 ?
                                    <>
                                        <Button outline onClick={this.handleFilterData}
                                                title={t('common:buttonTitleAttributes->filter')}>
                                            <MagnifyIcon/>
                                        </Button>
                                        <Button outline color="danger" onClick={this.handleFilterClear}
                                                title={t('common:buttonTitleAttributes->clear')}>
                                            <CloseIcon/>
                                        </Button>
                                    </>
                                    :
                                    <ModalConfirmation
                                        backdrop="static"
                                        color="danger"
                                        firstBtnText={t('modal:modal->buttonNo')}
                                        secondBtnText={t('modal:modal->buttonYes')}
                                        title={t('modal:modalDeleteTitle')}
                                        btnTitle={t('common:buttonTitleAttributes->delete')}
                                        btn={<TrashCanOutlineIcon/>}
                                        message={<p>{t('modal:modalDeleteAllMessage')}</p>}
                                        handleOnClick={() => this.props.removeItem(row, 'pos')}
                                        closeOnAction
                                    />
                            }
                        </ButtonGroup>
                    </ButtonToolbar>
            }
        ]
    }

    render() {
        const {rowsPerPage, pageSize, POSDefaultPage, POSGoToFinalPage} = this.state;
        const {t, theme, POS, POSToggleCleared, newPOSFormValues} = this.props;

        return (
            <>
                {
                    <div className="react-datatable__filters">
                        <DataTableElement
                            keyField="internalId"
                            theme={theme}
                            columns={this.renderPOSTableColumns()}
                            data={POS}
                            paginationDefaultPage={POSDefaultPage}
                            paginationResetDefaultPage={POSGoToFinalPage}
                            paginationRowsPerPageOptions={rowsPerPage}
                            paginationPerPage={pageSize}
                            selectableRows
                            onSelectedRowsChange={this.props.handleSelectedRowsChange}
                            contextActions={this.props.handleContextActions}
                            contextMessage={{
                                singular: t('partnerForm->productSelectedRowsSingular'),
                                plural: t('partnerForm->productSelectedRowsPlural')
                            }}
                            receivePerRowsChange
                            handlePerRowsChange={this.props.handlePerRowsChange}
                            withFilters
                            selectableRowDisabled={row => row.id === -1}
                            clearSelectedRows={POSToggleCleared}
                        />
                    </div>
                }
                <FormSection name="newPOS">
                    <PartnerAddPOSForm checkPointOfSaleValues={this.props.checkPointOfSaleValues} onAddPOS={pos => this.props.addItem(pos, 'pos')} newPOSFormValues={newPOSFormValues}/>
                </FormSection>
            </>
        )
    }
}

export default withTranslation('partner')(PartnerPOSDataTable);
