import React from "react";
import {Button, Col, Row} from "reactstrap";
import {Field} from "redux-form";
import PlusIcon from "mdi-react/PlusIcon";
import MinusIcon from "mdi-react/MinusIcon";
import AutoComplete from "../../../../shared/components/form/AutoComplete";
import Alert from "../../../../shared/components/common/Alert";
import axios from "axios";

class VoucherProducts extends React.Component {
    state = {
        filterSelectedProductsText: '',
        isLoading: false
    };

    fetchProductsOptions = async value => {
        if (value.trim()) {
            this.setState({isLoading: true});
            try {
                const params = {partnerId: this.props.partnerId, page: 1, pageSize: 10, name: value}
                const response = await axios.get('/Products/GetProducts', {
                    headers: {language: 'pt-PT'},
                    params
                });
                if (response) {
                    this.props.fetchedProductsOptions(response.data.products);
                    this.setState({isLoading: false});
                }
            } catch (e) {
                console.log(e)
            }
        }
    };

    addProduct = () => {
        this.props.addItem(this.state.productSearch, 'product');
        this.setState({productSearch: null});
    };

    render() {
        const {t, availableProducts, selectedProducts, isEditable} = this.props;
        const {filterSelectedProductsText, productSearch} = this.state;
        const filteredSelectedProducts = selectedProducts.filter(prod => prod.name.toLowerCase().includes(filterSelectedProductsText));

        const filteredAvailableProducts = availableProducts.filter(product => product.isEnabled);

        const options = filteredAvailableProducts.map(availableProduct => {
            return {
                label: availableProduct.name,
                value: availableProduct.id,
                isDisabled: selectedProducts.some(selectProduct => selectProduct.productId === availableProduct.id)
            }
        });

        return (
            <>
                <Row>
                    <Col sm={6}>
                        <Alert color="info" className="alert--neutral alert-period" icon>
                            <p>
                                {t('voucherForm->labelInfoNoteProductsMessage')}
                            </p>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Row className="align-items-end">
                            <Col md={10}>
                                <Field
                                    name="productSearch"
                                    id="productSearch"
                                    component={AutoComplete}
                                    label={t('voucherForm->labelAddNewProduct')}
                                    placeholder={t('voucherForm->labelProductPlaceholder')}
                                    async
                                    disableCreateOption={true}
                                    options={options}
                                    onChange={value => this.setState({
                                        productSearch: value
                                    })}
                                    handleInputChange={value => this.fetchProductsOptions(value)}
                                    disabled={!isEditable}
                                />
                            </Col>
                            <Col md={2}>
                                <Button color="primary" type="button" disabled={!productSearch}
                                        onClick={this.addProduct}><PlusIcon/></Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={6}>
                        <div className="d-flex justify-content-between align-items-center mb-1">
                            <span>{t('voucherForm->labelSelected') + ` (${selectedProducts.length})`}</span>
                            {
                                isEditable &&
                                <button className="panel__btn" type="button"
                                        onClick={() => this.props.removeAllItems('products')}>{t('voucherForm->labelCleanSelection')}</button>
                            }

                        </div>
                        <div className="form">
                            <div className="form__form-group products-list__search">
                                <label htmlFor="search" className="sr-only">
                                    {t('voucherForm->labelSearchTextPlaceholder')}
                                </label>
                                <input
                                    type="text"
                                    id="filterSelectedProductsText"
                                    name="filterSelectedProductsText"
                                    value={this.state.filterSelectedProductsText}
                                    onChange={e => this.setState({filterSelectedProductsText: e.target.value})}
                                    placeholder={t('voucherForm->labelSearchTextPlaceholder')}
                                    autoComplete="off"
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <ul className="list">
                            {filteredSelectedProducts.map(prod =>
                                <li className="list__item" key={prod.productId}>
                                    <span>{prod.name}</span>
                                    {
                                        isEditable &&
                                        <button
                                            className="panel__btn"
                                            type="button"
                                            onClick={() => this.props.removeItem(prod, 'product')}
                                        >
                                            <MinusIcon className="list__item-icon"/>
                                        </button>
                                    }

                                </li>
                            )}
                        </ul>
                    </Col>
                </Row>
            </>
        )
    }
}


export default VoucherProducts;