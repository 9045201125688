import React from "react";
import {Field} from "redux-form";
import {Row, Col, Button} from "reactstrap";
import { withTranslation } from "react-i18next";
import SelectElement from "../../../../shared/components/form/Select";

class LocationServicesForm extends React.Component {
    state = {
        selectedService: ""
    };

    addService = () => {
        if (!this.state.selectedService)
            return;
        this.props.addService(this.state.selectedService);
        this.setState({
            selectedService: {}
        });
    }

    render() {
        const { t, serviceTypesOptions, selectedServices } = this.props;
        const { selectedService } = this.state;

        const options = serviceTypesOptions
            .filter(item => !selectedServices.some((s) => s.id.toString() === item.key))
            .map(item => {
                return {
                    label: item.value,
                    value: item.key,
                    type: parseInt(item.type)
                }
            });
        //sort by name
        options.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

        return (
            <>
                <p className='bold-text'>{t("locationForm->servicesFormTitle")}</p>
                <Row className="align-items-end">
                    <Col sm={10}>
                        <Field
                            name="serviceTypeId"
                            id="serviceTypeId"
                            component={SelectElement}
                            options={options}
                            label={t('locationForm->servicesFormName')}
                            placeholder={t('common:labelSelectOptionPlaceholder')}
                            input={{
                                onChange: (service) => {
                                    this.setState({ selectedService: service })
                                },
                                value: selectedService
                            }}
                        />
                    </Col>
                    <Col sm={2}>
                        <Button
                            id="btnAddProduct"
                            color="primary"
                            type="button"
                            onClick={this.addService}
                            disabled={!selectedService || Object.keys(selectedService).length === 0}
                        >
                            {t('locationForm->servicesFormAddBtn')}
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withTranslation("location")(LocationServicesForm);


