import {combineReducers} from 'redux';
import {reducer as formReducer} from "redux-form";
import authReducer from './authReducer';
import themeReducer from './themeReducer';
import sidebarReducer from './sidebarReducer';
import contentReducer from './contentReducer';
import serviceCatReducer from './serviceCatReducer';
import serviceReducer from './serviceReducer';
import locationReducer from './locationReducer';
import selectOptionReducer from './selectOptionReducer';
import partnerReducer from './partnerReducer';
import posReducer from './posReducer';
import productReducer from './productReducer';
import tollBalanceReducer from './tollBalanceReducer';
import voucherReducer from './voucherReducer';
import bannersReducer from './bannersReducer';

export default combineReducers({
    form: formReducer,
    auth: authReducer,
    selectOptions: selectOptionReducer,
    theme: themeReducer,
    sidebar: sidebarReducer,
    contents: contentReducer,
    servicesCats: serviceCatReducer,
    services: serviceReducer,
    locations: locationReducer,
    partners: partnerReducer,
    pointsOfSale: posReducer,
    tollBalances: tollBalanceReducer,
    products: productReducer,
    vouchers: voucherReducer,
    banners: bannersReducer
});
