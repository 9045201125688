import axios from 'axios';
import history from '../../history';

export const FETCH_PARTNERS = 'FETCH_PARTNERS';
export const FETCH_PARTNER = 'FETCH_PARTNER';
export const CREATE_PARTNER = 'CREATE_PARTNER';
export const EDIT_PARTNER = 'EDIT_PARTNER';
export const TOGGLE_PARTNER = 'TOGGLE_PARTNER';
export const DELETE_PARTNER = 'DELETE_PARTNER';
export const DONE_PARTNER = 'DONE_PARTNER';
export const PENDING_PARTNER = 'PENDING_PARTNER';
export const ERROR_PARTNER = 'ERROR_PARTNER';

const initialFilter = {
    name: null,
    isEnabled: null,
};

export const fetchPartners = (page, pageSize, filters = initialFilter) => async dispatch => {
    dispatch({type: PENDING_PARTNER});
    try {
        const params = {
            "page": page,
            "pageSize": pageSize,
            "name": filters.name,
            "isEnabled": filters.isEnabled
        };
        const response = await axios.get('/Partners/GetPartners', { headers: { language: localStorage.getItem('i18nextLng') }, params: params});

        if (response) {
            dispatch({type: FETCH_PARTNERS, payload: response.data});
            dispatch({type: DONE_PARTNER, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_PARTNER, payload: e.message});
    }
};

export const fetchPartner = id => async dispatch => {
    dispatch({type: PENDING_PARTNER});
    try {
        const response = await axios.get(`/Partners/GetById/${id}`);
        if (response) {
            dispatch({type: FETCH_PARTNER, payload: response.data});
            dispatch({type: DONE_PARTNER});
        }
    } catch (e) {
        dispatch({type: ERROR_PARTNER, payload: e.message});
    }
};

export const createPartner = formValues => async dispatch => {
    try {
        const response = await axios.post('/Partners', formValues);
        if (response) {
            dispatch({type: CREATE_PARTNER, payload: 'operationSuccess'});
            history.replace('/vv/partner');
        }
    } catch (e) {
        dispatch({type: ERROR_PARTNER, payload: e.message});
    }
};

export const editPartner = formValues => async dispatch => {
    try {
        const response = await axios.put('/Partners', formValues);
        if (response) {
            dispatch({type: EDIT_PARTNER, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_PARTNER, payload: e.message});
    }
};

export const togglePartner = formValues => async dispatch => {
    dispatch({type: PENDING_PARTNER});
    try {
        const response = await axios.patch('/Partners', {Id: formValues.id, IsEnabled: !formValues.isEnabled});
        if (response) {
            formValues.isEnabled = !formValues.isEnabled;
            dispatch({type: TOGGLE_PARTNER, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_PARTNER, payload: e.message});
    }
};

export const deletePartner = (id, type) => async dispatch => {
    dispatch({type: PENDING_PARTNER});
    try {
        const response = await axios.delete(`/Partners/${id}`);
        if (response) {
            dispatch({type: DELETE_PARTNER, payload: 'operationSuccess'});
            history.replace('/vv/partner');
        }
    } catch (e) {
        dispatch({type: ERROR_PARTNER, payload: e.message});
    }
};