import _ from 'lodash';
import {
    CREATE_TOLLBALANCE,
    DELETE_TOLLBALANCE,
    DONE_TOLLBALANCE,
    EDIT_TOLLBALANCE,
    ERROR_TOLLBALANCE,
    FETCH_TOLLBALANCE,
    FETCH_TOLLBALANCES,
    FETCH_TOLLBALANCE_LIST,
    PENDING_TOLLBALANCE,
    TOGGLE_TOLLBALANCE,
    FETCH_TOLLBALANCE_TIERS,
    FETCH_TOLLBALANCE_STOCK_TYPES
} from '../actions/tollBalanceActions';

const initialState = {
    done: {
        status: false,
        message: null
    },
    data: {
        tollBalances: [],
        totalItems: null,
        tiers: [],
        stockUnitTypes: []
    },
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TOLLBALANCES:
            return {
                ...state,
                data: {
                    tollBalances: _.mapKeys(action.payload.partners, 'id'),
                    totalItems: action.payload.total,
                    tiers: state.data.tiers,
                    stockUnitTypes: state.data.stockUnitTypes
                }
            };
        case FETCH_TOLLBALANCE_LIST:
            return {
                ...state,
                data: {
                    tollBalances: action.payload.campaigns, //prevent from sorting
                    totalItems: action.payload.total,
                    tiers: state.data.tiers,
                    stockUnitTypes: state.data.stockUnitTypes
                }
            };
        case FETCH_TOLLBALANCE:
            return {
                ...state,
                data: {
                    tollBalances: {[action.payload.id]: action.payload},
                    tiers: state.data.tiers,
                    stockUnitTypes: state.data.stockUnitTypes
                }
            };
        case CREATE_TOLLBALANCE:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case EDIT_TOLLBALANCE:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case TOGGLE_TOLLBALANCE:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DELETE_TOLLBALANCE:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DONE_TOLLBALANCE:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case PENDING_TOLLBALANCE:
            return {...state, done: {status: false, message: null}, error: null};
        case ERROR_TOLLBALANCE:
            return {...state, done: {status: true, message: null}, error: action.payload};
        case FETCH_TOLLBALANCE_TIERS:
            return {
                ...state,
                data: {
                    tollBalances: state.data.tollBalances,
                    tiers: _.mapKeys(action.payload, 'id'),
                    totalItems: state.data.totalItems,
                    stockUnitTypes: state.data.stockUnitTypes
                }
            };
        case FETCH_TOLLBALANCE_STOCK_TYPES:
            return {
                ...state,
                data: {
                    tollBalances: state.data.tollBalances,
                    stockUnitTypes: _.mapKeys(action.payload, 'id'),
                    totalItems: state.data.totalItems,
                    tiers: state.data.tiers
                }
            };
        default:
            return state;
    }
}