import {
    FETCH_SELECT_OPTIONS,
    DONE_SELECT_OPTIONS,
    PENDING_SELECT_OPTIONS,
    ERROR_SELECT_OPTIONS
} from "../actions/selectOptionActions";

const initialState = {
    done: false,
    data: [],
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SELECT_OPTIONS:
            return {...state, data: action.payload};
        case DONE_SELECT_OPTIONS:
            return {...state, done: true, error: null};
        case PENDING_SELECT_OPTIONS:
            return {...state, done: false, error: null};
        case ERROR_SELECT_OPTIONS:
            return {...state, done: true, error: action.payload};
        default:
            return state;
    }
}