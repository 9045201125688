import _ from 'lodash';
import {
    CREATE_SERVICECAT,
    FETCH_SERVICECAT,
    FETCH_SERVICECATS,
    PENDING_SERVICECAT,
    ERROR_SERVICECAT,
    DONE_SERVICECAT,
    DELETE_SERVICECAT,
    EDIT_SERVICECAT,
    TOGGLE_SERVICECAT
} from '../actions/serviceCatActions';

const initialState = {
    done: {
        status: false,
        message: null
    },
    data: {
        categories: [],
        totalItems: null
    },
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SERVICECATS:
            return {
                ...state,
                data: {categories: _.mapKeys(action.payload.categories, 'id'), totalItems: action.payload.total}
            };
        case FETCH_SERVICECAT:
            return {...state, data: {categories: {[action.payload.id]: action.payload}}};
        case CREATE_SERVICECAT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case EDIT_SERVICECAT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case TOGGLE_SERVICECAT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DELETE_SERVICECAT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DONE_SERVICECAT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case PENDING_SERVICECAT:
            return {...state, done: {status: false, message: null}, error: null};
        case ERROR_SERVICECAT:
            return {...state, done: {status: true, message: null}, error: action.payload};
        default:
            return state;
    }
}