import React from 'react';
import axios from 'axios';
import history from '../../history';

export const FETCH_BANNERS = 'FETCH_BANNERS';
export const FETCH_BANNER = 'FETCH_BANNER';
export const CREATE_BANNER = 'CREATE_BANNER';
export const EDIT_BANNER = 'EDIT_BANNER';
export const TOGGLE_BANNER = 'TOGGLE_BANNER';
export const DELETE_BANNER = 'DELETE_BANNER';
export const DONE_BANNER = 'DONE_BANNER';
export const PENDING_BANNER = 'PENDING_BANNER';
export const ERROR_BANNER = 'ERROR_BANNER';

export const fetchBanners = (page, pageSize, title) => async dispatch => {
    dispatch({ type: PENDING_BANNER});
    try {
        const params = { "page": page, "pageSize": pageSize, "title": title};
        const response = await axios.get('/Banners/GetBanners', { headers: { language: localStorage.getItem('i18nextLng') }, params: params });

        if (response) {
            dispatch({ type: FETCH_BANNERS, payload: response.data});
            dispatch({ type: DONE_BANNER, payload: null});
        }
    } catch (e) {
        dispatch({ type: ERROR_BANNER, payload: e.message});
    }
};

export const fetchBanner = id => async dispatch => {
    dispatch({ type: PENDING_BANNER});
    try {
        const response = await axios.get(`/Banners/GetById/${id}`);
        if (response) {
            dispatch({ type: FETCH_BANNER, payload: response.data});
            dispatch({ type: DONE_BANNER});
        }
    } catch (e) {
        dispatch({ type: ERROR_BANNER, payload: e.message});
    }
};

export const createBanner = formValues => async dispatch => {
    try {
        const response = await axios.post('/Banners', formValues);
        if (response) {
            dispatch({ type: CREATE_BANNER, payload: 'operationSuccess'});
            history.replace("/banners");
        }
    } catch (e) {
        dispatch({ type: ERROR_BANNER, payload: e.message});
    }
};

export const editBanner = formValues => async dispatch => {
    try {
        const response = await axios.put('/Banners', formValues);
        if (response) {
            dispatch({type: EDIT_BANNER, payload: 'operationSuccess'});
            history.replace('/banners');
        }
    } catch (e) {
        dispatch({ type: ERROR_BANNER, payload: e.message});
    }
};

export const toggleBanner = formValues => async dispatch => {
    dispatch({ type: PENDING_BANNER});
    try {
        const response = await axios.patch('/banners', {Id: formValues.id, IsEnabled: !formValues.isEnabled});
        if (response) {
            formValues.isEnabled = !formValues.isEnabled;
            dispatch({ type: TOGGLE_BANNER, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({ type: ERROR_BANNER, payload: e.message});
    }
};

export const deleteBanner = (id, type, pageSize, currentPage) => async dispatch => {
    dispatch({ type: PENDING_BANNER});
    try {
        const response = await axios.delete(`/Banners/${id}`);
        if (response) {
            dispatch({ type: DELETE_BANNER, payload: 'operationSuccess'});
            if (type === 'list') {
                dispatch(fetchBanners(currentPage, pageSize));
            } else {
                history.replace('/banners');
            }
        }
    } catch (e) {
        dispatch({ type: ERROR_BANNER, payload: e.message});
    }
};