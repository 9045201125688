import React from "react";
import axios from "axios";
import {Field} from "redux-form";
import {Row, Col, Button} from "reactstrap";
import { withTranslation } from "react-i18next";
import AutoComplete from "../../../../shared/components/form/AutoComplete";

class LocationRelatedForm extends React.Component {
    state = {
        isLoading: false,
        locationOptions: [],
        selectedLocation: ""
    };

    addRelatedLocation = () => {
        if (!this.state.selectedLocation)
            return;
        this.props.addRelatedLocation(this.state.selectedLocation);
        this.setState({
            selectedLocation: {}
        });
    }

    fetchLocationOptions = async value => {
        if (value.trim()) {
            this.setState({ isLoading: true });
            try {
                const params = { page: 1, pageSize: 10, name: value }
                const response = await axios.get('/Locations/GetLocations', {
                    headers: { language: 'pt-PT' },
                    params
                });
                if (response) {
                    this.setState({
                        isLoading: false,
                        locationOptions: response.data.locations
                    });
                }
            } catch (e) {
                console.log(e)
            }
        }
    };

    render() {
        const { t, selectedLocations, locationId } = this.props;
        const { locationOptions, selectedLocation } = this.state;

        const options = locationOptions
            .filter(item => (!selectedLocations.some((s) => s.id === item.id) && locationId !== item.id))
            .map(location => {
                return {
                    label: location.name,
                    value: location.id,
                }
            });

        return (
            <>
                <p className='bold-text'>{t("locationForm->relatedFormTitle")}</p>
                <Row className="align-items-end">
                    <Col sm={10}>
                        <Field
                            name="locationSearch"
                            id="locationSearch"
                            component={AutoComplete}
                            options={options}
                            async
                            label={t('locationForm->relatedFormName')}
                            placeholder={t('common:labelSelectOptionPlaceholder')}
                            disableCreateOption={true}
                            input={{
                                onChange: value => this.setState({ selectedLocation: value }),
                                value: selectedLocation
                            }}
                            handleInputChange={value => this.fetchLocationOptions(value)}
                        />
                    </Col>
                    <Col sm={2}>
                        <Button
                            id="btnAddProduct"
                            color="primary"
                            type="button"
                            onClick={this.addRelatedLocation}
                            disabled={!selectedLocation || Object.keys(selectedLocation).length === 0}
                        >
                            {t('locationForm->relatedFormAddBtn')}
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}
export default withTranslation("location")(LocationRelatedForm);


