import React from "react";
import { connect } from "react-redux";
import { createServiceCat } from "../../../redux/actions/serviceCatActions";
import { fetchLanguages } from "../../../redux/actions/themeActions";
import { store } from "react-notifications-component";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import ServiceCatForm from "./ServiceCatForm";
import { Card, CardBody, Container } from "reactstrap";
import Alert from "../../../shared/components/common/Alert";
import { Link } from "react-router-dom";

class ServiceCatCreate extends React.Component {
  componentDidUpdate() {
    if (this.props.message) {
      store.addNotification({
        title: this.props.t("success->title"),
        message: this.props.t("success->message"),
        type: "success",
        insert: "bottom",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "zoomOut"],
        dismiss: {
          duration: 3000,
          showIcon: true,
        },
      });
    }
  }

  onSubmit = (formValues) => {
    this.props.createServiceCat(formValues);
  };

  renderError = () => {
    const { t, serviceCatError } = this.props;

    return (
      <Container>
        <Card>
          <CardBody>
            <Alert color='danger' className='alert--bordered' icon>
              <>
                <p className='bold-text'>{t("alert:danger->title")}</p>
                <p>{t("alert:danger->message", { error: serviceCatError })}</p>
                <Link to='/serviceCat'>{t("alert:danger->goBack")}</Link>
              </>
            </Alert>
          </CardBody>
        </Card>
      </Container>
    );
  };

  render() {
    const { t, languages, languagesDone } = this.props;
    if (!languagesDone) {
      return (
        <div className='d-flex justify-content-center'>
          <div>
            <img
              width='100'
              height='100'
              src={`${process.env.PUBLIC_URL}/img/load.gif`}
              alt='Loading'
            />
          </div>
        </div>
      );
    }
    /*if (this.props.error) {
            return (
                <div className="text-center">
                    A error occured while fetching data.
                    <p>Error Code: {this.props.error}</p>
                </div>
            );
        }*/

    return (
      <ServiceCatForm
        onSubmit={this.onSubmit}
        btnText={t("content:contentForm->btnCreate")}
        languages={languages}
        initialValues={{ isEnabled: true }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languages: state.theme.data,
    languagesDone: state.theme.done.status,
  };
};

export default compose(
  withTranslation(["servicesCat", "notification"]),
  connect(mapStateToProps, { createServiceCat, fetchLanguages })
)(ServiceCatCreate);
