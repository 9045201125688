import React from "react";
import {Field} from "redux-form";
import {Row, Col, Button} from "reactstrap";
import {withTranslation} from "react-i18next";
import InputElement from "../../../../shared/components/form/Input";
import AutoComplete from "../../../../shared/components/form/AutoComplete";
import SelectElement from "../../../../shared/components/form/Select";
import axios from "axios";

class TollBalanceAddProductForm extends React.Component {
    state = {
        newProduct: {
            stockUnitType: this.props.stockUnitTypesOptions[0]
        },
        isLoading: false
    };

    componentDidMount() {
        this.props.change('newProd.stockUnitType', this.props.stockUnitTypesOptions[0]);
    }

    fetchProductsOptions = async value => {
        if (value.trim()) {
            this.setState({isLoading: true});
            try {
                const params = {partnerId: this.props.partnerId, page: 1, pageSize: 10, name: value}
                const response = await axios.get('/Products/GetProducts', {
                    headers: {language: 'pt-PT'},
                    params
                });
                if (response) {
                    this.props.fetchedProductsOptions(response.data.products);
                    this.setState({isLoading: false});
                }
            } catch (e) {
                console.log(e)
            }
        }
    };

    addProduct = () => {
        this.setState({newProduct: {stockUnitType: this.props.stockUnitTypesOptions[0]}});
        this.props.handleAddProduct(this.state.newProduct);
    }

    render() {
        const {newProduct} = this.state;
        const {t, isEditable, options, stockUnitTypesOptions, isPermanent} = this.props;

        return (
            <>
                <p className="bold-text">{t('tollBalanceForm->labelNewProduct')}</p>

                <Row className="align-items-end">
                    <Col sm={6}>
                        <Field
                            placeholder={t('tollBalanceForm->labelProductPlaceholder')}
                            name="newProd.productSearch"
                            id="newProd.productSearch"
                            component={AutoComplete}
                            disableCreateOption={true}
                            options={options}
                            async
                            label={t('tollBalanceForm->tableColumnProduct')}
                            onChange={e => this.setState(state => ({
                                newProduct: {
                                    ...state.newProduct,
                                    productSearch: e
                                }
                            }))}
                            handleInputChange={value => this.fetchProductsOptions(value)}
                            disabled={!isEditable}
                        />
                    </Col>
                    <Col sm={1}>
                        <Field
                            name="newProd.stockUnitType"
                            id="newProd.stockUnitType"
                            component={SelectElement}
                            options={stockUnitTypesOptions}
                            defaultValue={stockUnitTypesOptions[0]}
                            label={t('tollBalanceForm->tableColumnStockType')}
                            onChange={e => this.setState(state => ({
                                newProduct: {
                                    ...state.newProduct,
                                    stockUnitType: e
                                }
                            }))}
                            disabled={!isEditable}
                        />
                    </Col>
                    <Col sm={1}>
                        <Field
                            name="newProd.silverTier"
                            id="newProd.silverTier"
                            component={InputElement}
                            label={t('tollBalanceForm->tableColumnSilver')}
                            onChange={e => this.setState(state => ({
                                newProduct: {
                                    ...state.newProduct,
                                    silverTier: e.target.value
                                }
                            }))}
                            disabled={!isEditable}
                        />
                    </Col>
                    <Col sm={1}>
                        <Field
                            name="newProd.goldTier"
                            id="newProd.goldTier"
                            component={InputElement}
                            label={t('tollBalanceForm->tableColumnGold')}
                            onChange={e => this.setState(state => ({
                                newProduct: {
                                    ...state.newProduct,
                                    goldTier: e.target.value
                                }
                            }))}
                            disabled={!isEditable}
                        />
                    </Col>
                    {!isPermanent &&
                    <Col sm={1}>
                        <Field
                            name="newProd.initialStock"
                            id="newProd.initialStock"
                            component={InputElement}
                            label={t('tollBalanceForm->tableColumnInitialStock')}
                            onChange={e => this.setState(state => ({
                                newProduct: {
                                    ...state.newProduct,
                                    initialStock: e.target.value
                                }
                            }))}
                            disabled={!isEditable}
                        />
                    </Col>
                    }
                    <Col sm={1}>
                        <Field
                            name="newProd.fee"
                            id="newProd.fee"
                            component={InputElement}
                            label={t('tollBalanceForm->tableColumnFee')}
                            onChange={e => this.setState(state => ({
                                newProduct: {
                                    ...state.newProduct,
                                    fee: e.target.value
                                }
                            }))}
                            disabled={!isEditable}
                        />
                    </Col>
                    <Col sm={1}>
                        <Button
                            id="btnAddProduct"
                            color="primary"
                            type="button"
                            onClick={this.addProduct}
                            disabled={!newProduct.productSearch || !newProduct.stockUnitType || !newProduct.silverTier || !newProduct.goldTier || !newProduct.fee}
                        >
                            {t('tollBalanceForm->btnAddProduct')}
                        </Button>
                    </Col>
                </Row>
            </>

        );
    }
}

export default withTranslation("tollBalance")(TollBalanceAddProductForm);