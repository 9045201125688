import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import ServiceCatForm from "./ServiceCatForm";
import {fetchLanguages} from "../../../redux/actions/themeActions";
import {
    fetchServiceCat,
    editServiceCat,
    deleteServiceCat,
} from "../../../redux/actions/serviceCatActions";
import {store} from "react-notifications-component";
import {Container, Card, CardBody} from "reactstrap";
import Alert from "../../../shared/components/common/Alert";
import {Link} from "react-router-dom";

class ServiceCatEdit extends React.Component {
    componentDidMount() {
        this.props.fetchServiceCat(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message && this.props.message) {
            store.addNotification({
                title: this.props.t("notification:success->title"),
                message: this.props.t("notification:success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                },
            });
        }
    }

    onSubmit = (formValues) => {
        this.props.editServiceCat(formValues);
    };

    onDelete = (id) => {
        this.props.deleteServiceCat(id, "form");
    };

    renderError = () => {
        const {t, serviceCatError, languagesError} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color='danger' className='alert--bordered' icon>
                            <>
                                <p className='bold-text'>{t("alert:danger->title")}</p>
                                <p>{t("alert:danger->message", {error: serviceCatError || languagesError})}</p>
                                <Link to='/vvservices/categories'>{t("alert:danger->goBack")}</Link>
                            </>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    };

    render() {
        const {t, serviceCatDone, serviceCatError, serviceCat, languages, languagesDone, languagesError} = this.props;

        if (!serviceCatDone || !languagesDone) {
            return (
                <div className='d-flex justify-content-center'>
                <div>
                  <img
                    width='100'
                    height='100'
                    src={`${process.env.PUBLIC_URL}/img/load.gif`}
                    alt='Loading'
                  />
                </div>
              </div>
            );
        }

        if (serviceCatError) {
            return this.renderError();
        }

        return (
            <ServiceCatForm
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                languages={languages}
                type='edit'
                btnText={t("servicesCat:serviceCatForm->btnSave")}
                initialValues={serviceCat}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        serviceCat: state.servicesCats.data.categories[ownProps.match.params.id],
        serviceCatDone: state.servicesCats.done.status,
        serviceCatError: state.servicesCats.error,
        message: state.servicesCats.done.message,
        languages: state.theme.data,
        languagesDone: state.theme.done.status,
        languagesError: state.theme.error
    };
};
export default compose(
    withTranslation(["serviceCat", "notification", "alert"]),
    connect(mapStateToProps, {
        fetchServiceCat,
        editServiceCat,
        deleteServiceCat,
        fetchLanguages,
    })
)(ServiceCatEdit);
