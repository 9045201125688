import React from "react";
import { FormSection } from "redux-form";
import { ButtonToolbar, Button, ButtonGroup } from "reactstrap";
import { withTranslation } from "react-i18next";
import { ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE } from "../variables";
import DataTableElement from "../../../../shared/components/common/DataTable";
import LocationRelatedForm from "./LocationRelatedForm";
import ModalConfirmation from "../../../../shared/components/common/ModalConfirmation";
import TrashCanOutlineIcon from "mdi-react/TrashCanOutlineIcon";

class LocationRelatedTable extends React.Component {
    state = {
        rowsPerPage: ROWS_PER_PAGE,
        pageSize: PAGE_SIZE,
        currentPage: CURRENT_PAGE,
        relatedDefaultPage: 1,
        relatedGoToFinalPage: false,
    };

    renderRelatedTableColumns() {
        const { t } = this.props;

        return [
            {
                name: t('locationForm->relatedTableName'),
                selector: 'name',
                sortable: true,
                wrap: true
            },
            {
                button: true,
                cell: row =>
                    <ButtonToolbar>
                        <ButtonGroup className="btn-group--icons" dir="ltr">
                            <ModalConfirmation
                                backdrop="static"
                                color="danger"
                                firstBtnText={t('modal:modal->buttonNo')}
                                secondBtnText={t('modal:modal->buttonYes')}
                                title={t('modal:modalDeleteTitle')}
                                btnTitle={t('common:buttonTitleAttributes->delete')}
                                btn={<TrashCanOutlineIcon />}
                                message={<p>{t('modal:modalDeleteMessage')}</p>}
                                handleOnClick={() => this.props.removeRelatedLocation(row)}
                                closeOnAction
                            />
                        </ButtonGroup>
                    </ButtonToolbar>
            }
        ]
    }

    render() {
        const { rowsPerPage, pageSize, relatedDefaultPage, relatedGoToFinalPage } = this.state;
        const { theme, relatedLocations, addRelatedLocation, locationId } = this.props;

        return (
            <>
                {
                    <div className="react-datatable__filters">
                        <DataTableElement
                            keyField="id"
                            theme={theme}
                            columns={this.renderRelatedTableColumns()}
                            data={relatedLocations}
                            paginationDefaultPage={relatedDefaultPage}
                            paginationResetDefaultPage={relatedGoToFinalPage}
                            paginationRowsPerPageOptions={rowsPerPage}
                            paginationPerPage={pageSize}
                        />
                    </div>
                }
                {
                    <FormSection name="addRelated">
                        <LocationRelatedForm
                            addRelatedLocation={addRelatedLocation}
                            selectedLocations={relatedLocations}
                            locationId={locationId}
                        />
                    </FormSection>
                }
            </>
        )
    }
}
export default withTranslation('location')(LocationRelatedTable);
