import {
    CHANGE_THEME_TO_DARK,
    CHANGE_THEME_TO_LIGHT,
    FETCH_LANGUAGES,
    ERROR_THEME,
    PENDING_THEME,
    DONE_THEME
} from '../actions/themeActions';

const initialState = {
    className: localStorage.getItem('theme') || 'theme-light',
    data: [],
    done: {
        status: false,
        message: null
    },
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CHANGE_THEME_TO_DARK:
            return {...state, className: 'theme-dark'};
        case CHANGE_THEME_TO_LIGHT:
            return {...state, className: 'theme-light'};
        case FETCH_LANGUAGES:
            return {...state, data: action.payload};
        case DONE_THEME:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case PENDING_THEME:
            return {...state, done: {status: false, message: null}, error: null};
        case ERROR_THEME:
            return {...state, done: {status: true, message: null}, error: action.payload};
        default:
            return state;
    }
}
