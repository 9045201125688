import React, {PureComponent} from "react";
import PropTypes from "prop-types";

class ToggleField extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
        ]).isRequired,
    };

    toggleValue = () => {
        const {onChange, value} = this.props;
        onChange(!value);
    }

    render() {
        const {name, value, onChange, id} = this.props;

        return (
            <label className="toggle-btn customizer__toggle" htmlFor={id}>
                <input
                    className="toggle-btn__input"
                    type="checkbox"
                    id={id}
                    name={name}
                    onChange={onChange}
                    checked={value}
                />
                <span className="toggle-btn__input-label" onClick={this.toggleValue}/>
            </label>
        );
    }
}

const ToggleElement = (props) => {
    const {input, defaultChecked, disabled, className, id} = props;

    return (
        <ToggleField
            {...input}
            id={id}
            defaultChecked={defaultChecked}
            disabled={disabled}
            className={className}
        />
    );
};

ToggleElement.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.bool,
        ]),
    }).isRequired,
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
};

export default ToggleElement;
