import React from "react";
import axios from "axios";
import history from "../../history";

export const CHANGE_SIDEBAR_VISIBILITY = 'CHANGE_SIDEBAR_VISIBILITY';
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY = 'CHANGE_MOBILE_SIDEBAR_VISIBILITY';
export const CLEAR_CACHE = 'CLEAR_CACHE';
export const ERROR_CACHE = 'ERROR_CACHE';
export const PENDING_MENU = 'PENDING_MENU';
export const FETCH_MENU = 'FETCH_MENU';
export const DONE_MENU = 'DONE_MENU';
export const ERROR_MENU = 'ERROR_MENU';

export function changeSidebarVisibility() {
    return {
        type: CHANGE_SIDEBAR_VISIBILITY,
    };
}

export function changeMobileSidebarVisibility() {
    return {
        type: CHANGE_MOBILE_SIDEBAR_VISIBILITY,
    };
}


export const clearCache = formValues => async dispatch => {
    try {
        const response = await axios.post('/RemoteCache/InvalidateCache');
        if (response) {
            dispatch({type: CLEAR_CACHE, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_CACHE, payload: e.message});
    }
};

export const fetchMenu = () => async dispatch => {
    dispatch({type: PENDING_MENU});
    try {
        const response = await axios.get(`/Settings/GetMenu`);
        if (response) {
            dispatch({type: FETCH_MENU, payload: response.data});
            dispatch({type: DONE_MENU});
        }
    } catch (e) {
        dispatch({type: ERROR_MENU, payload: e.message});
    }
};
