import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Content from './Content';
import Banner from './Banners';
import Service from './Service';
import ServiceCat from './ServiceCat';
import Location from './Location';
import Campaign from './Campaign/';

export default () => (
    <div>
        <Layout />
        <div className="container__wrap">
            <Route path="/content" component={Content} />
            <Route path="/banners" component={Banner} />
            <Route path="/vvservices/services" component={Service}/>
            <Route path="/vvservices/categories" component={ServiceCat}/>
            <Route path="/vvservices/location" component={Location}/>
            <Route path="/vv" component={Campaign}/>
        </div>
    </div>
);
