import _ from 'lodash';
import {
    CREATE_PRODUCT,
    DELETE_PRODUCT,
    DONE_PRODUCT,
    EDIT_PRODUCT,
    ERROR_PRODUCT,
    FETCH_PRODUCT,
    FETCH_PRODUCTS,
    PENDING_PRODUCT,
    TOGGLE_PRODUCT
} from '../actions/productActions';

const initialState = {
    done: {
        status: false,
        message: null
    },
    data: {
        products: [],
        totalItems: null
    },
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PRODUCTS:
            return {
                ...state,
                data: {products: _.mapKeys(action.payload.products, 'id'), totalItems: action.payload.total}
            };
        case FETCH_PRODUCT:
            return {...state, data: {products: {[action.payload.id]: action.payload}}};
        case CREATE_PRODUCT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case EDIT_PRODUCT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case TOGGLE_PRODUCT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DELETE_PRODUCT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DONE_PRODUCT:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case PENDING_PRODUCT:
            return {...state, done: {status: false, message: null}, error: null};
        case ERROR_PRODUCT:
            return {...state, done: {status: true, message: null}, error: action.payload};
        default:
            return state;
    }
}