import React from 'react';
import axios from 'axios';
import history from '../../history';

export const FETCH_CONTENTS = 'FETCH_CONTENTS';
export const FETCH_CONTENT = 'FETCH_CONTENT';
export const CREATE_CONTENT = 'CREATE_CONTENT';
export const EDIT_CONTENT = 'EDIT_CONTENT';
export const TOGGLE_CONTENT = 'TOGGLE_CONTENT';
export const DELETE_CONTENT = 'DELETE_CONTENT';
export const DONE_CONTENT = 'DONE_CONTENT';
export const PENDING_CONTENT = 'PENDING_CONTENT';
export const ERROR_CONTENT = 'ERROR_CONTENT';

export const fetchContents = (page, pageSize, key) => async dispatch => {
    dispatch({type: PENDING_CONTENT});
    try {
        const params = {"page": page, "pageSize": pageSize, "key": key, "groupKey": ""};
        const response = await axios.get('/Resources/GetResources', { headers: { language: localStorage.getItem('i18nextLng') }, params: params});
        if (response) {
            dispatch({type: FETCH_CONTENTS, payload: response.data});
            dispatch({type: DONE_CONTENT, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_CONTENT, payload: e.message});
    }
};

export const fetchContent = id => async dispatch => {
    dispatch({type: PENDING_CONTENT});
    try {
        const response = await axios.get(`/Resources/GetById/${id}`);
        if (response) {
            dispatch({type: FETCH_CONTENT, payload: response.data});
            dispatch({type: DONE_CONTENT});
        }
    } catch (e) {
        dispatch({type: ERROR_CONTENT, payload: e.message});
    }
};

export const createContent = formValues => async dispatch => {
    try {
        const response = await axios.post('/Resources', formValues);
        if (response) {
            dispatch({type: CREATE_CONTENT, payload: 'operationSuccess'});
            history.replace("/content");
        }
    } catch (e) {
        dispatch({type: ERROR_CONTENT, payload: e.message});
    }
};

export const editContent = formValues => async dispatch => {
    try {
        const response = await axios.put('/Resources', formValues);
        if (response) {
            dispatch({type: EDIT_CONTENT, payload: 'operationSuccess'});
            history.replace('/content');
        }
    } catch (e) {
        dispatch({type: ERROR_CONTENT, payload: e.message});
    }
};

export const toggleContent = formValues => async dispatch => {
    dispatch({type: PENDING_CONTENT});
    try {
        const response = await axios.patch('/Resources', {Id: formValues.id, IsEnabled: !formValues.isEnabled});
        if (response) {
            formValues.isEnabled = !formValues.isEnabled;
            dispatch({type: TOGGLE_CONTENT, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_CONTENT, payload: e.message});
    }
};

export const deleteContent = (id, type, pageSize, currentPage) => async dispatch => {
    dispatch({type: PENDING_CONTENT});
    try {
        const response = await axios.delete(`/Resources/${id}`);
        if (response) {
            dispatch({type: DELETE_CONTENT, payload: 'operationSuccess'});
            if (type === 'list') {
                dispatch(fetchContents(currentPage, pageSize));
            } else {
                history.replace('/content');
            }
        }
    } catch (e) {
        dispatch({type: ERROR_CONTENT, payload: e.message});
    }
};