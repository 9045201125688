import React from 'react';
import {Link} from 'react-router-dom';
import {Col, Container, Row, Card, CardBody, ButtonToolbar, Button} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {formValueSelector, reduxForm} from 'redux-form';
import {
    fetchTollBalanceCampaignList,
    toggleTollBalanceCampaign,
    deleteTollBalanceCampaign
} from '../../../../redux/actions/tollBalanceActions';
import {store} from "react-notifications-component";
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE} from '../variables';
import Alert from '../../../../shared/components/common/Alert';
import TollBalanceCampaignListDataTable from './DataTable';
import LoadingElement from "../../../../shared/components/common/Loading";

class TollBalanceCampaignList extends React.Component {
    constructor(props) {
        super(props);
        const {t} = props;

        this.state = {
            data: [],
            partnerName: props.match.params.partnerName,
            partnerId: props.match.params.partnerId,
            isEnabledFilterOptions: [
                {value: '', label: t('common->labelAll')},
                {value: true, label: t('common->labelYes')},
                {value: false, label: t('common->labelNo')}
            ],
            filterTypeOptions: [
                {value: '', label: t('common->labelAll')},
                {value: true, label: t('common->labelRegular')},
                {value: false, label: t('common->labelPunctual')},
            ],
            timePeriodFilterOptions: [
                {value: '', label: t('common->labelAlways')},
                {value: true, label: t('common->labelYes')},
                {value: false, label: t('common->labelNo')}
            ],
            daysHoursFilterOptions: [
                {value: 'monday', label: t('common->labelMonday')},
                {value: 'tuesday', label: t('common->labelTuesday')},
                {value: 'wednesday', label: t('common->labelWednesday')},
                {value: 'thursday', label: t('common->labelThursday')},
                {value: 'friday', label: t('common->labelFriday')},
                {value: 'saturday', label: t('common->labelSaturday')},
                {value: 'sunday', label: t('common->labelSunday')}
            ],
            showFinished: false,
            beginDate: null,
            endDate: null,
            dateTime: {},
            rowsPerPage: ROWS_PER_PAGE,
            pageSize: PAGE_SIZE,
            currentPage: CURRENT_PAGE,
        };
    }

    componentDidMount() {
        this.handleFilterClear();
    }

    componentDidUpdate(prevProps) {
        const {tollBalances, message, tollBalancesDone, error} = this.props;

        prevProps.tollBalances !== tollBalances && this.setState({data: tollBalances});
        prevProps.message !== message && message && prevProps.done !== tollBalancesDone && this.renderNotification('success');
        prevProps.error !== error && error && this.renderNotification('danger');
    }

    renderNotification(type) {
        const {t, error} = this.props;
        store.addNotification({
            title: t(`notification:${type}->title`),
            message: type === 'success' ? t(`notification:${type}->message`) : t(`notification:${type}->message`, {error}),
            type: type,
            insert: "bottom",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
                duration: 3000,
                showIcon: true
            }
        });
    }

    handlePageChange = (currentPage, pageSize) => {
        const {partnerId, showFinished, filtered, beginDate, endDate, dateTime} = this.state;
        const {filterName, filterIsPermanent, filterIsEnabled} = this.props;

        this.setState({currentPage, pageSize});
        let filters;
        filtered ? (filters = {
            name: filterName,
            isPermanent: filterIsPermanent.value,
            beginDate,
            endDate,
            dateTime,
            isEnabled: filterIsEnabled.value,
            showFinished
        }) : filters = {
            showFinished
        };
        this.props.fetchTollBalanceCampaignList(partnerId, currentPage, pageSize, filters);
    };

    handleToggle = (row) => {
        this.props.toggleTollBalanceCampaign(row);
    };

    handleModalDelete = (id) => {
        const {partnerId, partnerName, filtered, showFinished, beginDate, endDate, dateTime, pageSize, currentPage} = this.state;
        const {filterName, filterIsPermanent, filterIsEnabled} = this.props;
        let filters;
        
        filtered ? (filters = {
            name: filterName,
            isPermanent: filterIsPermanent.value,
            beginDate,
            endDate,
            dateTime,
            isEnabled: filterIsEnabled.value,
            showFinished
        }) : filters = {
            showFinished
        };
        
        this.props.deleteTollBalanceCampaign(id, 'list', partnerName, partnerId, currentPage, pageSize, filters);
    };

    handleFilterData = () => {
        const {showFinished, partnerId, pageSize} = this.state;
        const {filterName, filterIsPermanent, filterBeginDate, filterEndDate, filterIsEnabled, filterDateTime} = this.props;
        
        let dateTime = {}, newBeginDate, newEndDate;

        if (filterDateTime && filterDateTime.length) {
            filterDateTime.map(item =>
                dateTime[item.value] = true
            )
        }

        if (filterBeginDate) {
            newBeginDate = new Date(filterBeginDate);
            newBeginDate.setHours(0, 0, 0);
        }

        if (filterEndDate) {
            newEndDate = new Date(filterEndDate);
            newEndDate.setHours(23, 59, 59);
        }
        
        this.setState({
            currentPage: CURRENT_PAGE,
            filtered: true,
            beginDate: newBeginDate,
            endDate: newEndDate,
            dateTime
        });
        this.props.fetchTollBalanceCampaignList(partnerId, CURRENT_PAGE, pageSize, {
            name: filterName,
            isPermanent: filterIsPermanent.value,
            beginDate: newBeginDate,
            endDate: newEndDate,
            isEnabled: filterIsEnabled.value,
            dateTime,
            showFinished
        });
    }

    handleFilterClear = () => {
        const {partnerId, pageSize, filterTypeOptions, isEnabledFilterOptions, showFinished} = this.state;

        this.props.change('filter', {
            name: '',
            beginDate: null,
            endDate: null,
            dateTime: null,
            isPermanent: filterTypeOptions[0],
            isEnabled: isEnabledFilterOptions[0]
        })
        this.setState({
            currentPage: CURRENT_PAGE,
            filtered: false,
            beginDate: null,
            endDate: null,
            dateTime: {}
        });
        this.props.fetchTollBalanceCampaignList(partnerId, CURRENT_PAGE, pageSize, {showFinished});
    }

    handleShowFinished = e => {
        const {partnerId, currentPage, pageSize} = this.state;
        const {filterName, filterIsPermanent, filterBeginDate, filterEndDate, filterIsEnabled} = this.props;

        this.setState({showFinished: e.target.checked});
        this.props.fetchTollBalanceCampaignList(partnerId, currentPage, pageSize, {
            name: filterName,
            isPermanent: filterIsPermanent.value,
            beginDate: filterBeginDate,
            endDate: filterEndDate,
            isEnabled: filterIsEnabled.value,
            showFinished: e.target.checked
        });
    }

    renderTableHeader() {
        const {t, location} = this.props;
        const {partnerName} = this.state;
        
        return (
            <div className='card__title'>
                <h5 className='bold-text'>{t("tollBalanceCampaignList->tableTitle")} - {partnerName}</h5>
                <ButtonToolbar className='products-list__btn-toolbar-top'>
                    <Link className='btn btn-primary products-list__btn-add' to={`${location.pathname}/create`}>
                        {t("tollBalanceCampaignList->createNew")}
                    </Link>
                </ButtonToolbar>
            </div>
        );
    }

    renderTable() {
        const {data, rowsPerPage, pageSize, currentPage, showFinished, isEnabledFilterOptions, filterTypeOptions, daysHoursFilterOptions, beginDate, endDate} = this.state;
        const {totalItems, theme} = this.props;


        return (
            <>
                {this.renderTableHeader()}
                <TollBalanceCampaignListDataTable
                    theme={theme}
                    data={data}
                    noHeader
                    paginationServer
                    paginationTotalRows={totalItems}
                    handlePerRowsChange={this.handlePerRowsChange}
                    handlePageChange={this.handlePageChange}
                    rowsPerPage={rowsPerPage}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    withFilters
                    paginationDefaultPage={currentPage}
                    handleToggle={this.handleToggle}
                    handleFilterData={this.handleFilterData}
                    handleFilterClear={this.handleFilterClear}
                    handleShowFinished={this.handleShowFinished}
                    handleModalDelete={this.handleModalDelete}
                    isEnabledFilterOptions={isEnabledFilterOptions}
                    filterTypeOptions={filterTypeOptions}
                    daysHoursFilterOptions={daysHoursFilterOptions}
                    location={this.props.location}
                    showFinished={showFinished}
                    beginDate={beginDate}
                    endDate={endDate}
                />
            </>
        )
    }

    renderError(error) {
        const {t} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color="danger" className="alert--bordered" icon>
                            <p className="bold-text">{t('alert:danger->title')}</p>
                            <p className="mb-2">{t('alert:danger->message', {error})}</p>
                            <Button
                                size="sm"
                                type="button"
                                className="mb-0"
                                onClick={() => this.handleFilterData()}
                            >
                                {t('alert:danger->tryAgain')}
                            </Button>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    }

    render() {
        const {t, tollBalancesDone, tollBalanceError} = this.props;

        if (!tollBalancesDone) {
            return <LoadingElement isLoading={true}/>;
        }

        if (tollBalanceError) {
            return this.renderError(tollBalanceError);
        }

        return (
            <Container>
                <Row>
                    <Col>
                        <h3 className="page-title mb-4">{t('tollBalanceCampaignList->pageTitle')}</h3>
                        <Card>
                            <CardBody>
                                {this.renderTable()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const selector = formValueSelector('tollBalanceForm');

const mapStateToProps = (state) => {
    return {
        tollBalances: Object.values(state.tollBalances.data.tollBalances),
        totalItems: state.tollBalances.data.totalItems,
        tollBalancesDone: state.tollBalances.done.status,
        message: state.tollBalances.done.message,
        tollBalanceError: state.tollBalances.error,
        theme: state.theme.className,
        filterName: selector(state, 'filter.name'),
        filterIsPermanent: selector(state, 'filter.isPermanent'),
        filterIsEnabled: selector(state, 'filter.isEnabled'),
        filterBeginDate: selector(state, 'filter.beginDate'),
        filterEndDate: selector(state, 'filter.endDate'),
        filterDateTime: selector(state, 'filter.dateTime')
    };
};

const translationWrapped = withTranslation(['tollBalance', 'common', 'modal', 'notification'])(TollBalanceCampaignList);

const formWrapped = reduxForm({
    form: 'tollBalanceForm',
})(translationWrapped);

export default connect(mapStateToProps, {
    fetchTollBalanceCampaignList,
    toggleTollBalanceCampaign,
    deleteTollBalanceCampaign
})(formWrapped);