import {
    SIGN_IN_AUTH,
    SIGN_OUT_AUTH,
    DONE_AUTH,
    ERROR_AUTH,
    PENDING_AUTH,
    VERIFY_TOKEN_AUTH
} from '../actions/authActions';

const initialState = {
    done: {
        status: false,
        message: null
    },
    data: {
        isAuthenticated: true,
        user: {}
    },
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN_AUTH:
            return {
                ...state,
                data: {isAuthenticated: true, user: {name: action.payload.username}}
            };
        case SIGN_OUT_AUTH:
            return {...state, data: {auth: {isAuthenticated: false, user: {}}}};
        case DONE_AUTH:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case PENDING_AUTH:
            return {...state, done: {status: false, message: null}, error: null};
        case VERIFY_TOKEN_AUTH:
            return {
                ...state, 
                data: {isAuthenticated: action.payload.isAuthenticated, user: {name: action.payload.username}}}
        case ERROR_AUTH:
            return {...state, done: {status: true, message: null}, error: action.payload};
        default:
            return state;
    }
}