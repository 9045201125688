import React from "react";
import {connect} from "react-redux";
import axios from "axios";
import {fetchAllPointsOfSale} from '../../../redux/actions/posActions';
import {fetchProducts} from '../../../redux/actions/productActions';
import {
    fetchVoucherCampaign,
    createVoucherCampaign,
    editVoucherCampaign,
    deleteVoucherCampaign
} from '../../../redux/actions/voucherActions';
import {store} from "react-notifications-component";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Button, Card, CardBody, Container} from "reactstrap";
import {cloneDeep, remove} from "lodash";
import Alert from "../../../shared/components/common/Alert";
import VoucherForm from "./VoucherForm/";
import LoadingElement from "../../../shared/components/common/Loading";

class VoucherEdit extends React.Component {
    state = {
        offerTypes: {
            data: [],
            done: false
        },
        benefitTypes: {
            data: [],
            done: false
        },
        tiers: {
            data: [],
            done: false
        },
        voucherCodeFormats: {
            data: [],
            done: false
        },
        barcodeFormats: {
            data: [],
            done: false
        }
    };

    async componentDidMount() {
        const {partnerId, id, copyId} = this.props.match.params;
        this.props.fetchVoucherCampaign(copyId ? copyId : id, 'edit')
        this.props.fetchAllPointsOfSale(partnerId);
        this.props.fetchProducts(partnerId, 1, 10000);
        await this.fetchTiers();
        await this.fetchCampaignBenefitType();
        await this.fetchCampaignOfferType();
        await this.fetchVoucherCodeFormat();
        await this.fetchBarcodeFormat();
    }

    async fetchCampaignBenefitType() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetCampaignBenefitType', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({benefitTypes: {data: response.data, done: true}});
            }
        } catch (e) {
            this.renderError(e);
        }
    };

    async fetchCampaignOfferType() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetCampaignOfferType', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({offerTypes: {data: response.data, done: true}});
            }
        } catch (e) {
            this.renderError(e);
        }
    };

    async fetchTiers() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetTiers', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({tiers: {data: response.data, done: true}});
            }
        } catch (e) {
            this.renderError();
        }
    };

    async fetchVoucherCodeFormat() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetVoucherCodeFormat', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({voucherCodeFormats: {data: response.data, done: true}});
            }
        } catch (e) {
            this.renderError();
        }
    };

    async fetchBarcodeFormat() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetBarCodeFormat', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({barcodeFormats: {data: response.data, done: true}});
            }
        } catch (e) {
            this.renderError();
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message && this.props.message) {
            store.addNotification({
                title: this.props.t("notification:success->title"),
                message: this.props.t("notification:success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                },
            });
        }
    }

    onSubmit = formValues => {
        const {copyId} = this.props.match.params;

        copyId ? this.props.createVoucherCampaign(formValues, '/copy') : this.props.editVoucherCampaign(formValues);
    };

    onDelete = () => {
        const {id, partnerName, partnerId} = this.props.match.params;
        this.props.deleteVoucherCampaign(id, "form", partnerName, partnerId);
    };

    normalizePOSData() {
        const {voucher, POS} = this.props;
        let POSSelected = cloneDeep(voucher.voucherCampaignPointOfSale);
        let POSCopy = cloneDeep(POS);

        POS.map(pos => POSSelected.map(selected => {
            if (parseInt(pos.key) === selected.pointOfSaleId) {
                selected.key = pos.key;
                selected.value = pos.value;
                remove(POSCopy, pos);
            }
            return selected;
        }));

        return {POSSelected, POSCopy};
    }

    normalizeProductsData() {
        const {voucher, products} = this.props;
        let productsSelected = cloneDeep(voucher.voucherCampaignProduct);

        products.map(product => productsSelected.map(productSelected => 
            product.id === productSelected.productId && (productSelected.name = product.name)
        ));

        return productsSelected;
    }

    normalizeVoucherData() {
        const {t, voucher} = this.props;
        const {benefitTypes, voucherCodeFormats, barcodeFormats} = this.state;
        let voucherCopy = cloneDeep(voucher);

        benefitTypes.data.map(benefitType => benefitType.id === voucherCopy.voucherCampaignsBenefits.campaignBenefitType && (voucherCopy.voucherCampaignsBenefits.campaignBenefitType = {
            label: t(`common->labelBenefitType${benefitType.name}`),
            value: voucherCopy.voucherCampaignsBenefits.campaignBenefitType,
        }));

        voucherCodeFormats.data.map(voucherCodeFormat => {
            voucherCopy.voucherCampaignLayoutTranslation.map(translation => translation.codeFormat === voucherCodeFormat.id && (translation.codeFormat = {
                label: t(`voucherForm->labelVoucherCodeFormat${voucherCodeFormat.name}`),
                value: translation.codeFormat,
            }));
            return voucherCodeFormat;
        });

        barcodeFormats.data.map(barcodeFormat => {
            voucherCopy.voucherCampaignLayoutTranslation.map(translation => translation.barCodeType === barcodeFormat.id && (translation.barCodeType = {
                label: barcodeFormat.name,
                value: translation.barCodeType,
            }));
            return barcodeFormat;
        });

        return voucherCopy;
    }

    renderError(error) {
        const {t} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color="danger" className="alert--bordered alert-period" icon>
                            <p className="bold-text">{t('alert:danger->title')}</p>
                            <p>{t('alert:danger->message', {error})}</p>
                            <Button size="sm" type="button" className="mb-0"
                                    onClick={() => this.componentDidMount()}>{t('alert:danger->tryAgain')}</Button>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    }

    render() {
        const {t, voucherDone, voucherError, POSDone, POSError, products, productsDone, productsError} = this.props;
        const {copyId} = this.props.match.params;

        const {benefitTypes, offerTypes, tiers, voucherCodeFormats, barcodeFormats} = this.state;

        if (!voucherDone || !POSDone || !productsDone || !benefitTypes.done || !offerTypes.done || !tiers.done || !voucherCodeFormats.done || !barcodeFormats.done) {
            return <LoadingElement isLoading={true}/>;
        }

        if (productsError || POSError || voucherError) {
            return this.renderError(productsError || POSError || voucherError);
        }

        const normalizedPOS = this.normalizePOSData();
        const normalizedProductsSelected = this.normalizeProductsData();
        let normalizedVoucher = this.normalizeVoucherData();

        //normalizedVoucher.campaignProducts = normalizedProductsSelected.productsSelected;

        return (
            <VoucherForm
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                partnerName={this.props.match.params.partnerName}
                partnerId={this.props.match.params.partnerId}
                btnText={copyId ? t('voucherForm->btnCreate') : t('voucherForm->btnSave')}
                POS={normalizedPOS.POSCopy}
                POSSelected={normalizedPOS.POSSelected}
                products={products}
                productsSelected={normalizedProductsSelected}
                symbol={normalizedProductsSelected.symbol}
                tiers={tiers.data}
                benefitTypes={benefitTypes.data}
                offerTypes={offerTypes.data}
                voucherCodeFormats={voucherCodeFormats.data}
                barcodeFormats={barcodeFormats.data}
                initialValues={normalizedVoucher}
                type={copyId ? 'copy' : 'edit'}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        voucher: state.vouchers.data.vouchers[ownProps.match.params.copyId ? ownProps.match.params.copyId : ownProps.match.params.id],
        voucherDone: state.vouchers.done.status,
        voucherError: state.vouchers.error,
        POS: Object.values(state.pointsOfSale.data.pointsOfSale),
        POSDone: state.pointsOfSale.done.status,
        POSError: state.pointsOfSale.error,
        products: Object.values(state.products.data.products),
        productsDone: state.products.done.status,
        productsError: state.products.error,
        message: state.vouchers.done.message
    };
};

export default compose(
    withTranslation(["voucher", "notification"]),
    connect(mapStateToProps, {
        fetchAllPointsOfSale,
        fetchProducts,
        fetchVoucherCampaign,
        createVoucherCampaign,
        editVoucherCampaign,
        deleteVoucherCampaign
    })
)(VoucherEdit);
