import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, formValueSelector, getFormSyncErrors} from 'redux-form';
import {Link} from 'react-router-dom';
import classnames from 'classnames';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    ButtonToolbar,
    Button, ButtonGroup, NavItem, NavLink, Nav, TabContent, TabPane, Table
} from 'reactstrap';
import {
    required,
    number,
    maxLength50,
    minValue0,
    requiredOneOfField,
    requiredOneItem,
    greaterDate,
} from '../../../../shared/validations';
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE} from '../variables';
import {withTranslation} from 'react-i18next';
import InputElement from '../../../../shared/components/form/Input';
import CheckBoxElement from '../../../../shared/components/form/CheckBox';
import ModalConfirmation from '../../../../shared/components/common/ModalConfirmation';
import RadioButtonElement from '../../../../shared/components/form/RadioButton';
import DateTimePickerElement from '../../../../shared/components/form/DateTimePicker';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from "mdi-react/MinusIcon";
import PencilIcon from 'mdi-react/PencilIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import DataTableElement from '../../../../shared/components/common/DataTable';
import {numberMask} from '../../../variables';
import SelectElement from '../../../../shared/components/form/Select';
import HiddenElement from '../../../../shared/components/form/Hidden';
import ModalElement from '../../../../shared/components/common/Modal';
import TollBalanceAddProductForm from './TollBalanceAddProductForm';
import LoadingElement from "../../../../shared/components/common/Loading";
import Alert from "../../../../shared/components/common/Alert";
import {cloneDeep} from "lodash";

class TollBalanceForm extends React.Component {

    state = {
        isLoading: false,
        rowsPerPage: ROWS_PER_PAGE,
        pageSize: PAGE_SIZE,
        currentPage: CURRENT_PAGE,
        activeTab: 0,
        tabs: [
            this.props.t('tollBalanceForm->tabTimePeriod'),
            this.props.t('tollBalanceForm->tabPOS'),
            this.props.t('tollBalanceForm->tabRules'),
        ],
        isEditable: true,
        isFinished: false,
        days: {},
        compareDate: {},
        partnerName: this.props.partnerName,
        partnerId: this.props.partnerId,
        stockUnitTypesOptions: this.props.stockUnitTypes.map(unit => (
            {
                label: unit.id === 1 ? '€' : '%',
                value: unit.id,
            }
        )),
        availablePOS: this.props.POS,
        selectedPOS: this.props.POSSelected || [],
        filterAvailablePOSText: '',
        filterSelectedPOSText: '',
        availableProducts: [],
        selectedProducts: this.props.productsSelected || [],
        selectedProductsCount: (this.props.productsSelected && this.props.productsSelected.length) || 0,
        symbol: this.props.symbol || [],
        tiers: this.props.tiers,
        stockUnitTypes: this.props.stockUnitTypes,
        selectedRows: [],
        toggleCleared: false,
        setRules: {},
        isPermanent: false,
    };
    componentDidMount() {
        const {initialValues, type} = this.props;
        (type === 'edit' || type === 'copy') && this.checkSelectedProducts();
        if (type === 'edit') {
            new Date(initialValues.beginDate).getTime() >= new Date().getTime() ? this.setState({isEditable: true}) : this.setState({isEditable: false});
            initialValues.endDate ? new Date().getTime() >= new Date(initialValues.endDate).getTime() ? this.setState({isFinished: true}) : this.setState({isFinished: false}) : this.setState({isFinished: false});
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state;
    }

    toggleTab = (tab) => {
        const {activeTab} = this.state;
        activeTab !== tab && this.setState({activeTab: tab});
    };

    checkErrorInTabs() {
        const {formSyncErrors} = this.props;

        if (formSyncErrors.selectedProductsCheck || formSyncErrors.campaignProducts) {
            this.setState({activeTab: 2});
        } else {
            this.setState({activeTab: 0});
        }
    }

    onSubmit = formValues => {
        this.setState({ isLoading: true });
        const { partnerId, selectedPOS, isPermanent } = this.state;
        const {type} = this.props;

        let formToPost = {
            ...formValues,
            campaignProducts: formValues.campaignProducts.filter(item => !item.invalid),
            partnerId: partnerId,
            campaignPointsOfSale: [],
        }

        formToPost.minCampaignValue = formToPost.minCampaignValue ? parseFloat(formToPost.minCampaignValue) : null

        formToPost.campaignPointsOfSale = cloneDeep(selectedPOS);
        formToPost.campaignPointsOfSale.map(pos => {
            pos.pointOfSaleId = parseInt(pos.key);
            if (type === 'copy') {
                delete pos.id;
                delete pos.campaignId;
            }
            return pos;
        });

        formToPost.campaignProducts.map(campaignProduct => {
            campaignProduct.stockUnitType = campaignProduct.stockUnitType.value;
            if (type === 'copy') {
                delete campaignProduct.id;
                delete campaignProduct.campaignId;
            }
            if (isPermanent) {
                campaignProduct.initialStock = null;
                campaignProduct.currentStock = null;
            }
            return campaignProduct;
        });

        for (let i = 0; i < formToPost.campaignProducts.length; i++) {
            for (let j = 0; j < formToPost.campaignProducts[i].campaignProductValues.length; j++) {
                formToPost.campaignProducts[i].campaignProductValues[j].value = parseFloat(formToPost.campaignProducts[i].campaignProductValues[j].value);
                j >= 1 ? formToPost.campaignProducts[i].campaignProductValues[j].fee = parseFloat(formToPost.campaignProducts[i].campaignProductValues[0].fee) : formToPost.campaignProducts[i].campaignProductValues[j].fee = parseFloat(formToPost.campaignProducts[i].campaignProductValues[0].fee);
                if (type === 'copy') {
                    delete formToPost.campaignProducts[i].campaignProductValues[j].id;
                    delete formToPost.campaignProducts[i].campaignProductValues[j].campaignProductId;
                    delete formToPost.campaignProducts[i].product;
                    delete formToPost.campaignProducts[i].internalId;
                }
            }
        }

        type === 'copy' && delete formToPost.id;
        this.props.onSubmit(formToPost);
    };

    handleModalDelete = () => {
        this.props.onDelete();
    };

    renderModalBody = () => {
        const {t, theme} = this.props;
        const {stockUnitTypesOptions, selectedRows} = this.state;

        return (
            <div className={theme}>
                <p className="d-flex mb-2 bold-text">{t('tollBalanceForm->labelNewRule')}</p>
                <Table className="form d-block table--bordered">
                    <thead>
                    <tr>
                        <th>{t('tollBalanceForm->tableColumnStockType')}</th>
                        <th>{t('tollBalanceForm->tableColumnSilver')}</th>
                        <th>{t('tollBalanceForm->tableColumnGold')}</th>
                        <th>{t('tollBalanceForm->tableColumnInitialStock')}</th>
                        <th>{t('tollBalanceForm->tableColumnFee')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <Field
                                name="modalForm.newStockUnitType"
                                id="modalForm.newStockUnitType"
                                component={SelectElement}
                                options={stockUnitTypesOptions}
                                defaultValue={stockUnitTypesOptions[0]}
                            />
                        </td>
                        <td>
                            <Field
                                name="modalForm.newSilverTier"
                                id="modalForm.newSilverTier"
                                component={InputElement}
                                numeric
                            />
                        </td>
                        <td>
                            <Field
                                name="modalForm.newGoldTier"
                                id="modalForm.newGoldTier"
                                component={InputElement}
                                numeric
                            />
                        </td>
                        <td>
                            <Field
                                name="modalForm.newInitialStock"
                                id="modalForm.newInitialStock"
                                component={InputElement}
                                parse={value => Number(value)}
                                numeric
                            />
                        </td>
                        <td>
                            <Field
                                name="modalForm.newFee"
                                id="modalForm.newFee"
                                component={InputElement}
                                mask={numberMask('', ' %')}
                            />
                        </td>
                    </tr>
                    </tbody>
                </Table>

                {selectedRows.length === 1
                    ? <p>{t('tollBalanceForm->modalWarningMessageSingle')}</p>
                    : <p>{t('tollBalanceForm->modalWarningMessageMulti', {count: selectedRows.length})}</p>
                }
            </div>
        );
    }

    contextActions = ((editModalHandler, deleteHandler) => {
        const {t, theme} = this.props;
        return (
            <ButtonToolbar>
                <ButtonGroup className="btn-group--icons" dir="ltr">
                    <ModalElement
                        backdrop="static"
                        color="primary"
                        outline
                        theme={theme}
                        cancelBtnText={t('modal:buttonCancel')}
                        btnOpenModal={<PencilIcon/>}
                        btnTitle={t('common:buttonTitleAttributes->edit')}
                        title={t('tollBalanceForm->modalTitleChangeRules')}
                        body={this.renderModalBody()}
                        actionBtnText={t('tollBalanceForm->modalBtnChange')}
                        handleOnClick={editModalHandler}
                    />
                    <ModalConfirmation
                        backdrop="static"
                        color="danger"
                        firstBtnText={t('modal:buttonNo')}
                        secondBtnText={t('modal:buttonYes')}
                        title={t('modal:modalDeleteTitle')}
                        btnTitle={t('common:buttonTitleAttributes->delete')}
                        btn={<TrashCanOutlineIcon/>}
                        message={<p>{t('modal:modalDeleteAllMessage')}</p>}
                        handleOnClick={deleteHandler}
                        closeOnAction
                    />
                </ButtonGroup>
            </ButtonToolbar>
        )
    });

    checkSelectedProducts = () => {
        const {change} = this.props;
        const {selectedProducts} = this.state;
        selectedProducts.length > 0 ? change('selectedProductsCheck', 'true') : change('selectedProductsCheck', null);
    };

    addPOS = key => {
        const {availablePOS, selectedPOS} = this.state;
        const toAddPOS = availablePOS.find(pos => pos.key === key);

        this.setState(state => ({
            selectedPOS: [...selectedPOS, toAddPOS],
            availablePOS: state.availablePOS.filter(pos => pos.key !== key)
        }));
    };

    addAllPOS = () => {
        this.setState(state => ({
            selectedPOS: [...state.selectedPOS, ...state.availablePOS],
            availablePOS: []
        }));
    };

    removePOS = key => {
        const {selectedPOS} = this.state;
        const toRemovePOS = selectedPOS.find(pos => pos.key === key);
        this.setState(state => ({
            selectedPOS: state.selectedPOS.filter(pos => pos.key !== key),
            availablePOS: [...state.availablePOS, toRemovePOS],
        }));
    };

    removeAllPOS = () => {
        this.setState(state => ({
            selectedPOS: [],
            availablePOS: [...state.availablePOS, ...state.selectedPOS]
        }));
    };

    addProduct = () => {
        const {productSearch, change, newProdStockUnitType, newProdSilverTier, newProdGoldTier, newProdInitialStock, newProdFee} = this.props;
        const {stockUnitTypesOptions} = this.state;

        if (productSearch) {
            const {availableProducts, selectedProductsCount, symbol} = this.state;
            const addProduct = {
                id: parseInt(productSearch.value),
                productId: parseInt(productSearch.value),
                product: {
                    id: parseInt(productSearch.value),
                    name: productSearch.label
                },
                internalId: selectedProductsCount,
                invalid: false
            };

            const index = availableProducts.findIndex(product => product.id === productSearch.value);
            let copyProducts = [...availableProducts], copySymbol = [...symbol];
            copyProducts[index].isDisabled = false;
            copySymbol[selectedProductsCount] = newProdStockUnitType ? newProdStockUnitType.label : stockUnitTypesOptions[0].label;

            this.setState(state => ({
                selectedProducts: [...state.selectedProducts, addProduct],
                availableProducts: copyProducts,
                selectedProductsCount: state.selectedProductsCount + 1,
                symbol: copySymbol
            }), () => this.checkSelectedProducts());
            change('newProd', {
                productSearch: '',
                stockUnitType: stockUnitTypesOptions[0],
                silverTier: '',
                initialStock: '',
                fee: ''
            });
            change(`campaignProducts[${selectedProductsCount}]`, {
                stockUnitType: newProdStockUnitType ? newProdStockUnitType : stockUnitTypesOptions[0],
                initialStock: newProdInitialStock ? parseInt(newProdInitialStock) : null,
            });
            change(`campaignProducts[${selectedProductsCount}].campaignProductValues[0]`, {
                tierId: 1,
                value: newProdSilverTier,
                fee: newProdFee
            });
            change(`campaignProducts[${selectedProductsCount}].campaignProductValues[1]`, {
                tierId: 2,
                value: newProdGoldTier,
                fee: newProdFee
            });
        }
    };

    removeProduct(row) {
        this.props.change(`campaignProducts[${row.internalId}].invalid`, true);
        this.setState(state => ({
            selectedProducts: state.selectedProducts.filter(product => product.id !== parseInt(row.id))
        }), () => this.checkSelectedProducts());

    }

    handleSelectedRowsChange = state => {
        this.setState({selectedRows: state.selectedRows});
    };

    handleRowsDeleteAll = () => {
        const {selectedRows} = this.state;
        this.setState(state => ({toggleCleared: !state.toggleCleared}));
        selectedRows.map(row => this.removeProduct(row));
    };

    editRulesModal = () => {
        const {selectedRows, symbol} = this.state;
        const {modalForm, change} = this.props;
        let newRules = [...selectedRows];
        let newSymbols = [...symbol];

        newRules.map(newRule => {
            change(`campaignProducts[${newRule.internalId}].stockUnitType`, modalForm.newStockUnitType ? modalForm.newStockUnitType : this.state.stockUnitTypesOptions[0]);
            change(`campaignProducts[${newRule.internalId}].initialStock`, modalForm.newInitialStock);
            change(`campaignProducts[${newRule.internalId}].campaignProductValues[0].value`, modalForm.newSilverTier);
            change(`campaignProducts[${newRule.internalId}].campaignProductValues[1].value`, modalForm.newGoldTier);
            change(`campaignProducts[${newRule.internalId}].campaignProductValues[0].fee`, modalForm.newFee);
            change(`campaignProducts[${newRule.internalId}].campaignProductValues[1].fee`, modalForm.newFee);
            newSymbols[newRule.internalId] = modalForm.newStockUnitType ? modalForm.newStockUnitType.label : this.state.stockUnitTypesOptions[0].label;
            return newRule;
        });
        this.setState({symbol: newSymbols});
    };

    renderTabHeader() {
        const {activeTab, tabs} = this.state;

        return (
            tabs.map((tab, index) => {
                return (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({active: activeTab === index})}
                            onClick={() => this.toggleTab(index)}
                        >
                            {tab}
                        </NavLink>
                    </NavItem>
                )
            })
        );
    }

    renderColGeneral() {
        const { t, initialValues } = this.props;
        const { isEditable, isFinished } = this.state;
        
        return (
            <Card>
                <CardBody>
                    <div className="card__title">
                        <h5 className="bold-text">{t('tollBalanceForm->formTitleGeneral')}</h5>
                    </div>
                    <Field
                        name="isEnabled"
                        id="isEnabled"
                        component={CheckBoxElement}
                        defaultChecked={initialValues.isEnabled}
                        label={t('tollBalanceForm->labelEnabled')}
                        className="colored-click"
                        disabled={isFinished}
                    />
                    <Field
                        name="name"
                        id="name"
                        component={InputElement}
                        label={t('tollBalanceForm->labelName')}
                        validate={[required, maxLength50]}
                        disabled={!isEditable}
                    />
                    <div className="form__label-margin">
                        <p>{t('tollBalanceForm->labelCampaignType')}</p>
                    </div>
                    <Field
                        name="isPermanent"
                        id="regular"
                        radioValue={true}
                        component={RadioButtonElement}
                        label={t('common->labelRegular')}
                        defaultChecked={initialValues.isPermanent}
                        className="colored-click"
                        onChange={() => this.setState({isPermanent: true})}
                        disabled={!isEditable}
                    />
                    <Field
                        name="isPermanent"
                        id="punctual"
                        radioValue={false}
                        component={RadioButtonElement}
                        label={t('common->labelPunctual')}
                        defaultChecked={!initialValues.isPermanent}
                        className="colored-click"
                        onChange={(e) => this.setState({isPermanent: false})}
                        disabled={!isEditable}
                    />

                    <Alert color="info" className="alert--neutral alert-period" icon>
                        <p>
                            {t('tollBalanceForm->labelGeneralInfo')}
                        </p>
                    </Alert>

                    <Field
                        name="minCampaignValue"
                        id="minCampaignValue"
                        component={InputElement}
                        label={t('tollBalanceForm->labelCampaignValue')}
                        validate={[minValue0]}
                        numeric
                        //disabled={!isEditable}
                    />
                </CardBody>
            </Card>
        );
    }

    renderTabTimePeriod() {
        const {t, initialValues} = this.props;
        const {isEditable, days: {monday, tuesday, wednesday, thursday, friday, saturday, sunday}, compareDate: {beginDate, endDate}} = this.state;

        return (
            <>
                <Row>
                    <Col sm={6}>
                        <Alert color="info" className="alert--neutral alert-period" icon>
                            <p>
                                {t('tollBalanceForm->labelTabPeriod')}
                            </p>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Field
                            name="beginDate"
                            id="beginDate"
                            component={DateTimePickerElement}
                            label={t('tollBalanceForm->labelBeginDate')}
                            showTimeSelect
                            dateFormat="dd/MM/yyyy, HH:mm"
                            startDate={new Date(initialValues.beginDate)}
                            validate={required}
                            onChange={e => this.setState(state => ({
                                compareDate: {
                                    ...state.compareDate,
                                    beginDate: e
                                }
                            }))}
                            minDate={new Date()}
                            disabled={!isEditable}
                        />
                    </Col>
                    <Col sm={6}>
                        <Field
                            name="endDate"
                            id="endDate"
                            component={DateTimePickerElement}
                            showTimeSelect
                            label={t('tollBalanceForm->labelEndDate')}
                            dateFormat="dd/MM/yyyy, HH:mm"
                            startDate={initialValues.endDate ? (new Date(initialValues.endDate)) : null}
                            onChange={e => this.setState(state => ({compareDate: {...state.compareDate, endDate: e}}))}
                            minDate={new Date()}
                            disabled={!isEditable}
                        />
                        <Field
                            name="dateCheck"
                            id="dateCheck"
                            component={HiddenElement}
                            hiddenValue=""
                            validate={endDate && (new Date(beginDate).getTime() >= new Date(endDate).getTime()) ? [greaterDate] : []}
                        />
                    </Col>
                </Row>
                <div className='form__form-group'>
                    <span className='form__form-group-label'>{t("tollBalanceForm->labelDays")}</span>
                    <div className='form__form-group-field'>
                        <Field
                            id="monday"
                            name="monday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.monday}
                            label={t('common->labelMonday')}
                            className="colored-click"
                            validate={
                                monday ||
                                tuesday ||
                                wednesday ||
                                thursday ||
                                friday ||
                                saturday ||
                                sunday ? [] : [requiredOneOfField]
                            }
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, monday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="tuesday"
                            name="tuesday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.tuesday}
                            label={t('common->labelTuesday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, tuesday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="wednesday"
                            name="wednesday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.wednesday}
                            label={t('common->labelWednesday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, wednesday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="thursday"
                            name="thursday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.thursday}
                            label={t('common->labelThursday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, thursday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="friday"
                            name="friday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.friday}
                            label={t('common->labelFriday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, friday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="saturday"
                            name="saturday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.saturday}
                            label={t('common->labelSaturday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, saturday: value}}))}
                            disabled={!isEditable}
                        />
                        <Field
                            id="sunday"
                            name="sunday"
                            component={CheckBoxElement}
                            defaultChecked={initialValues.sunday}
                            label={t('common->labelSunday')}
                            className="colored-click"
                            onChange={(e, value) => this.setState(state => ({days: {...state.days, sunday: value}}))}
                            disabled={!isEditable}
                        />
                    </div>
                </div>
                <Row>
                    <Col sm={6}>
                        <Field
                            name="beginTime"
                            id="beginTime"
                            component={DateTimePickerElement}
                            showTimeSelect
                            label={t('tollBalanceForm->labelBeginTime')}
                            showTimeSelectOnly
                            dateFormat="HH:mm"
                            startDate={initialValues.beginTime ? new Date(initialValues.beginTime) : new Date(2020, 0, 1, 0, 0)}
                            validate={required}
                            disabled={!isEditable}
                        />
                    </Col>
                    <Col sm={6}>
                        <Field
                            name="endTime"
                            id="endTime"
                            component={DateTimePickerElement}
                            showTimeSelect
                            label={t('tollBalanceForm->labelEndTime')}
                            showTimeSelectOnly
                            dateFormat="HH:mm"
                            startDate={initialValues.endTime ? new Date(initialValues.endTime) : new Date(2020, 0, 1, 23, 59)}
                            validate={required}
                            disabled={!isEditable}
                        />
                    </Col>
                </Row>
            </>
        )
    }

    renderTabPOS() {
        const {t} = this.props;
        const {isEditable, availablePOS, filterAvailablePOSText, selectedPOS, filterSelectedPOSText} = this.state;
        const filteredAvailablePOS = availablePOS.filter(pos => pos.value.toLowerCase().includes(filterAvailablePOSText));
        const filteredSelectedPOS = selectedPOS.filter(pos => pos.value.toLowerCase().includes(filterSelectedPOSText));

        return (
            <>
                <Row>
                    <Col sm={6}>
                        <Alert color="info" className="alert--neutral alert-period" icon>
                            <p>
                                {t('tollBalanceForm->labelTabPOS')}
                            </p>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <span>{t('tollBalanceForm->labelAvailable') + ` (${availablePOS.length})`}</span>
                            {
                                isEditable &&
                                <button className="panel__btn" type="button"
                                        onClick={this.addAllPOS}>{t('tollBalanceForm->labelSelectAll')}</button>  
                            }
                        </div>
                        <div className="form">
                            <div className="form__form-group products-list__search">
                                <label htmlFor="search" className="sr-only">
                                    {t('tollBalanceForm->labelSearchTextPlaceholder')}
                                </label>
                                <input
                                    type="text"
                                    id="filterAvailablePOSText"
                                    name="filterAvailablePOSText"
                                    value={this.state.filterAvailablePOSText}
                                    onChange={e => this.setState({filterAvailablePOSText: e.target.value})}
                                    placeholder={t('tollBalanceForm->labelSearchTextPlaceholder')}
                                    autoComplete="off"
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <ul className="list">
                            {filteredAvailablePOS.map(pos =>
                                <li className="list__item" key={pos.id || pos.key}>
                                    <span>{pos.value}</span>
                                    {
                                        isEditable &&
                                        <button className="panel__btn" type="button"
                                                onClick={() => this.addPOS(pos.key)}><PlusIcon
                                            className="list__item-icon"/></button>    
                                    }
                                </li>
                            )}
                        </ul>
                    </Col>
                    <Col sm={6}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <span>{t('tollBalanceForm->labelSelected') + ` (${selectedPOS.length})`}</span>
                            {
                                isEditable &&
                                <button className="panel__btn" type="button"
                                        onClick={this.removeAllPOS}>{t('tollBalanceForm->labelCleanSelection')}</button>    
                            }
                            
                        </div>
                        <div className="form">
                            <div className="form__form-group products-list__search">
                                <label htmlFor="search" className="sr-only">
                                    {t('tollBalanceForm->labelSearchTextPlaceholder')}
                                </label>
                                <input
                                    type="text"
                                    id="filterSelectedPOSText"
                                    name="filterSelectedPOSText"
                                    value={this.state.filterSelectedPOSText}
                                    onChange={e => this.setState({filterSelectedPOSText: e.target.value})}
                                    placeholder={t('tollBalanceForm->labelSearchTextPlaceholder')}
                                    autoComplete="off"
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <ul className="list">
                            {filteredSelectedPOS.map(pos =>
                                <li className="list__item" key={pos.id || pos.key}>
                                    <span>{pos.value}</span>
                                    {
                                        isEditable &&
                                        <button className="panel__btn" type="button"
                                                onClick={() => this.removePOS(pos.key)}><MinusIcon
                                            className="list__item-icon"/></button>    
                                    }
                                </li>
                            )}
                        </ul>
                    </Col>
                </Row>
            </>
        )
    }

    renderTableColumns() {
        const {t} = this.props;
        const {isEditable, symbol, stockUnitTypesOptions, isPermanent} = this.state;

        return [
            {
                name: t('tollBalanceForm->tableColumnProduct'),
                selector: 'product.name',
                wrap: true,
                cell: row => (
                    <>
                        {row.product.name}
                        <Field
                            name={`campaignProducts[${row.internalId}].productId`}
                            id={`campaignProducts[${row.internalId}].productId`}
                            component={HiddenElement}
                            hiddenValue={row.productId}
                        />
                    </>
                )
            },
            {
                name: t('tollBalanceForm->tableColumnStockType'),
                selector: 'stockUnitType',
                cell: row => (
                    <>
                        <Field
                            name={`campaignProducts[${row.internalId}].stockUnitType`}
                            id={`campaignProducts[${row.internalId}].stockUnitType`}
                            component={SelectElement}
                            options={stockUnitTypesOptions}
                            defaultValue={stockUnitTypesOptions[0]}
                            onChange={(e) => {
                                let symbol = [...this.state.symbol];
                                symbol[row.internalId] = e.label;
                                this.setState({symbol})
                            }}
                            disabled={!isEditable}
                        />
                    </>
                )
            },
            {
                name: t('tollBalanceForm->tableColumnSilver'),
                selector: 'silverTier',
                cell: row => (
                    <Field
                        id={`campaignProducts[${row.internalId}].campaignProductValues[0].value`}
                        name={`campaignProducts[${row.internalId}].campaignProductValues[0].value`}
                        component={InputElement}
                        mask={numberMask('', ` ${symbol[row.internalId]}`)}
                        numeric
                        disabled={!isEditable}
                    />
                )
            },
            {
                name: t('tollBalanceForm->tableColumnGold'),
                selector: 'goldTier',
                cell: row => (
                    <Field
                        id={`campaignProducts[${row.internalId}].campaignProductValues[1].value`}
                        name={`campaignProducts[${row.internalId}].campaignProductValues[1].value`}
                        component={InputElement}
                        mask={numberMask('', ` ${symbol[row.internalId]}`)}
                        validate={required}
                        disabled={!isEditable}
                    />
                )
            },
            {
                name: t('tollBalanceForm->tableColumnInitialStock'),
                selector: 'initialStock',
                omit: isPermanent,
                cell: row => (
                    <Field
                        id={`campaignProducts[${row.internalId}].initialStock`}
                        name={`campaignProducts[${row.internalId}].initialStock`}
                        component={InputElement}
                        parse={value => Number(value)}
                        numeric
                        disabled={!isEditable}
                    />
                )
            },
            {
                name: t('tollBalanceForm->tableColumnCurrentStock'),
                selector: 'currentStock',
                omit: isPermanent,
                cell: row => (
                    row.currentStock
                )
            },
            {
                name: t('tollBalanceForm->tableColumnFee'),
                selector: 'fee',
                cell: row => (
                    <>
                        <Field
                            id={`campaignProducts[${row.internalId}].campaignProductValues[0].tierId`}
                            name={`campaignProducts[${row.internalId}].campaignProductValues[0].tierId`}
                            component={HiddenElement}
                            hiddenValue={1}
                        />
                        <Field
                            id={`campaignProducts[${row.internalId}].campaignProductValues[0].fee`}
                            name={`campaignProducts[${row.internalId}].campaignProductValues[0].fee`}
                            component={InputElement}
                            mask={numberMask('', ' %')}
                            guide={true}
                            validate={required}
                            disabled={!isEditable}
                        />
                        <Field
                            id={`campaignProducts[${row.internalId}].campaignProductValues[1].tierId`}
                            name={`campaignProducts[${row.internalId}].campaignProductValues[1].tierId`}
                            component={HiddenElement}
                            hiddenValue={2}
                        />
                        <Field
                            id={`campaignProducts[${row.internalId}].campaignProductValues[1].fee`}
                            name={`campaignProducts[${row.internalId}].campaignProductValues[1].fee`}
                            component={HiddenElement}
                            hiddenValue={0}
                        />
                    </>
                )
            },
            {
                button: true,
                cell: row => (
                    <ButtonToolbar>
                        <ButtonGroup className="btn-group--icons" dir="ltr">
                            <Button disabled={!isEditable} color="danger" type="button" onClick={() => this.removeProduct(row)}
                                    title={t('common:buttonTitleAttributes->delete')}>
                                <TrashCanOutlineIcon/>
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                ),
            }
        ]
    }

    renderTabAssignRules() {
        const {t, theme, type, change, partnerId} = this.props;
        const {isEditable, rowsPerPage, pageSize, currentPage, availableProducts, selectedProducts, toggleCleared, stockUnitTypesOptions, isPermanent} = this.state;

        const filteredAvailableProducts = availableProducts.filter(product => product.isEnabled);

        const options = filteredAvailableProducts.map(product => {
            return {
                label: product.name,
                value: product.id,
                isDisabled: this.state.selectedProducts.some(prod => type === 'edit' ? prod.productId === product.id : prod.id === product.id)
            }
        });

        return (
            <>
                <Field
                    name="selectedProductsCheck"
                    id="selectedProductsCheck"
                    component={HiddenElement}
                    hiddenValue=""
                    validate={requiredOneItem}
                />
                {
                    selectedProducts.length > 0 &&
                    <DataTableElement
                        theme={theme}
                        columns={this.renderTableColumns()}
                        data={selectedProducts}
                        paginationRowsPerPageOptions={rowsPerPage}
                        paginationPerPage={pageSize}
                        currentPage={currentPage}
                        selectableRows={isEditable}
                        onSelectedRowsChange={this.handleSelectedRowsChange}
                        contextActions={this.contextActions(this.editRulesModal, this.handleRowsDeleteAll)}
                        contextMessage={{
                            singular: t('tollBalanceForm->productSelectedRowsSingular'),
                            plural: t('tollBalanceForm->productSelectedRowsPlural')
                        }}
                        clearSelectedRows={toggleCleared}
                    />
                }

                <TollBalanceAddProductForm
                    options={options}
                    stockUnitTypesOptions={stockUnitTypesOptions}
                    change={change}
                    handleAddProduct={this.addProduct}
                    isPermanent={isPermanent}
                    fetchedProductsOptions={options => this.setState({availableProducts: options})}
                    partnerId={partnerId}
                    isEditable={isEditable}
                />
            </>
        )
    }

    renderTabs() {
        return (
            <>
                <TabPane tabId={0}>
                    {this.renderTabTimePeriod()}
                </TabPane>
                <TabPane tabId={1}>
                    {this.renderTabPOS()}
                </TabPane>
                <TabPane tabId={2}>
                    {this.renderTabAssignRules()}
                </TabPane>
            </>
        )
    }

    render() {
        const {t, partnerId, btnText, type, initialValues} = this.props;
        const {partnerName, activeTab, isLoading} = this.state;

        return (
            <Container>
                <LoadingElement isLoading={isLoading}/>
                <h3 className="page-title mb-4">{partnerName}</h3>
                <form className="form d-block" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Row>
                        <Col md={6}>
                            {this.renderColGeneral()}
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <div className="tabs tabs--justify tabs--bordered-bottom">
                                <div className="tabs__wrap">
                                    <Nav tabs>
                                        {this.renderTabHeader()}
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        {this.renderTabs()}
                                    </TabContent>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit" onClick={() => this.checkErrorInTabs()}>{btnText}</Button>
                        {
                            type === 'edit' && <ModalConfirmation
                                backdrop="static"
                                color="danger"
                                firstBtnText={t('modal:buttonNo')}
                                secondBtnText={t('modal:buttonYes')}
                                title={t('modal:modalDeleteTitle')}
                                btn={t('modal:btnDelete')}
                                message={<p>{t('modal:modalDeleteMessage')}</p>}
                                handleOnClick={this.handleModalDelete}
                                disabled={new Date(initialValues.beginDate) <= new Date() && (initialValues.endDate ? new Date(initialValues.endDate) >= new Date() : true) && initialValues.isEnabled}
                            />
                        }
                        <Link className="btn btn-secondary"
                              to={`/vv/tollbalance/campaigns/${partnerName}/${partnerId}`}>
                            {t('tollBalanceForm->btnCancel')}
                        </Link>
                    </ButtonToolbar>
                </form>
            </Container>
        );
    }
}

const selector = formValueSelector('tollBalanceForm');

const mapStateToProps = (state) => {
    return {
        theme: state.theme.className,
        isPermanent: selector(state, 'isPermanent'),
        productSearch: selector(state, 'newProd.productSearch'),
        newProdStockUnitType: selector(state, 'newProd.stockUnitType'),
        newProdSilverTier: selector(state, 'newProd.silverTier'),
        newProdGoldTier: selector(state, 'newProd.goldTier'),
        newProdInitialStock: selector(state, 'newProd.initialStock'),
        newProdFee: selector(state, 'newProd.fee'),
        modalForm: selector(state, 'modalForm'),
        campaignProducts: selector(state, 'campaignProducts'),
        formSyncErrors: getFormSyncErrors('tollBalanceForm')(state)
    };
};

const translationWrapped = withTranslation(['tollBalance', 'modal'])(TollBalanceForm);

const formWrapped = reduxForm({
    form: 'tollBalanceForm',
})(translationWrapped);

export default connect(mapStateToProps)(formWrapped);