import _ from 'lodash';
import {
    CREATE_POINTSOFSALE,
    DELETE_POINTSOFSALE,
    DONE_POINTSOFSALE,
    EDIT_POINTSOFSALE,
    ERROR_POINTSOFSALE,
    FETCH_POINTSOFSALE,
    FETCH_POINTSOFSALES,
    PENDING_POINTSOFSALE,
    TOGGLE_POINTSOFSALE,
    FETCH_ALL_POINTSOFSALE
} from '../actions/posActions';

const initialState = {
    done: {
        status: false,
        message: null
    },
    data: {
        pointsOfSale: [],
        totalItems: null
    },
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POINTSOFSALES:
            return {
                ...state,
                data: { pointsOfSale: _.mapKeys(action.payload.pointsOfSale, 'id'), totalItems: action.payload.total}
            };
        case FETCH_POINTSOFSALE:
            return { ...state, data: { pointsOfSale: {[action.payload.id]: action.payload}}};
        case CREATE_POINTSOFSALE:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case EDIT_POINTSOFSALE:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case TOGGLE_POINTSOFSALE:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DELETE_POINTSOFSALE:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DONE_POINTSOFSALE:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case PENDING_POINTSOFSALE:
            return {...state, done: {status: false, message: null}, error: null};
        case ERROR_POINTSOFSALE:
            return {...state, done: {status: true, message: null}, error: action.payload};
        case FETCH_ALL_POINTSOFSALE:
            return {
            ...state,
            data: { pointsOfSale: _.mapKeys(action.payload, 'key'), totalItems: action.payload.total}
        };
        default:
            return state;
    }
}