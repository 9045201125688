import React from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import { Button, ButtonToolbar, ButtonGroup } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import 'animate.css';
import DataTableElement from '../../../../shared/components/common/DataTable';
import InputElement from '../../../../shared/components/form/Input';
import SelectElement from '../../../../shared/components/form/Select';
import Moment from 'react-moment';
import FileMultipleOutlineIcon from 'mdi-react/FileMultipleOutlineIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import PencilOutlineIcon from 'mdi-react/PencilOutlineIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import ModalConfirmation from '../../../../shared/components/common/ModalConfirmation';
import DateTimePickerElement from '../../../../shared/components/form/DateTimePicker';

class TollBalanceCampaignListDataTable extends React.Component {
    state = {
        showFinished: false
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state;
    }

    handlePageChange = (currentPage, pageSize) => {
        this.setState({ currentPage, pageSize });
        this.props.handlePageChange(currentPage, pageSize);
    };

    handlePerRowsChange = (currentPage, pageSize) => {
        this.setState({ currentPage, pageSize });
        this.props.handlePageChange(currentPage, pageSize);
    };

    handleFilterData = () => {
        this.props.handleFilterData();
    }

    handleFilterClear = () => {
        this.props.handleFilterClear();
    }

    renderTableColumns() {
        const { t, location, isEnabledFilterOptions, filterTypeOptions, daysHoursFilterOptions, beginDate, endDate } = this.props;

        return [
            {
                name: t('tollBalanceCampaignList->tableColumnName'),
                selector: 'name',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.name"
                            id="filter.name"
                            component={InputElement}
                        />
                        :
                        row.name
                )
            },
            {
                name: t('tollBalanceCampaignList->tableColumnType'),
                selector: 'isPermanent',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.isPermanent"
                            id="filter.isPermanent"
                            component={SelectElement}
                            options={filterTypeOptions}
                            defaultValue={filterTypeOptions[0]}
                        />
                        :
                        row.isPermanent ? t('common->labelRegular') : t('common->labelPunctual')
                )
            },
            {
                name: t('tollBalanceCampaignList->tableColumnTimePeriod'),
                selector: 'endDate',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <>
                            <Field
                                name="filter.beginDate"
                                id="filter.beginDate"
                                component={DateTimePickerElement}
                                dateFormat="dd/MM/yyyy"
                                startDate={beginDate}
                            />
                            <Field
                                name="filter.endDate"
                                id="filter.beginDate"
                                component={DateTimePickerElement}
                                dateFormat="dd/MM/yyyy"
                                startDate={endDate}
                            />
                        </>
                        :
                        row.beginDate ? <div className="container__whiteSpace-none"><Moment format="DD/MM/YYYY" date={row.beginDate} />&nbsp;>&nbsp;
                            {row.endDate ? <Moment format="DD/MM/YYYY" date={row.endDate} /> : <span>&infin;</span>} </div>
                         : t('common:labelNotAvailable')
                )
            },
            {
                name: t('tollBalanceCampaignList->tableColumnDateTime'),
                selector: 'beginTime',
                sortable: true,
                cell: row => {
                    if (row.id === -1) {
                        return (
                            <Field
                                name="filter.dateTime"
                                id="filter.dateTime"
                                component={SelectElement}
                                options={daysHoursFilterOptions}
                                placeholder={t('common->labelAlways')}
                                isMulti
                            />
                        );
                    }

                    const days = [];
                    let countDaysOpened = 0, countDays = 0, count = 0, textDays = '';

                    days.push({ name: t('common->labelMonday'), value: row.monday });
                    days.push({ name: t('common->labelTuesday'), value: row.tuesday });
                    days.push({ name: t('common->labelWednesday'), value: row.wednesday });
                    days.push({ name: t('common->labelThursday'), value: row.thursday });
                    days.push({ name: t('common->labelFriday'), value: row.friday });
                    days.push({ name: t('common->labelSaturday'), value: row.saturday });
                    days.push({ name: t('common->labelSunday'), value: row.sunday });
                    days.map(day => day.value && countDaysOpened++);

                    return countDaysOpened > 0 ? countDaysOpened < days.length ? days.map((day, i) => {
                        if (countDays < 2 && day.value) {
                            textDays += (day.name + (countDays < 1 ? ',' : ''));
                            countDays++;
                        } else if (countDays >= 2 && day.value) {
                            count++;
                        }
                        return true;
                    }) && (textDays += (count > 0 ? ' + ' + count : '')) && (row.beginTime && row.endTime ?
                        <div>
                            {textDays}
                            <div>
                                <Moment format="HH:mm" date={row.beginTime} />&nbsp;>&nbsp;
                                <Moment format="HH:mm" date={row.endTime} />
                            </div>
                        </div>
                        : t('common:labelNotAvailable')) :
                        <div>
                            {t('common->labelAlways')}
                            <div>
                                <Moment format="HH:mm" date={row.beginTime}/>&nbsp;>&nbsp;
                                <Moment format="HH:mm" date={row.endTime}/>
                            </div>
                        </div>
                        : t('common:labelNotAvailable');
                }
            },
            {
                name: t('tollBalanceCampaignList->tableColumnPlace'),
                selector: 'campaignPointsOfSaleCount',
                cell: row => (
                    row.id === -1 ?
                        null
                        :
                        row.campaignPointsOfSaleLabel ? (row.campaignPointsOfSaleCount - 2) > 0 ? row.campaignPointsOfSaleLabel + ' + ' + (row.campaignPointsOfSaleCount - 2) : row.campaignPointsOfSaleLabel : row.campaignPointsOfSaleCount > 0 ? row.campaignPointsOfSaleCount === row.partnerPointsOfSaleCount ? t('common:labelAll') : t('common:labelNotAvailable') : t('common:labelAll')
                )
            },
            {
                name: t('tollBalanceCampaignList->tableColumnProducts'),
                selector: 'campaignProductsCount',
                cell: row => (
                    row.id === -1 ?
                        null
                        :
                        row.campaignProductsLabel ? (row.campaignProductsCount - 2) > 0 ? row.campaignProductsLabel + ' + ' + (row.campaignProductsCount - 2) : row.campaignProductsLabel : row.campaignProductsCount > 0 ? row.campaignProductsCount === row.partnerProductsCount ? t('common:labelAll') : t('common:labelNotAvailable') : t('common:labelNotAvailable')
                )
            },
            {
                name: t('tollBalanceCampaignList->tableColumnActive'),
                button: true,
                maxWidth: '200px',
                cell: row => (
                    row.id === -1 ?
                        <Field
                            name="filter.isEnabled"
                            id="filter.isEnabled"
                            component={SelectElement}
                            options={isEnabledFilterOptions}
                            defaultValue={isEnabledFilterOptions[0]}
                        />
                        :
                        <label className="toggle-btn customizer__toggle" htmlFor={`toggle_${row.id}`}>
                            <input
                                className="toggle-btn__input"
                                type="checkbox"
                                name={`toggle_${row.id}`}
                                id={`toggle_${row.id}`}
                                checked={row.isEnabled}
                                value={row}
                                onChange={() => this.props.handleToggle(row)}
                            />
                            <span className="toggle-btn__input-label" />
                        </label>
                )
            },
            {
                button: true,
                minWidth: '200px',
                cell: row => {
                    if (row.id === -1) {
                        return (
                            <ButtonToolbar>
                                <ButtonGroup className="btn-group--icons" dir="ltr">
                                    <Button
                                        outline
                                        onClick={this.handleFilterData}
                                        title={t('common:buttonTitleAttributes->filter')}>
                                        <MagnifyIcon/>
                                    </Button>
                                    <Button
                                        outline
                                        color="danger"
                                        onClick={this.handleFilterClear}
                                        title={t('common:buttonTitleAttributes->clear')}>
                                        <CloseIcon/>
                                    </Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        )
                    }

                    return (
                        <ButtonToolbar>
                            <ButtonGroup className="btn-group--icons" dir="ltr">
                                <Link 
                                    to={`${location.pathname}/edit/${row.id}`}
                                    className="btn btn-outline-secondary"
                                    title={t('common:buttonTitleAttributes->edit')}
                                >
                                    <PencilOutlineIcon/>
                                </Link>
                                <Link 
                                    to={`${location.pathname}/copy/${row.id}`}
                                    className="btn btn-outline-secondary"
                                    title={t('common:buttonTitleAttributes->copy')}
                                >
                                    <FileMultipleOutlineIcon />
                                </Link>
                                <ModalConfirmation
                                    backdrop="static"
                                    color="danger"
                                    outline
                                    firstBtnText={t('modal:buttonNo')}
                                    secondBtnText={t('modal:buttonYes')}
                                    title={t('modal:modalDeleteTitle')}
                                    btnTitle={ t('common:buttonTitleAttributes->delete')}
                                    btn={<TrashCanOutlineIcon/>}
                                    message={<p>{t('modal:modalDeleteMessage')}</p>}
                                    handleOnClick={() => this.props.handleModalDelete(row.id)}
                                    disabled={new Date(row.beginDate) <= new Date() && (row.endDate ? new Date(row.endDate) >= new Date() : true) && row.isEnabled}
                                />
                            </ButtonGroup>
                        </ButtonToolbar>
                    );
                }
            }
        ]
    }

    render() {
        const { t, data, paginationTotalRows, theme, rowsPerPage, pageSize, currentPage, showFinished, paginationDefaultPage } = this.props;

        const conditionalRowStyles = [
            {
                when: row => new Date(row.endDate) < new Date() && row.endDate !== null,
                style: {
                    backgroundColor: "rgba(234, 238, 255, 0.6)!important",
                    '&:hover': {
                        cursor: 'pointer',
                    },
                },
            }
        ]

        return (
            <>
                <div className="form__form-group">
                    <div className="form__form-group-field">
                        <label
                            className="checkbox-btn checkbox-btn--colored-click">
                            <input
                                className="checkbox-btn__checkbox"
                                type="checkbox"
                                id="isPermanent"
                                name="isPermanent"
                                checked={showFinished}
                                onChange={this.props.handleShowFinished}
                            />
                            <span className="checkbox-btn__checkbox-custom">
                                <CheckIcon />
                            </span>
                            <span
                                className="checkbox-btn__label">{t('tollBalanceCampaignList->labelFinishedCampaigns')}</span>
                        </label>
                    </div>
                </div>
                <div className="form react-datatable__filters">
                    <DataTableElement
                        theme={theme}
                        keyField="id"
                        columns={this.renderTableColumns()}
                        data={data}
                        noHeader
                        paginationServer
                        paginationTotalRows={paginationTotalRows}
                        handlePerRowsChange={this.handlePerRowsChange}
                        handlePageChange={this.handlePageChange}
                        paginationRowsPerPageOptions={rowsPerPage}
                        paginationPerPage={pageSize}
                        currentPage={currentPage}
                        receivePerRowsChange
                        withFilters
                        paginationDefaultPage={paginationDefaultPage}
                        conditionalRowStyles={conditionalRowStyles}
                    />
                </div>
            </>
        )
    }
}

export default withTranslation('tollBalance')(TollBalanceCampaignListDataTable);