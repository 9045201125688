import React from 'react';
import {Route, Switch} from 'react-router-dom';
import TollBalanceCampaigns from '../../../../Campaign/TollBalance/TollBalanceCampaigns/';
import TollBalanceCampaignList from '../../../../Campaign/TollBalance/TollBalanceCampaignList/';
import TollBalanceCreate from '../../../../Campaign/TollBalance/TollBalanceCreate';
import TollBalanceEdit from '../../../../Campaign/TollBalance/TollBalanceEdit';

export default () => (
    <Switch>
        <Route path="/vv/tollbalance/campaigns" exact component={TollBalanceCampaigns}/>
        <Route path="/vv/tollbalance/campaigns/:partnerName/:partnerId" exact component={TollBalanceCampaignList}/>
        <Route path="/vv/tollbalance/campaigns/:partnerName/:partnerId/create" component={TollBalanceCreate}/>
        <Route path="/vv/tollbalance/campaigns/:partnerName/:partnerId/edit/:id" component={TollBalanceEdit}/>
        <Route path="/vv/tollbalance/campaigns/:partnerName/:partnerId/copy/:copyId" component={TollBalanceEdit}/>
    </Switch>
);
