import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {fetchPartner, editPartner, deletePartner} from "../../../redux/actions/partnerActions";
import {
    fetchPointsOfSales,
    createPointsOfSale,
    editPointsOfSale,
    deletePointsOfSale
} from "../../../redux/actions/posActions";
import {fetchProducts, createProduct, editProduct, deleteProducts} from "../../../redux/actions/productActions";
import {store} from "react-notifications-component";
import {Container, Card, CardBody, Button} from "reactstrap";
import {cloneDeep} from "lodash";
import axios from "axios";
import Alert from "../../../shared/components/common/Alert";
import PartnerForm from "./PartnerForm/index";
import LoadingElement from "../../../shared/components/common/Loading";
import history from "../../../history";

class PartnerEdit extends React.Component {
    state = {
        loadingDone: true,
        partnerTypes: {
            data: [],
            done: false
        },
        message: false
    }

    async componentDidMount() {
        const {id, partnerId} = this.props.match.params;
        this.props.fetchPartner(id);
        this.props.fetchPointsOfSales(partnerId, 1, 1000);
        this.props.fetchProducts(partnerId, 1, 1000);
        await this.fetchPartnerTypes();
    }

    async fetchPartnerTypes() {
        try {
            const response = await axios.get('/Partners/GetPartnerType');
            response && this.setState({partnerTypes: {data: response.data, done: true}});
        } catch (e) {
            this.renderError(e);
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (prevStates.message !== this.state.message) {
            store.addNotification({
                title: this.props.t("notification:success->title"),
                message: this.props.t("notification:success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                },
            });
        }
    }

    onSubmit = async (partnerForm, posForm, productsForm) => {
        this.setState({loadingDone: false});
        let posToDelete = [], productsToDelete = [];

        await Promise.all(posForm.map(async form => {
            if (form.id) {
                if (form.invalid) {
                    posToDelete.push(form.id);
                } else {
                    await this.props.editPointsOfSale(form);
                }
            } else {
                !form.invalid && await this.props.createPointsOfSale(form);
            }
            return form;
        }));

        await Promise.all(productsForm.map(async form => {
            if (form.id) {
                if (form.invalid) {
                    productsToDelete.push(form.id);
                } else {
                    await this.props.editProduct(form);
                }
            } else {
                !form.invalid && await this.props.createProduct(form);
            }
            return form;
        }));

        posToDelete.length > 0 && await this.props.deletePointsOfSale(posToDelete);
        productsToDelete.length > 0 && await this.props.deleteProducts(productsToDelete);
        await this.props.editPartner(partnerForm);

        if (this.props.partnerError || this.props.POSError || this.props.productsError) {
            this.setState({loadingDone: true});
        } else {
            this.setState({message: true});
            history.replace('/vv/partner');
        }
    };

    onDelete = async id => {
        const {POS, products} = this.props;
        let posToDelete = [], productsToDelete = [];

        POS.map(pos => posToDelete.push(pos.id));
        products.map(product => productsToDelete.push(product.id))

        await this.props.deletePointsOfSale(posToDelete);
        await this.props.deleteProducts(productsToDelete)
        await this.props.deletePartner(id, 'form');
    };

    normalizePartnerData() {
        const {t, partner} = this.props;
        const {partnerTypes} = this.state;
        let partnerCopy = cloneDeep(partner);

        partnerTypes.data.some(partnerType => partnerType.id === partnerCopy.partnerType && (partnerCopy.partnerType = {
            label: t(`partnerForm->labelPartnerType${partnerType.name}`),
            value: partnerCopy.partnerType,
        }));
        return partnerCopy;
    }

    normalizePOSData() {
        let POSCopy = cloneDeep(this.props.POS);

        POSCopy.map((pos, index) => {
            pos.internalId = index;
            pos.partnerId = this.props.match.params.partnerId;
            return pos;
        });
        return POSCopy;
    }

    normalizeProductsData() {
        let productsCopy = cloneDeep(this.props.products);

        productsCopy.map((pos, index) => {
            pos.internalId = index;
            pos.partnerId = this.props.match.params.partnerId;
            return pos;
        });
        return productsCopy;
    }

    renderError = (error, type) => {
        const {t} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color="danger" className="alert--bordered" icon>
                            <p className="bold-text">{t('alert:danger->title')}</p>
                            <p>
                                {t('alert:danger->message', {error})}
                            </p>
                            <p>{t('partnerForm->labelErrorSaving', {origin: type})}</p>
                            <Button size="sm" type="button" className="mb-0"
                                    onClick={() => this.componentDidMount()}>{t('alert:danger->tryAgain')}</Button>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    };

    render() {
        const {
            t,
            partnerDone,
            partnerError,
            POSDone,
            POSError,
            productsDone,
            productsError
        } = this.props;
        const {loadingDone, partnerTypes} = this.state;

        if (!partnerDone || !POSDone || !productsDone || !loadingDone || !partnerTypes.done) {
            return <LoadingElement isLoading={true}/>;
        }

        if (partnerError || POSError || productsError) {
            return this.renderError((partnerError || POSError || productsError), ((partnerError && 'partner') || (POSError && 'pos') || (productsError && 'products')));
        }

        const POS = this.normalizePOSData();
        const products = this.normalizeProductsData();
        const partner = this.normalizePartnerData();

        return (
            <PartnerForm
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                type="edit"
                btnText={t("partnerForm->btnSave")}
                initialValues={{partner, pos: POS, products: products}}
                partnerId={this.props.match.params.partnerId}
                POS={POS}
                products={products}
                partnerTypes={partnerTypes.data}
            />
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        partner: state.partners.data.partners[ownProps.match.params.id],
        partnerDone: state.partners.done.status,
        partnerError: state.partners.error,
        message: state.partners.done.message,
        POS: Object.values(state.pointsOfSale.data.pointsOfSale),
        POSDone: state.pointsOfSale.done.status,
        POSError: state.pointsOfSale.error,
        products: Object.values(state.products.data.products),
        productsDone: state.products.done.status,
        productsError: state.products.error
    };
};

export default compose(
    withTranslation(["partner", "notification", "alert"]),
    connect(mapStateToProps, {
        fetchPartner,
        editPartner,
        deletePartner,
        fetchPointsOfSales,
        createPointsOfSale,
        editPointsOfSale,
        deletePointsOfSale,
        fetchProducts,
        createProduct,
        editProduct,
        deleteProducts
    })
)(PartnerEdit);