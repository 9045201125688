import React from "react";
import {connect} from "react-redux";
import {Field, reduxForm, formValueSelector, getFormSyncErrors} from "redux-form";
import {Link} from "react-router-dom";
import {cloneDeep, differenceWith, isEqual} from "lodash";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    ButtonToolbar,
    Button,
    ButtonGroup,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import {maxLength50, required} from "../../../../shared/validations";
import classnames from "classnames";
import {withTranslation} from "react-i18next";
import "animate.css";
import InputElement from "../../../../shared/components/form/Input";
import CheckBoxElement from "../../../../shared/components/form/CheckBox";
import ModalConfirmation from "../../../../shared/components/common/ModalConfirmation";
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE, ICON_MAX_SIZE, ICON_TYPE_FORMAT} from "../variables";
import PartnerPOSDataTable from "./PartnerPOSDataTable";
import PartnerProductDataTable from "./PartnerProductDataTable";
import memoizeOne from "memoize-one";
import axios from "axios";
import SelectElement from "../../../../shared/components/form/Select";
import LoadingElement from "../../../../shared/components/common/Loading";
import FileInput from "../../../../shared/components/form/FileInput";
import ImagePreview from "../../../../shared/components/form/ImagePreview";
import TrashCanOutlineIcon from "mdi-react/TrashCanOutlineIcon";

class PartnerForm extends React.Component {
    state = {
        isLoading: false,
        activeTab: 0,
        tabs: [
            this.props.t('partnerForm->tabPOS'),
            this.props.t('partnerForm->tabProducts')
        ],
        POS: this.props.POS ? cloneDeep(this.props.POS) : [],
        products: this.props.products ? cloneDeep(this.props.products) : [],
        isEnabledFilterOptions: [
            {value: '', label: this.props.t('common->labelAll')},
            {value: true, label: this.props.t('common->labelYes')},
            {value: false, label: this.props.t('common->labelNo')}
        ],
        partnerTypesOptions: this.props.partnerTypes.map(partnerType => (
            {
                label: this.props.t(`partnerForm->labelPartnerType${partnerType.name}`),
                value: partnerType.id,
            }
        )),
        rowsPerPage: ROWS_PER_PAGE,
        pageSize: PAGE_SIZE,
        currentPage: CURRENT_PAGE,
        productsDefaultPage: 1,
        POSDefaultPage: 1,
        productsGoToFinalPage: false,
        POSGoToFinalPage: false,
        partnerId: this.props.partnerId,
        POSCount: (this.props.POS && this.props.POS.length) || 0,
        productsCount: (this.props.products && this.props.products.length) || 0,
        selectedRows: [],
        POSToggleCleared: false,
        productsToggleCleared: false,
        partnerImage: this.props.initialValues.partner.partnerImage || {}
    };

    componentDidMount() {
        if (this.props.type === 'edit') {
            this.checkItems('products');
            this.checkItems('pos');
            this.handleFilterClear('pos');
            this.handleFilterClear('products')
        } else {
            this.props.change('partner.partnerType', this.state.partnerTypesOptions[0]);
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return nextState !== this.state;
    }

    onSubmit = formValues => {
        this.setState({isLoading: true});
        const {type} = this.props;
        const {partnerImage} = this.state;

        let posAttributeSameValue, prodAttributeSameValue;
        if (type === 'edit' || type === 'copy') {
            posAttributeSameValue = formValues.pos.some(formValue => formValue.nameNotValid || formValue.referenceNotValid || formValues.latitudeNotValid || formValue.longitudeNotValid);
            prodAttributeSameValue = formValues.products.some(formValue => formValue.nameNotValid || formValue.referenceNotValid || formValues.latitudeNotValid || formValue.longitudeNotValid);
        }

        if (!posAttributeSameValue && !formValues.partner.nameNotValid && !prodAttributeSameValue) {
            let partnerForm = formValues.partner, posForm, productsForm;
            partnerForm.partnerType = partnerForm.partnerType.value;
            if (type === 'edit') {
                posForm = differenceWith(formValues.pos, this.props.initialValues.pos, isEqual);
                productsForm = differenceWith(formValues.products, this.props.initialValues.products, isEqual);
            } else if (type === 'copy') {
                posForm = formValues.pos.filter(pos => !pos.invalid);
                productsForm = formValues.products.filter(product => !product.invalid);
            }
            partnerForm.partnerImage = {
                imageData: partnerImage.imageData,
                format: partnerImage.format
            };
            type === 'edit' && this.props.initialValues.partner.partnerImage && (partnerForm.partnerImage.id = this.props.initialValues.partner.partnerImage.id);

            this.props.onSubmit(partnerForm, posForm, productsForm);
        }
        else {
            this.setState({ isLoading: false });
        }
    };

    checkErrorInTabs() {
        const {formSyncErrors} = this.props;

        if (formSyncErrors.pos) {
            this.setState({activeTab: 0});
        } else if (formSyncErrors.products) {
            this.setState({activeTab: 1});
        }
    }

    contextActions = memoizeOne(deleteHandler => {
        const {t} = this.props;
        return (
            <ButtonToolbar>
                <ButtonGroup className="btn-group--icons" dir="ltr">
                    <ModalConfirmation
                        backdrop="static"
                        color="danger"
                        firstBtnText={t('modal:modal->buttonNo')}
                        secondBtnText={t('modal:modal->buttonYes')}
                        title={t('modal:modalDeleteTitle')}
                        btn={<TrashCanOutlineIcon/>}
                        message={<p>{t('modal:modalDeleteAllMessage')}</p>}
                        handleOnClick={deleteHandler}
                        closeOnAction
                    />
                </ButtonGroup>
            </ButtonToolbar>
        )
    });

    handleSelectedRowsChange = state => {
        this.setState({selectedRows: state.selectedRows});
    };

    handleRowsDeleteAll = (type) => {
        const {selectedRows} = this.state;

        if (type === 'products') {
            selectedRows.map(row => this.removeItem(row, type));
            this.setState(state => ({productsToggleCleared: !state.productsToggleCleared}));
        } else {
            selectedRows.map(row => this.removeItem(row, type));
            this.setState(state => ({POSToggleCleared: !state.POSToggleCleared}));
        }
    };

    handleToggle = (internalId, type) => {
        if (type === 'products') {
            const productsCopy = cloneDeep(this.state.products);

            productsCopy[internalId].isEnabled = !productsCopy[internalId].isEnabled;
            this.props.change('partnerForm', `products[${internalId}].isEnabled`, productsCopy[internalId].isEnabled);
            this.setState({products: productsCopy});
        } else {
            const POSCopy = cloneDeep(this.state.POS);

            POSCopy[internalId].isEnabled = !POSCopy[internalId].isEnabled;
            this.props.change('partnerForm', `pos[${internalId}].isEnabled`, POSCopy[internalId].isEnabled);
            this.setState({POS: POSCopy});
        }
    };

    handleModalDelete = () => {
        this.props.onDelete(this.props.id);
    };

    toggleTab = (tab) => {
        const {activeTab} = this.state;
        activeTab !== tab && this.setState({activeTab: tab});
    };

    handlePerRowsChange = (currentPage, pageSize) => {
        this.setState({currentPage, pageSize});
    };

    checkItems(type) {
        const {change} = this.props;
        const {products, POS} = this.state;

        if (type === 'products') {
            products.length > 0 ? change('productsCheck', 'true') : change('productsCheck', null);
        } else {
            POS.length > 0 ? change('posCheck', 'true') : change('posCheck', null);
        }
    }

    addItem = (newItem, type) => {
        const {partnerId, productsCount, POSCount, pageSize} = this.state;

        if (type === 'products') {
            const addProduct = {
                name: newItem.prodName,
                reference: newItem.prodReference,
                isEnabled: true,
                internalId: productsCount
            };

            this.setState(state => ({
                products: [...state.products, addProduct],
                productsCount: state.productsCount + 1,
                newProduct: {},
                productsDefaultPage: Math.ceil((state.products.length + 1) / pageSize),
                productsGoToFinalPage: !state.productsGoToFinalPage
            }), () => this.checkItems(type));

            this.props.change('newProduct', {
                prodName: '',
                prodReference: ''
            });

            this.props.array.push('products', {
                name: newItem.prodName,
                reference: newItem.prodReference,
                isEnabled: true,
                partnerId,
                internalId: productsCount
            });
        } else {
            const addPOS = {
                name: newItem.posName,
                reference: newItem.posReference,
                latitude: newItem.posLatitude,
                longitude: newItem.posLongitude,
                isEnabled: true,
                internalId: POSCount
            };

            this.setState(state => ({
                POS: [...state.POS, addPOS],
                POSCount: state.POSCount + 1,
                newPOS: {},
                POSDefaultPage: Math.ceil((state.POS.length + 1) / pageSize),
                POSGoToFinalPage: !state.POSGoToFinalPage
            }), () => this.checkItems(type));

            this.props.change('newPOS', {
                posName: '',
                posReference: '',
                posLatitude: '',
                posLongitude: ''
            });

            this.props.array.push('pos', {
                name: newItem.posName,
                reference: newItem.posReference,
                latitude: newItem.posLatitude,
                longitude: newItem.posLongitude,
                isEnabled: true,
                partnerId,
                internalId: POSCount
            });
        }
    };

    removeItem = (row, type) => {
        if (type === 'products') {
            this.props.change(`products[${row.internalId}].invalid`, true);
            this.setState(state => ({
                products: state.products.filter(product => product.internalId !== row.internalId)
            }), () => this.checkItems(type));
        } else {
            this.props.change(`pos[${row.internalId}].invalid`, true);
            this.setState(state => ({
                POS: state.POS.filter(pos => pos.internalId !== row.internalId)
            }), () => this.checkItems(type));
        }
    };

    handleFilterData = type => {
        if (type === 'products') {
            const {productsFormValues} = this.props;
            this.setState({products: productsFormValues, filtered: true});
        } else {
            const {posFormValues} = this.props;
            this.setState({POS: posFormValues, filtered: true});
        }
    }

    handleFilterClear = type => {
        type === 'products' ?
            this.props.change('filter.products', {
                name: '',
                reference: '',
                isEnabled: this.state.isEnabledFilterOptions[0]
            })
            :
            this.props.change('filter.pos', {
                name: '',
                reference: '',
                latitude: '',
                longitude: '',
                isEnabled: this.state.isEnabledFilterOptions[0]
            });
        this.setState({filtered: false});
    }

    async checkNameValue(value) {
        const {initialValues, change} = this.props;
        if (value !== initialValues.partner.name) {
            try {
                const response = await axios.get("/Partners/PartnerExists", {
                    params: {
                        name: value
                    }
                });
                if (response) {
                    if (response.data) {
                        change('partner.nameNotValid', true);
                        this.setState({attributeChecked: true});
                    } else {
                        change('partner.nameNotValid', false);
                        this.setState({attributeChecked: false});
                    }
                } else {
                    console.log('error')
                }
            } catch (e) {
                console.log(e)
            }
        }
    }

    checkProductsValues = (internalId, attribute, newProd = null) => {
        const {productsFormValues, change} = this.props;

        if (internalId !== -1) {
            const attributeCheck = productsFormValues[internalId];
            productsFormValues.some((prod, index) => {
                if (internalId !== index && prod[attribute] === attributeCheck[attribute]) {
                    change(`products[${internalId}].${attribute}NotValid`, true);
                    this.setState({attributeChecked: true})
                    return true;
                } else {
                    change(`products[${internalId}].${attribute}NotValid`, false);
                    this.setState({attributeChecked: false})
                    return false;
                }
            });
        } else {
            productsFormValues.some(pos => {
                if (pos[attribute] === newProd[`prod${attribute.charAt(0).toUpperCase() + attribute.slice(1)}`]) {
                    change(`newProd.${attribute}NotValid`, true);
                    this.setState({attributeChecked: true})
                    return true;
                } else {
                    change(`newProd.${attribute}NotValid`, false);
                    this.setState({attributeChecked: false})
                    return false;
                }
            });
        }
    };

    checkPointOfSaleValues = (internalId, attribute, newPOS = null) => {
        const {posFormValues} = this.props;

        if (internalId !== -1) {
            const attributeCheck = posFormValues[internalId];
            posFormValues.some((pos, index) => {
                if (attribute === 'latitude' || attribute === 'longitude') {
                    if (internalId !== index && pos.latitude === attributeCheck.latitude && pos.longitude === attributeCheck.longitude) {
                        this.props.change(`pos[${internalId}].latitudeNotValid`, true);
                        this.props.change(`pos[${internalId}].longitudeNotValid`, true);
                        this.setState({attributeChecked: true})
                        return true;
                    } else {
                        this.props.change(`pos[${internalId}].latitudeNotValid`, false);
                        this.props.change(`pos[${internalId}].longitudeNotValid`, false);
                        this.setState({attributeChecked: false})
                        return false;
                    }
                }
                if (internalId !== index && (pos[attribute] === attributeCheck[attribute])) {
                    this.props.change(`pos[${internalId}].${attribute}NotValid`, true);
                    this.setState({attributeChecked: true})
                    return true;
                } else {
                    this.props.change(`pos[${internalId}].${attribute}NotValid`, false);
                    this.setState({attributeChecked: false})
                    return false;
                }
            });
        } else {
            posFormValues.some(pos => {
                if (attribute === 'latitude' || attribute === 'longitude') {
                    if (pos.latitude === newPOS.posLatitude && pos.longitude === newPOS.posLongitude) {
                        this.props.change(`newPOS.latitudeNotValid`, true);
                        this.props.change(`newPOS.longitudeNotValid`, true);
                        this.setState({attributeChecked: true})
                        return true;
                    } else {
                        this.props.change(`newPOS.latitudeNotValid`, false);
                        this.props.change(`newPOS.longitudeNotValid`, false);
                        this.setState({attributeChecked: false})
                        return false;
                    }
                }
                if (pos[attribute] === newPOS[`pos${attribute.charAt(0).toUpperCase() + attribute.slice(1)}`]) {
                    this.props.change(`newPOS.${attribute}NotValid`, true);
                    this.setState({attributeChecked: true})
                    return true;
                } else {
                    this.props.change(`newPOS.${attribute}NotValid`, false);
                    this.setState({attributeChecked: false})
                    return false;
                }
            });
        }
    }

    handleImageUpload(e) {
        if (e) {
            const reader = new FileReader();
            reader.readAsDataURL(e.file);
            reader.onloadend = (e) => this.setState({
                partnerImage: {
                    imageData: e.target.result.split(",")[1],
                    format: e.target.result.split(",")[0]
                }
            });
        }
    }

    renderTabHeader() {
        const {activeTab, tabs} = this.state;

        return (
            tabs.map((tab, index) => {
                return (
                    <NavItem key={index}>
                        <NavLink
                            className={classnames({active: activeTab === index})}
                            onClick={() => this.toggleTab(index)}
                        >
                            {tab}
                        </NavLink>
                    </NavItem>
                )
            })
        );
    }

    renderColImage() {
        const {t} = this.props;
        const {partnerImage} = this.state;

        return (
            <Card>
                <CardBody>
                    <div className='card__title'>
                        <h5 className='bold-text'>{t("partnerForm->formIcon")}</h5>
                    </div>
                    <Field
                        name="partner.partnerImage"
                        id="partner.partnerImage"
                        component={FileInput}
                        label={t('partnerForm->labelIcon')}
                        onChange={(e) => this.handleImageUpload(e)}
                        types={ICON_TYPE_FORMAT}
                        maxSize={ICON_MAX_SIZE}
                        validate={required}
                    />
                    {
                        partnerImage &&
                        <ImagePreview src={partnerImage.imageData} format={partnerImage.format}/>
                    }
                </CardBody>
            </Card>
        );
    }

    renderColGeneral() {
        const {t, type, initialValues, partnerFormValues} = this.props;
        const {partnerTypesOptions} = this.state;

        return (
            <Card>
                <CardBody>
                    <div className='card__title'>
                        <h5 className='bold-text'>{t("partnerForm->formTitle")}</h5>
                    </div>
                    <Field
                        name="partner.isEnabled"
                        id="partner.isEnabled"
                        component={CheckBoxElement}
                        defaultChecked={initialValues.partner.isEnabled}
                        label={t('partnerForm->labelEnabled')}
                        className="colored-click"
                    />
                    <Field
                        name="partner.name"
                        id="partner.name"
                        component={InputElement}
                        label={t('partnerForm->labelPartnerName')}
                        validate={[required, maxLength50]}
                        className={partnerFormValues && partnerFormValues.nameNotValid ? 'mb-0' : ''}
                        onBlur={e => this.checkNameValue(e.target.value)}
                    />
                    {
                        partnerFormValues && partnerFormValues.nameNotValid &&
                        <span className="form__form-group-error mb-3">
                            {t('partnerForm->labelCustomErrorSameValue', {
                                attribute: t('partnerForm->labelPartnerName'),
                                attributePlural: ''
                            })}
                        </span>
                    }
                    <Field
                        name="partner.partnerType"
                        id="partner.partnerType"
                        component={SelectElement}
                        options={partnerTypesOptions}
                        defaultValue={partnerTypesOptions[0]}
                        label={t('partnerForm->labelPartnerType')}
                        validate={required}
                    />
                    {
                        type === 'edit' &&
                        <Field
                            name="partner.partnerId"
                            id="partner.partnerId"
                            component={InputElement}
                            label={t('partnerForm->labelExternalReference')}
                            disabled
                        />
                    }
                </CardBody>
            </Card>
        );
    }

    renderTabs() {
        const {POS, products, isEnabledFilterOptions, POSToggleCleared, productsToggleCleared} = this.state;
        const {theme, filterPOSName, filterPOSReference, filterPOSLatitude, filterPOSLongitude, filterPOSIsEnabled, filterProductsName, filterProductsReference, filterProductsIsEnabled} = this.props;

        let filteredPOS, filteredProducts;
        filterPOSName || filterPOSReference || filterPOSLatitude || filterPOSLongitude || filterPOSIsEnabled
            ?
            filteredPOS = POS.filter(item =>
                (
                    !item.invalid &&
                    (!filterPOSName || item.name.toLowerCase().includes(filterPOSName)) &&
                    (!filterPOSReference || item.reference.toLowerCase().includes(filterPOSReference)) &&
                    (!filterPOSLatitude || item.latitude.toLowerCase().includes(filterPOSLatitude)) &&
                    (!filterPOSLongitude || item.longitude.toLowerCase().includes(filterPOSLongitude)) &&
                    (item.isEnabled.toString().includes(filterPOSIsEnabled.value.toString()))
                )
            )
            :
            filteredPOS = [...POS];

        filterProductsName || filterProductsReference || filterProductsIsEnabled
            ?
            filteredProducts = products.filter(item =>
                (
                    !item.invalid &&
                    (!filterProductsName || item.name.toLowerCase().includes(filterProductsName)) &&
                    (!filterProductsReference || item.reference.toLowerCase().includes(filterProductsReference)) &&
                    (item.isEnabled.toString().includes(filterProductsIsEnabled.value.toString()))
                )
            )
            :
            filteredProducts = [...products];

        return (
            <>
                <TabPane tabId={0}>
                    <PartnerPOSDataTable
                        POS={filteredPOS}
                        theme={theme}
                        addItem={this.addItem}
                        removeItem={this.removeItem}
                        handlePerRowsChange={this.handlePerRowsChange}
                        handleSelectedRowsChange={this.handleSelectedRowsChange}
                        handleContextActions={this.contextActions(() => this.handleRowsDeleteAll('pos'))}
                        isEnabledFilterOptions={isEnabledFilterOptions}
                        handleFilterData={this.handleFilterData}
                        handleFilterClear={this.handleFilterClear}
                        POSToggleCleared={POSToggleCleared}
                        handleToggle={this.handleToggle}
                        checkPointOfSaleValues={this.checkPointOfSaleValues}
                        posFormValues={this.props.posFormValues}
                        newPOSFormValues={this.props.newPOSFormValues}
                        change={this.props.change}
                    />
                </TabPane>
                <TabPane tabId={1}>
                    <PartnerProductDataTable
                        products={filteredProducts}
                        theme={theme}
                        addItem={this.addItem}
                        removeItem={this.removeItem}
                        handlePerRowsChange={this.handlePerRowsChange}
                        handleRowsDeleteAll={this.handleRowsDeleteAll}
                        handleSelectedRowsChange={this.handleSelectedRowsChange}
                        handleContextActions={this.contextActions(() => this.handleRowsDeleteAll('products'))}
                        isEnabledFilterOptions={isEnabledFilterOptions}
                        handleFilterData={this.handleFilterData}
                        handleFilterClear={this.handleFilterClear}
                        productsToggleCleared={productsToggleCleared}
                        checkProductsValues={this.checkProductsValues}
                        prodFormValues={this.props.productsFormValues}
                        newProdFormValues={this.props.newProdFormValues}
                        change={this.props.change}
                    />
                </TabPane>
            </>
        )
    }

    render() {
        const {t, btnText, type, handleSubmit} = this.props;
        const {activeTab, isLoading} = this.state;

        return (
            <Container>
                <LoadingElement isLoading={isLoading}/>
                <form className="form d-block" onSubmit={handleSubmit(this.onSubmit)} noValidate>
                    <Row>
                        <Col md={6}>
                            {this.renderColGeneral()}
                        </Col>
                        <Col md={6}>
                            {this.renderColImage()}
                        </Col>
                    </Row>
                    {
                        type === 'edit' &&
                        <Card>
                            <CardBody>
                                <div className="tabs tabs--justify tabs--bordered-bottom">
                                    <div className="tabs__wrap">
                                        <Nav tabs>
                                            {this.renderTabHeader()}
                                        </Nav>
                                        <TabContent activeTab={activeTab}>
                                            {this.renderTabs()}
                                        </TabContent>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    }
                    <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit" onClick={() => this.checkErrorInTabs()}>
                            {btnText}
                        </Button>
                        {type === "edit" && (
                            <ModalConfirmation
                                backdrop="static"
                                color="danger"
                                firstBtnText={t('modal:buttonNo')}
                                secondBtnText={t('modal:buttonYes')}
                                title={t('modal:modalDeleteTitle')}
                                btn={t('modal:btnDelete')}
                                message={<p>{t('modal:modalDeleteAllMessage')}</p>}
                                handleOnClick={this.handleModalDelete}
                            />
                        )}
                        <Link className="btn btn-secondary" to="/vv/partner">
                            {t('partnerForm->btnCancel')}
                        </Link>
                    </ButtonToolbar>
                </form>

            </Container>
        );
    }
}

const selector = formValueSelector('partnerForm');

const mapStateToProps = (state) => {
    return {
        id: selector(state, 'partner.id'),
        theme: state.theme.className,
        formSyncErrors: getFormSyncErrors('partnerForm')(state),
        posFormValues: selector(state, 'pos'),
        partnerFormValues: selector(state, 'partner'),
        newPOSFormValues: selector(state, 'newPOS'),
        productsFormValues: selector(state, 'products'),
        newProdFormValues: selector(state, 'newProd'),
        filterPOSName: selector(state, 'filter.pos.name'),
        filterPOSReference: selector(state, 'filter.pos.reference'),
        filterPOSLatitude: selector(state, 'filter.pos.latitude'),
        filterPOSLongitude: selector(state, 'filter.pos.longitude'),
        filterPOSIsEnabled: selector(state, 'filter.pos.isEnabled'),
        filterProductsName: selector(state, 'filter.products.name'),
        filterProductsReference: selector(state, 'filter.products.reference'),
        filterProductsIsEnabled: selector(state, 'filter.products.isEnabled'),
    };
};

const translationWrapped = withTranslation(["partner", "modal", "common"])(PartnerForm);

const formWrapped = reduxForm({
    form: "partnerForm"
})(translationWrapped);

export default connect(mapStateToProps)(formWrapped);
