import React from "react";
import {connect} from "react-redux";
import {createPartner} from "../../../redux/actions/partnerActions";
import {fetchLanguages} from "../../../redux/actions/themeActions";
import {store} from "react-notifications-component";
import {Card, CardBody, Container} from "reactstrap";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Link} from "react-router-dom";
import PartnerForm from "./PartnerForm/index";
import axios from "axios";
import Alert from "../../../shared/components/common/Alert";
import LoadingElement from "../../../shared/components/common/Loading";

class PartnerCreate extends React.Component {
    state = {
        partnerTypes: {
            data: [],
            done: false
        }
    };

    async componentDidMount() {
        await this.fetchPartnerTypes();
    }
    
    async fetchPartnerTypes() {
        try {
            const response = await axios.get('/Partners/GetPartnerType');
            response && this.setState({partnerTypes: {data: response.data, done: true}});
        } catch (e) {
            console.log(e)
            this.renderError(e);
        }
    }

    componentDidUpdate() {
        if (this.props.message) {
            store.addNotification({
                title: this.props.t("notification:success->title"),
                message: this.props.t("notification:success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                },
            });
        }
    }

    onSubmit = (formValues) => {
        this.props.createPartner(formValues);
    };

    renderError = (error) => {
        const {t, partnerError} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color='danger' className='alert--bordered' icon>
                            <>
                                <p className='bold-text'>{t("alert:danger->title")}</p>
                                <p>
                                    {t("alert:danger->message", {
                                        error: error || partnerError
                                    })}
                                </p>
                                <Link to='/vv/partner'>{t("alert:danger->goBack")}</Link>
                            </>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    };

    render() {
        const {t} = this.props;
        const {partnerTypes} = this.state;

        if (!partnerTypes.done) {
            return <LoadingElement isLoading={true}/>;
        }

        return (
            <PartnerForm
                onSubmit={this.onSubmit}
                btnText={t("content:contentForm->btnCreate")}
                partnerTypes={partnerTypes.data}
                initialValues={{partner: {isEnabled: true}}}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        message: state.partners.done.message
    };
};

export default compose(withTranslation(["partner", "notification"]), connect(mapStateToProps, {
    createPartner,
    fetchLanguages
}))(PartnerCreate);
