import axios from 'axios';

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const TOGGLE_PRODUCT = 'TOGGLE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DONE_PRODUCT = 'DONE_PRODUCT';
export const PENDING_PRODUCT = 'PENDING_PRODUCT';
export const ERROR_PRODUCT = 'ERROR_PRODUCT';

export const fetchProducts = (partnerId, page, pageSize) => async dispatch => {
    dispatch({type: PENDING_PRODUCT});
    try {
        const params = { "partnerId": partnerId, "page": page, "pageSize": pageSize };
        const response = await axios.get('/Products/GetProducts', { headers: { language: localStorage.getItem('i18nextLng')}, params: params});
        if (response) {
            dispatch({type: FETCH_PRODUCTS, payload: response.data});
            dispatch({type: DONE_PRODUCT, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_PRODUCT, payload: e.message});
    }
};

export const createProduct = formValues => async dispatch => {
    try {
        const response = await axios.post('/Products', formValues);
        if (response) {
            dispatch({ type: CREATE_PRODUCT, payload: 'operationSuccess' });
        }
    } catch (e) {
        dispatch({type: ERROR_PRODUCT, payload: e.message});
    }
};

export const editProduct = formValues => async dispatch => {
    try {
        const response = await axios.put('/Products', formValues);
        if (response) {
            dispatch({type: EDIT_PRODUCT, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_PRODUCT, payload: e.message});
    }
};

export const toggleProduct = formValues => async dispatch => {
    dispatch({type: PENDING_PRODUCT});
    try {
        const response = await axios.patch('/Products', {Id: formValues.id, IsEnabled: !formValues.isEnabled});
        if (response) {
            formValues.isEnabled = !formValues.isEnabled;
            dispatch({type: TOGGLE_PRODUCT, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_PRODUCT, payload: e.message});
    }
};

export const deleteProducts = productArray => async dispatch => {
    dispatch({type: PENDING_PRODUCT});
    try {
        const response = await axios.delete(`/Products`, { data: productArray });
        if (response) {
            dispatch({type: DELETE_PRODUCT, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_PRODUCT, payload: e.message});
    }
};

