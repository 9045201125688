import React from 'react';
import {Button, ButtonToolbar, ButtonGroup, Badge} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import DataTableServerElement from '../../../../shared/components/common/DataTableServer';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import {
    DataTableFiltersCampaignName,
    DataTableFiltersNIF,
    DataTableFiltersPartnerName,
    DataTableFiltersVoucherCode, DataTableFiltersVoucherState
} from './DataTableFilters';
import Moment from 'react-moment';

class VoucherMonitoringDataTable extends React.Component {
    handlePageChange = (currentPage, pageSize) => {
        this.props.handlePageChange(currentPage, pageSize);
    };

    handlePerRowsChange = (currentPage, pageSize) => {
        this.props.handlePageChange(currentPage, pageSize);
    };

    handleDataSort = (column, order) => {
        const {orderColumns} = this.props;
        let sortColumn = null;

        if (orderColumns.some(orderColumn => orderColumn.name.toLowerCase() === column.selector.toLowerCase() && (sortColumn = orderColumn))) {
            this.props.handleDataSort(sortColumn, order);
        }
    }

    handleFilterSubmit = formValues => {
        this.props.handleFilterData(formValues.filter);
    };

    handleFilterClear = () => {
        this.props.handleFilterClear();
    };

    renderTableColumns() {
        const {t, voucherStateOptions} = this.props;
        
        return [
            {
                name: t('voucherMonitoring->tableColumnCampaign'),
                selector: 'campaignTitle',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <DataTableFiltersCampaignName t={t}/>
                        :
                        row.campaignName
                )
            },
            {
                name: t('voucherMonitoring->tableColumnPartner'),
                selector: 'partnerName',
                cell: row => (
                    row.id === -1 ?
                        <DataTableFiltersPartnerName t={t}/>
                        :
                        row.partnerName
                )
            },
            {
                name: t('voucherMonitoring->tableColumnVoucherCode'),
                selector: 'voucherCode',
                sortable: true,
                maxWidth: '250px',
                cell: row => (
                    row.id === -1 ?
                        <DataTableFiltersVoucherCode/>
                        :
                        row.voucherCode
                )
            },
            {
                name: t('voucherMonitoring->tableColumnNIF'),
                selector: 'nif',
                sortable: true,
                maxWidth: '250px',
                cell: row => (
                    row.id === -1 ?
                        <DataTableFiltersNIF/>
                        :
                        row.nif
                )
            },
            {
                name: t('voucherMonitoring->tableColumnVoucherState'),
                selector: 'voucherState',
                sortable: true,
                maxWidth: '150px',
                cell: row => (
                    row.id === -1 ?
                        <DataTableFiltersVoucherState voucherStateOptions={voucherStateOptions}/>
                        :
                        voucherStateOptions.map(voucherState =>
                            voucherState.value === row.voucherState &&
                            <Badge key={voucherState.value} color={voucherState.value === 4 || voucherState.value === 5 ? 'danger' : voucherState.value === 3 ? 'success' : 'secondary'}>
                                {voucherState.label}
                                <span className="d-block">
                                    <Moment format="DD/MM/YYYY HH:mm" date={row.voucherStateDate}/>
                                </span>
                            </Badge>
                        )
                )
            },
            {
                maxWidth: '100px',
                cell: row => (
                    row.id === -1 &&
                    <ButtonToolbar>
                        <ButtonGroup className="btn-group--icons" dir="ltr">
                            <Button outline onClick={this.handleFilterData}
                                    title={t('common:buttonTitleAttributes->filter')}>
                                <MagnifyIcon/>
                            </Button>
                            <Button outline color="danger" onClick={this.handleFilterClear}
                                    title={t('common:buttonTitleAttributes->clear')}>
                                <CloseIcon/>
                            </Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                )
            }
        ]
    }

    render() {
        const {data, progressLoading, paginationTotalRows, theme, rowsPerPage, pageSize, currentPage, defaultSortAsc, defaultSortField, paginationResetDefaultPage} = this.props;
        
        return (
            <form onSubmit={this.props.handleSubmit(this.handleFilterSubmit)}>
                <div className="form react-datatable__filters">
                    <DataTableServerElement
                        theme={theme}
                        keyField="id"
                        columns={this.renderTableColumns()}
                        data={data}
                        noHeader
                        paginationServer
                        paginationTotalRows={paginationTotalRows}
                        handlePerRowsChange={this.handlePerRowsChange}
                        handlePageChange={this.handlePageChange}
                        paginationRowsPerPageOptions={rowsPerPage}
                        paginationPerPage={pageSize}
                        currentPage={currentPage}
                        receivePerRowsChange
                        paginationResetDefaultPage={paginationResetDefaultPage}
                        sortServer
                        defaultSortField={defaultSortField}
                        defaultSortAsc={defaultSortAsc}
                        handleDataSort={this.handleDataSort}
                        withFilters
                        progressPending={progressLoading}
                    />
                </div>
            </form>
            
        )
    }
}

export default withTranslation('voucher')(VoucherMonitoringDataTable);