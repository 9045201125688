import React from "react";
import {Container, Card, CardBody, Button, ButtonToolbar} from "reactstrap";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {required} from "../../../../shared/validations";
import {Link} from "react-router-dom";
import {Field, reduxForm} from "redux-form";
import {store} from "react-notifications-component";
import {fetchVouchersVersionList, importVouchers} from "../../../../redux/actions/voucherActions";
import {FILE_TYPE_FORMAT, FILE_MAX_SIZE} from "../variables";
import "animate.css";
import Alert from "../../../../shared/components/common/Alert";
import LoadingElement from "../../../../shared/components/common/Loading";
import FileInput from "../../../../shared/components/form/FileInput";
import VoucherImportDataTable from "./DataTable";

class VoucherImport extends React.Component {
    state = {
        isLoading: false,
        partnerId: this.props.match.params.partnerId,
        partnerName: this.props.match.params.partnerName,
        vouchersImported: null
    };

    componentDidMount() {
        this.props.fetchVouchersVersionList(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        const {vouchersImported, message, vouchersDone, error} = this.props;

        prevProps.vouchersImported !== vouchersImported && this.setState({vouchersImported});
        prevProps.message !== message && message && prevProps.vouchersDone !== vouchersDone && this.renderNotification('success');
        prevProps.error !== error && error && this.renderNotification('danger');
    }

    renderNotification(type) {
        const {t, error} = this.props;
        store.addNotification({
            title: t(`notification:${type}->title`),
            message: type === 'success' ? t(`notification:${type}->message`) : t(`notification:${type}->message`, {error}),
            type: type,
            insert: "bottom",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
                duration: 3000,
                showIcon: true
            }
        });
    }

    onSubmit = async formValues => {
        this.setState({isLoading: true});
        const file = formValues.file.file;
        let data = new FormData();

        data.append('file', file, file.name);
        data.append('voucherCampaignId', this.props.match.params.id);
        await this.props.importVouchers(data);

        this.props.reset();
        this.setState({isLoading: false});
        this.componentDidMount();
    };

    renderTableHeader() {
        const {t} = this.props;
        return (
            <div className='card__title'>
                <h5 className='bold-text'>{t("voucherImport->tableTitle")}</h5>
            </div>
        );
    }

    renderTable() {
        const {t, theme, vouchers} = this.props;

        return (
            <>
                {this.renderTableHeader()}
                <VoucherImportDataTable
                    theme={theme}
                    data={vouchers}
                    noHeader
                    t={t}
                />
            </>
        )
    }

    renderImportVoucher() {
        const {t} = this.props;
        const {vouchersImported} = this.state;
        let countError = 0;

        if (vouchersImported) {
            vouchersImported.voucherCodeImportResult.map(voucher => voucher.isError && countError++);
        }

        return (
            <>
                <div className='card__title'>
                    <h5 className='bold-text'>{t('voucherImport->formTitle')}</h5>
                </div>
                <Field
                    name="file"
                    id="file"
                    component={FileInput}
                    label={t('voucherImport->labelFile')}
                    types={FILE_TYPE_FORMAT}
                    maxSize={FILE_MAX_SIZE}
                    validate={required}
                />
                {
                    vouchersImported &&
                    <>
                        <p className="bold-text">{t('voucherImport->tableColumnTotalVouchers')}: {vouchersImported.totalImported}</p>
                        <p className="bold-text text-success">{t('voucherImport->labelImportedVouchersSuccess')}: {(vouchersImported.totalImported - countError)}</p>
                        <p className="bold-text text-danger">{t('voucherImport->labelImportedVouchersError')}: {countError}</p>
                    </>
                }
            </>
        );
    }

    renderError(error) {
        const {t} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color="danger" className="alert--bordered" icon>
                            <p className="bold-text">{t('alert:danger->title')}</p>
                            <p className="mb-2">{t('alert:danger->message', {error})}</p>
                            <Button size="sm" type="button" className="mb-0"
                                    onClick={() => this.componentDidMount()}>{t('alert:danger->tryAgain')}</Button>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    }

    render() {
        const {t, vouchersDone, vouchersError, location} = this.props;
        const {partnerName, isLoading} = this.state;

        if (!vouchersDone || isLoading) {
            return <LoadingElement isLoading={true}/>;
        }

        if (vouchersError) {
            return this.renderError(vouchersError);
        }

        return (
            <Container>
                <form className="form d-block" encType="multipart/form-data"
                      onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <h3 className="page-title mb-4">{partnerName}</h3>
                    <Card>
                        <CardBody>
                            {this.renderImportVoucher()}
                        </CardBody>
                    </Card>
                    <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" type="submit">{t('voucherImport->btnSubmit')}</Button>
                        <Link className="btn btn-secondary" to={location.pathname.split('/import-vouchers')[0]}>
                            {t('voucherForm->btnCancel')}
                        </Link>
                    </ButtonToolbar>
                    <Card>
                        <CardBody>
                            {this.renderTable()}
                        </CardBody>
                    </Card>
                </form>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        vouchers: Object.values(state.vouchers.data.vouchers),
        totalItems: state.vouchers.data.totalItems,
        vouchersDone: state.vouchers.done.status,
        vouchersError: state.vouchers.error,
        vouchersImported: state.vouchers.importedFile,
        message: state.vouchers.done.message,
        theme: state.theme.className
    };
};

const translationWrapped = withTranslation(['voucher', 'common', 'modal', 'notification'])(VoucherImport);

const formWrapped = reduxForm({
    form: 'voucherForm',
})(translationWrapped);

export default connect(mapStateToProps, {fetchVouchersVersionList, importVouchers})(formWrapped);