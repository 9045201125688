import React, {PureComponent} from 'react';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import {isMobileOnly} from 'react-device-detect';
import PropTypes from 'prop-types';
import TimetableIcon from 'mdi-react/TimetableIcon';
import AvTimerIcon from 'mdi-react/AvTimerIcon';
import {useTranslation} from 'react-i18next';
import pt from 'date-fns/locale/pt';

registerLocale('pt', pt);
setDefaultLocale('pt');

//falta linguas
class DateTimePickerField extends PureComponent {
    static propTypes = {
        onChange: PropTypes.func.isRequired,
        minDate: PropTypes.instanceOf(Date),
        disabled: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            startDate: props.startDate || null,
        };
    }

    componentDidMount() {
        const {startDate} = this.props;
        startDate && this.handleChange(startDate);
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.startDate !== this.props.startDate && this.props.startDate === null) {
            this.handleChange(null);
        }
    }

    handleChange = (date) => {
        const {onChange} = this.props;
        
        this.setState({
            startDate: date,
        });
        onChange(date);
    };

    render() {
        const {startDate} = this.state;
        const {timeCaption, showTimeSelect, showTimeSelectOnly, dateFormat, minDate, disabled} = this.props;
        
        return (
            <div className="date-picker">
                <DatePicker
                    timeFormat="HH:mm"
                    className="form__form-group-datepicker"
                    selected={startDate}
                    onChange={this.handleChange}
                    showTimeSelect={showTimeSelect}
                    showTimeSelectOnly={showTimeSelectOnly}
                    dateFormat={dateFormat}
                    timeIntervals={15}
                    locale="pt"
                    timeCaption={timeCaption}
                    dropDownMode="select"
                    withPortal={isMobileOnly}
                    minDate={minDate || null}
                    disabled={disabled}
                />
            </div>
        );
    }
}

const DateTimePickerElement = (props) => {
    const {input, meta: {touched, error, submitFailed}, className, showTimeSelect, showTimeSelectOnly, dateFormat, startDate, minDate, label, disabled} = props;
    const {t} = useTranslation(['common', 'validation']);

    return (
        <div className={`form__form-group ${className}`}>
            <span className="form__form-group-label">{label}</span>
            <div className="form__form-group-field">
                <DateTimePickerField
                    {...input}
                    showTimeSelect={showTimeSelect}
                    showTimeSelectOnly={showTimeSelectOnly}
                    dateFormat={dateFormat}
                    timeCaption={t('labelDatePickerTimeCaption')}
                    startDate={startDate}
                    minDate={minDate}
                    disabled={disabled}
                />
                <div className="form__form-group-icon">
                    {showTimeSelectOnly ?
                        <AvTimerIcon /> 
                    :
                        <TimetableIcon/>
                    }
                </div>
            </div>
            {touched && error && submitFailed &&
            <span className="form__form-group-error">{t(`validation:validate->${error}`)}</span>}
        </div>
    );
};

DateTimePickerElement.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    minDate: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
    className: PropTypes.string
};


DateTimePickerElement.defaultProps = {
    className: ''
};

export default DateTimePickerElement;
