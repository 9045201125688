import React from "react";
import {compose} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import ServiceCatForm from "./LocationForm";
import {fetchLanguages} from "../../../redux/actions/themeActions";
import {fetchOptions, removeOptions} from "../../../redux/actions/selectOptionActions";
import {fetchLocation, editLocation, deleteLocation} from "../../../redux/actions/locationActions";
import {store} from "react-notifications-component";
import {Container, Card, CardBody} from "reactstrap";
import Alert from "../../../shared/components/common/Alert";
import {Link} from "react-router-dom";

class ServiceCatEdit extends React.Component {
    componentDidMount() {
        this.props.fetchOptions('/ServiceTypes/GetAll', {headers: {language: 'pt-PT'}});
        this.props.fetchLocation(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message && this.props.message) {
            store.addNotification({
                title: this.props.t("notification:success->title"),
                message: this.props.t("notification:success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.removeOptions();
    }

    onSubmit = formValues => {
        this.props.editLocation(formValues);
    };

    onDelete = id => {
        this.props.deleteLocation(id, "form");
    };

    renderError = () => {
        const {t, locationError, serviceTypesOptionsError, languagesError} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color='danger' className='alert--bordered' icon>
                            <>
                                <p className='bold-text'>{t("alert:danger->title")}</p>
                                <p>{t("alert:danger->message", {error: locationError || serviceTypesOptionsError || languagesError})}</p>
                                <Link to='/vvservices/location'>{t("alert:danger->goBack")}</Link>
                            </>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    };

    render() {
        const {
            t,
            locationDone,
            locationError,
            location,
            serviceTypesOptions,
            serviceTypesOptionsDone,
            serviceTypesOptionsError,
            languages,
            languagesDone,
            languagesError
        } = this.props;

        if (!locationDone || !languagesDone || !serviceTypesOptionsDone) {
            return (
                <div className='d-flex justify-content-center'>
                <div>
                  <img
                    width='100'
                    height='100'
                    src={`${process.env.PUBLIC_URL}/img/load.gif`}
                    alt='Loading'
                  />
                </div>
              </div>
            );
        }

        if (locationError || serviceTypesOptionsError || languagesError) {
            return this.renderError();
        }
        
        let locationCopy = {...location};
        serviceTypesOptions.map(item => {
            if (parseInt(item.key) === location.serviceTypeId) {
                locationCopy.serviceTypeId = {
                    label: item.value, value: item.key, type: parseInt(item.type)
                };
            }
        })
        if (location.freewayId) {
            locationCopy.freewayId = {
                label: '',
                value: `${location.freewayId}`
            }
        }
        
        return (
            <ServiceCatForm
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                languages={languages}
                serviceTypesOptions={Object.values(serviceTypesOptions)}
                type='edit'
                btnText={t('locationForm->btnSave')}
                initialValues={locationCopy}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        serviceTypesOptions: state.selectOptions.data,
        serviceTypesOptionsDone: state.selectOptions.done,
        serviceTypesOptionsError: state.selectOptions.error,
        location: state.locations.data.locations[ownProps.match.params.id],
        locationDone: state.locations.done.status,
        locationError: state.locations.error,
        languages: state.theme.data,
        languagesDone: state.theme.done.status,
        languagesError: state.theme.error
    };
};
export default compose(
    withTranslation(["location", "notification", "alert"]),
    connect(mapStateToProps, {
        fetchLocation,
        editLocation,
        deleteLocation,
        fetchLanguages,
        fetchOptions,
        removeOptions
    })
)(ServiceCatEdit);
