import React from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Button, ButtonToolbar, ButtonGroup, UncontrolledTooltip, Modal} from 'reactstrap';
import 'animate.css';
import './VoucherPreview.scss';
import axios from 'axios';
import moment from 'moment';
import DataTableServerElement from '../../../../shared/components/common/DataTableServer';
import Moment from 'react-moment';
import ModalConfirmation from '../../../../shared/components/common/ModalConfirmation';
import CheckIcon from 'mdi-react/CheckIcon';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import PencilOutlineIcon from 'mdi-react/PencilOutlineIcon';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import UploadIcon from 'mdi-react/UploadIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import CloseIcon from 'mdi-react/CloseIcon';
import MonitorIcon from 'mdi-react/MonitorIcon';
import MonitorDashboardIcon from 'mdi-react/MonitorDashboardIcon';
import CellphoneIcon from 'mdi-react/CellphoneIcon';
import CellphoneTextIcon from 'mdi-react/CellphoneTextIcon';
import topSideImg from '../../../../shared/img/voucher-preview/top-side.png';
import bottomSideImg from '../../../../shared/img/voucher-preview/bottom-side.png';
import downloadImg from '../../../../shared/img/voucher-preview/download.svg';
import redAlertImg from '../../../../shared/img/voucher-preview/red-alert.svg';
import topSideMobileImg from '../../../../shared/img/voucher-preview/mobile-top-side.png';
import bottomSideMobileImg from '../../../../shared/img/voucher-preview/mobile-bottom-side.png';
import {
    DataTableFiltersCampaignName,
    DataTableFiltersBenefitType,
    DataTableFiltersTimePeriod,
    DataTableFiltersDateTime,
    DataTableFiltersHighlighted,
    DataTableFiltersActive
} from './DataTableFilters';
import LoadingElement from '../../../../shared/components/common/Loading';

class VoucherCampaignsListDataTable extends React.Component {
    state = {
        showFinished: false,
        showPreviewModal: false,
        previewData: null,
        partnerLogo: null,
        isLoading: false,
        isDesktop: true,
    };

    handlePageChange = (currentPage, pageSize) => {
        this.props.handlePageChange(currentPage, pageSize);
    };

    handlePerRowsChange = (currentPage, pageSize) => {
        this.props.handlePageChange(currentPage, pageSize);
    };

    handleDataSort = (column, order) => {
        const {orderColumns} = this.props;
        let sortColumn = null;

        if (orderColumns.some(orderColumn => orderColumn.name.toLowerCase() === column.selector.toLowerCase() && (sortColumn = orderColumn))) {
            this.props.handleDataSort(sortColumn, order);
        }
    }

    handleFilterSubmit = formValues => {
        this.props.handleFilterData(formValues.filter);
    };

    handleFilterClear = () => {
        this.props.handleFilterClear();
    };

    toggleModal = () => {
        this.setState(prevState => ({modal: !prevState.modal}));
    };

    fetchPreviewData = async id => {
        const {partnerId} = this.props;
        this.setState({isLoading: true});
        try {
            const response = await axios.get(`/VoucherCampaigns/GetById/${id}`);
            if (response) {
                this.setState({previewData: response.data});
            }
        } catch (e) {
            console.log(e)
        }
        try {
            const response = await axios.get(`/Partners/GetByGuid/${partnerId}`);
            if (response) {
                this.setState({partnerLogo: response.data.partnerImage});
            }
        } catch (e) {
            console.log(e)
        }
        this.toggleModal();
        this.setState({isLoading: false});
    }

    renderPreviewModal() {
        const {t, theme} = this.props;
        const {modal, previewData, partnerLogo, isDesktop} = this.state;

        return (
            <Modal
                isOpen={modal}
                toggle={this.toggleModal}
                backdrop="static"
                modalClassName="ltr-support"
                className={`modal-dialog--primary ${theme}`}
            >
                {
                    (previewData && partnerLogo) &&
                    <>
                        <div className="modal__header">
                            <button className="modal__close-btn" type="button" onClick={this.toggleModal}>
                                <CloseIcon/></button>
                            <h4 className="text-modal  modal__title">{t('voucherCampaignList->modalPreviewTitle')}</h4>
                        </div>
                        <div className="modal__body">
                            <p className="text-left mb-2">
                                    <span
                                        className="bold-text">{t('voucherCampaignList->modalPreviewNote')}</span> {t('voucherCampaignList->modalPreviewNoteLead')}
                            </p>
                            <div
                                className="d-flex justify-content-around align-items-center voucher-preview__buttons mb-2">
                                <button type="button" onClick={() => this.setState({isDesktop: true})}>
                                    {
                                        isDesktop ?
                                            <MonitorDashboardIcon size={56}/>
                                            :
                                            <MonitorIcon size={56}/>
                                    }
                                    <span
                                        className={`d-block ${isDesktop ? 'text-success' : ''}`}>{t('voucherCampaignList->modalPreviewBtnDesktop')}</span>
                                </button>
                                <button type="button" onClick={() => this.setState({isDesktop: false})}>
                                    {
                                        isDesktop ?
                                            <CellphoneIcon size={56}/>
                                            :
                                            <CellphoneTextIcon size={56}/>
                                    }
                                    <span
                                        className={`d-block ${isDesktop ? '' : 'text-success'}`}>{t('voucherCampaignList->modalPreviewBtnApp')}</span>
                                </button>
                            </div>
                            <div className={`voucher-preview__desktop ${isDesktop ? 'd-block' : 'd-none'}`}>
                                <img src={topSideImg}
                                     alt="preview top side"/>
                                <div className="voucher-preview__desktop-wrapper">
                                    <div className="my-voucher">
                                        <Row>
                                            <Col xs={9} className="bg-grey my-voucher-content px-0">
                                                {
                                                    previewData.voucherCampaignLayoutTranslation[0].image &&
                                                    <div className="my-voucher-bg">
                                                        <img
                                                            src={previewData.voucherCampaignLayoutTranslation[0].image.format + ',' + previewData.voucherCampaignLayoutTranslation[0].image.imageData}
                                                            alt="preview"/>
                                                    </div>
                                                }
                                                <Row>
                                                    <Col xs={8}>
                                                        <div className="text-left mx-4 my-3">
                                                            <div className="mb-3">
                                                                <p className={previewData.voucherCampaignLayoutTranslation[0].image ? 'text-white' : 'aluminium'}>{t('voucherCampaignList->myVoucherLabelVoucher')}</p>
                                                                <h4 className={previewData.voucherCampaignLayoutTranslation[0].image ? 'text-white' : 'green'}>
                                                                    <strong>{previewData.voucherCampaignLayoutTranslation[0].title}</strong>
                                                                </h4>
                                                            </div>
                                                            {
                                                                previewData.campaignOfferType === 2 &&
                                                                <div className="mb-2">
                                                                    <p className={previewData.voucherCampaignLayoutTranslation[0].image ? 'text-white' : 'green'}>{t('voucherCampaignList->myVoucherLabelForOnly')}</p>
                                                                    <h3 className={`d-inline ${previewData.voucherCampaignLayoutTranslation[0].image ? 'text-white' : 'green'}`}>
                                                                        <strong>{t('voucherCampaignList->myVoucherLabelNumberOfPoints')}</strong>
                                                                    </h3>
                                                                    <p className={`d-inline ml-2 ${previewData.voucherCampaignLayoutTranslation[0].image ? 'text-white' : 'bright-grey'}`}>{t('voucherCampaignList->myVoucherLabelYouHave')}
                                                                        <span
                                                                            className={!previewData.voucherCampaignLayoutTranslation[0].image ? 'green' : ''}><strong>{t('voucherCampaignList->myVoucherLabelMyPoints')}</strong> {t('voucherCampaignList->myVoucherLabelPoints')}</span> {t('voucherCampaignList->myVoucherLabelAvailable')}
                                                                    </p>
                                                                </div>
                                                            }
                                                            <div>
                                                                <div
                                                                    className={`my-voucher-conditions mb-2 ${previewData.voucherCampaignLayoutTranslation[0].image ? 'text-white' : 'bright-grey'}`}>
                                                                    {previewData.voucherCampaignLayoutTranslation[0].description}
                                                                </div>
                                                                {
                                                                    previewData.daysUntilUse && (previewData.daysUntilUse > moment(previewData.endDate).diff(moment(), 'days', true)) &&
                                                                    <div className="mb-1 white">
                                                                        <img className="red-alert mr-1"
                                                                             src={redAlertImg}
                                                                             alt="alert"/>
                                                                        <span
                                                                            className={previewData.voucherCampaignLayoutTranslation[0].image ? 'text-white' : 'bright-grey'}>
                                                                                {t('voucherCampaignList->myVoucherLabelDaysUntilUse', {count: Math.ceil(moment(previewData.endDate).diff(moment(), 'days', true))})}
                                                                            </span>
                                                                    </div>
                                                                }
                                                                {
                                                                    previewData.endDate &&
                                                                    <p className={`mt-0 ${previewData.voucherCampaignLayoutTranslation[0].image ? 'text-white' : 'aluminium'}`}>{t('voucherCampaignList->labelMyVoucherValidUntil')} {
                                                                        <Moment format="DD/MM/YYYY"
                                                                                date={previewData.endDate}/>}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col xs={4} className="my-voucher-logo">
                                                        <div className="h-100">
                                                            <div className="logo align-self-center">
                                                                <img
                                                                    src={partnerLogo.format + ',' + partnerLogo.imageData}
                                                                    alt="logo"/>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={3} className="bg-green d-flex justify-content-center">
                                                <div className="align-self-center">
                                                    <button type="button"
                                                            className="button button-border border-white justify-content-center">
                                                            <span className="bold-text">
                                                                {previewData.campaignOfferType === 1 ? t('voucherCampaignList->myVoucherLabelVoucher') : t('voucherCampaignList->myVoucherLabelPointsExchange')}
                                                            </span>
                                                        <img
                                                            className="ml-2"
                                                            src={downloadImg}
                                                            alt="download"/>
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <img src={bottomSideImg}
                                     alt="preview bottom side"/>
                            </div>
                            <div className={`voucher-preview__app ${!isDesktop ? 'd-block' : 'd-none'}`}>
                                <img
                                    src={topSideMobileImg}
                                    alt="preview top side"/>
                                <div className="voucher-preview__app-wrapper">
                                    <div className="my-voucher-app">
                                        <div className="bg-green my-voucher-content px-0">
                                            {
                                                previewData.voucherCampaignLayoutTranslation[0].image &&
                                                <div className="my-voucher-app-bg">
                                                    <img
                                                        src={previewData.voucherCampaignLayoutTranslation[0].image.format + ',' + previewData.voucherCampaignLayoutTranslation[0].image.imageData}
                                                        alt="preview"/>
                                                </div>
                                            }
                                            <Row>
                                                <Col xs={4} className="my-voucher-app-logo">
                                                    <div className="logo">
                                                        <img
                                                            src={partnerLogo.format + ',' + partnerLogo.imageData}
                                                            alt="logo"/>
                                                    </div>
                                                </Col>
                                                <Col xs={8}>
                                                    <div className="text-left mx-0 my-3">
                                                        <div className="mb-3">
                                                            <p className="text-white">{t('voucherCampaignList->myVoucherLabelVoucher')}</p>
                                                            <h4 className="text-white">
                                                                <strong>{previewData.voucherCampaignLayoutTranslation[0].title}</strong>
                                                            </h4>
                                                        </div>
                                                        {
                                                            previewData.campaignOfferType === 2 &&
                                                            <div className="mb-2">
                                                                <p className="text-white">{t('voucherCampaignList->myVoucherLabelForOnly')}</p>
                                                                <h4 className="d-inline text-white">
                                                                    <strong>{t('voucherCampaignList->myVoucherLabelNumberOfPoints')}</strong>
                                                                </h4>
                                                            </div>
                                                        }
                                                        <div>
                                                            {
                                                                previewData.daysUntilUse && (previewData.daysUntilUse > moment(previewData.endDate).diff(moment(), 'days', true)) &&
                                                                <div className="mb-1 white">
                                                                    <img className="red-alert mr-1"
                                                                         src={redAlertImg}
                                                                         alt="alert"/>
                                                                    <span className="text-white">
                                                                            {t('voucherCampaignList->myVoucherLabelDaysUntilUse', {count: Math.ceil(moment(previewData.endDate).diff(moment(), 'days', true))})}
                                                                        </span>
                                                                </div>
                                                            }
                                                            {
                                                                previewData.endDate &&
                                                                <p className="mt-0 text-white">{t('voucherCampaignList->labelMyVoucherValidUntil')} {
                                                                    <Moment format="DD/MM/YYYY"
                                                                            date={previewData.endDate}/>}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src={bottomSideMobileImg}
                                    alt="preview top side"/>
                            </div>

                        </div>
                    </>
                }
                <ButtonToolbar className="modal__footer">
                    <Button className="modal_cancel" onClick={this.toggleModal}>Voltar</Button>
                </ButtonToolbar>
            </Modal>
        );
    }

    renderTableColumns() {
        const {t, location, isEnabledFilterOptions, filterBenefitTypeOptions, daysHoursFilterOptions, filterHighlightedOptions, beginDate, endDate} = this.props;

        return [
            {
                name: t('voucherCampaignList->tableColumnName'),
                selector: 'name',
                sortable: true,
                minWidth: '300px',
                cell: row => (
                    row.id === -1 ?
                        <DataTableFiltersCampaignName/>
                        :
                        row.isImported && !row.hasImportedVouchers ?
                            <>
                                <span>
                                    <AlertCircleOutlineIcon className="mr-1" id={`tooltip-${row.id}`}/>
                                </span>{row.name}
                                <UncontrolledTooltip placement="top" target={`tooltip-${row.id}`}>
                                    {t('voucherCampaignList->labelNoImportedVouchers')}
                                </UncontrolledTooltip>
                            </>
                            :
                            row.name
                )
            },
            {
                name: t('voucherCampaignList->tableColumnType'),
                selector: 'campaignBenefitType',
                sortable: true,
                cell: row => (
                    row.id === -1 ?
                        <DataTableFiltersBenefitType filterBenefitTypeOptions={filterBenefitTypeOptions}/>
                        :
                        t(`common->labelBenefitType${row.campaignBenefitTypeLabel}`)
                )
            },
            {
                name: t('voucherCampaignList->tableColumnTimePeriod'),
                selector: 'endDate',
                cell: row => (
                    row.id === -1 ?
                        <DataTableFiltersTimePeriod beginDate={beginDate} endDate={endDate}/>
                        :
                        row.beginDate ? <><Moment format="DD/MM/YYYY" date={row.beginDate}/>&nbsp;>&nbsp;
                            {row.endDate ? <Moment format="DD/MM/YYYY" date={row.endDate}/> : <span>&infin;</span>}
                        </> : t('common:labelNotAvailable')
                )
            },
            {
                name: t('voucherCampaignList->tableColumnDateTime'),
                selector: 'beginTime',
                cell: row => {
                    if (row.id === -1) {
                        return (
                            <DataTableFiltersDateTime t={t} daysHoursFilterOptions={daysHoursFilterOptions}/>
                        );
                    }

                    const days = [];
                    let countDaysOpened = 0, countDays = 0, count = 0, textDays = '';

                    days.push({name: t('common->labelMonday'), value: row.monday});
                    days.push({name: t('common->labelTuesday'), value: row.tuesday});
                    days.push({name: t('common->labelWednesday'), value: row.wednesday});
                    days.push({name: t('common->labelThursday'), value: row.thursday});
                    days.push({name: t('common->labelFriday'), value: row.friday});
                    days.push({name: t('common->labelSaturday'), value: row.saturday});
                    days.push({name: t('common->labelSunday'), value: row.sunday});
                    days.map(day => day.value && countDaysOpened++);

                    return countDaysOpened > 0 ? countDaysOpened < days.length ? days.map((day, i) => {
                            if (countDays < 2 && day.value) {
                                textDays += (day.name + (countDays < 1 ? ',' : ''));
                                countDays++;
                            } else if (countDays >= 2 && day.value) {
                                count++;
                            }
                            return true;
                        }) && (textDays += (count > 0 ? ' + ' + count : '')) && (row.beginTime && row.endTime ?
                        <div>
                            {textDays}
                            <div>
                                <Moment format="HH:mm" date={row.beginTime}/>&nbsp;>&nbsp;
                                <Moment format="HH:mm" date={row.endTime}/>
                            </div>
                        </div>
                        : t('common:labelNotAvailable')) :
                        <div>
                            {t('common->labelAlways')}
                            <div>
                                <Moment format="HH:mm" date={row.beginTime}/>&nbsp;>&nbsp;
                                <Moment format="HH:mm" date={row.endTime}/>
                            </div>
                        </div>
                        : t('common:labelNotAvailable');
                }
            },
            {
                name: t('voucherCampaignList->tableColumnPlace'),
                selector: 'campaignPointsOfSaleCount',
                cell: row => (
                    row.id === -1 ?
                        null
                        :
                        row.campaignPointsOfSaleLabel ? (row.campaignPointsOfSaleCount - 2) > 0 ? row.campaignPointsOfSaleLabel + ' + ' + (row.campaignPointsOfSaleCount - 2) : row.campaignPointsOfSaleLabel : row.campaignPointsOfSaleCount > 0 ? row.campaignPointsOfSaleCount === row.partnerPointsOfSaleCount ? t('common:labelAll') : t('common:labelNotAvailable') : t('common:labelAll')
                )
            },
            {
                name: t('voucherCampaignList->tableColumnHighlighted'),
                selector: 'highlighted',
                sortable: true,
                maxWidth: '120px',
                cell: row => (
                    row.id === -1 ?
                        <DataTableFiltersHighlighted filterHighlightedOptions={filterHighlightedOptions}/>
                        :
                        row.highlighted ? t('common:filters->labelSelectEnabled') : t('common:filters->labelSelectNotEnabled')
                )
            },
            {
                name: t('voucherCampaignList->tableColumnActive'),
                sortable: true,
                button: true,
                selector: 'active',
                maxWidth: '120px',
                cell: row => (
                    row.id === -1 ?
                        <DataTableFiltersActive isEnabledFilterOptions={isEnabledFilterOptions}/>
                        :
                        <label className="toggle-btn customizer__toggle" htmlFor={`toggle_${row.id}`}>
                            <input
                                className="toggle-btn__input"
                                type="checkbox"
                                name={`toggle_${row.id}`}
                                id={`toggle_${row.id}`}
                                checked={row.isEnabled}
                                value={row}
                                onChange={() => this.props.handleToggle(row)}
                                disabled={row.endDate && new Date().getTime() >= new Date(row.endDate).getTime()}
                            />
                            <span className="toggle-btn__input-label"/>
                        </label>
                )
            },
            {
                button: true,
                minWidth: '200px',
                cell: row => {
                    if (row.id === -1) {
                        return (
                            <ButtonToolbar>
                                <ButtonGroup className="btn-group--icons" dir="ltr">
                                    <Button
                                        outline
                                        onClick={this.handleFilterData}
                                        title={t('common:buttonTitleAttributes->filter')}>
                                        <MagnifyIcon/>
                                    </Button>
                                    <Button
                                        outline
                                        color="danger"
                                        onClick={this.handleFilterClear}
                                        title={t('common:buttonTitleAttributes->clear')}>
                                        <CloseIcon/>
                                    </Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        )
                    }

                    return (
                        <ButtonToolbar>
                            <ButtonGroup className="btn-group--icons" dir="ltr">
                                <Link
                                    to={`${location.pathname}/edit/${row.id}`}
                                    className="btn btn-outline-secondary"
                                    title={t('common:buttonTitleAttributes->edit')}
                                >
                                    <PencilOutlineIcon/>
                                </Link>
                                <Button
                                    outline
                                    onClick={() => this.fetchPreviewData(row.id)}
                                    title={t('common:buttonTitleAttributes->preview')}
                                >
                                    <EyeOutlineIcon/>
                                </Button>
                                {
                                    row.isImported &&
                                    <Link
                                        to={`${location.pathname}/import-vouchers/${row.id}`}
                                        className="btn btn-outline-secondary"
                                        title={t('common:buttonTitleAttributes->import')}
                                    >
                                        <UploadIcon/>
                                    </Link>
                                }
                                <ModalConfirmation
                                    backdrop="static"
                                    color="danger"
                                    outline
                                    firstBtnText={t('modal:buttonNo')}
                                    secondBtnText={t('modal:buttonYes')}
                                    title={t('modal:modalDeleteTitle')}
                                    btnTitle={t('common:buttonTitleAttributes->delete')}
                                    btn={<TrashCanOutlineIcon/>}
                                    message={<p>{t('modal:modalDeleteMessage')}</p>}
                                    handleOnClick={async () => await this.props.handleModalDelete(row.id)}
                                    disabled={new Date(row.beginDate) <= new Date() && (row.endDate ? new Date(row.endDate) >= new Date() : true) && row.isEnabled}
                                />
                            </ButtonGroup>
                        </ButtonToolbar>
                    );
                }
            }
        ];
    }

    render() {
        const {t, data, paginationTotalRows, theme, rowsPerPage, pageSize, currentPage, showFinished, defaultSortAsc, defaultSortField, paginationResetDefaultPage} = this.props;
        const {isLoading} = this.state;
        const conditionalRowStyles = [
            {
                when: row => row.endDate && (new Date(row.endDate) < new Date()),
                style: {
                    backgroundColor: "rgba(234, 238, 255, 0.6)!important",
                },
            },
            {
                when: row => row.isImported && !row.hasImportedVouchers,
                style: {
                    backgroundColor: "rgba(255, 72, 97, 0.4)!important",
                },
            }
        ];
        
        return (
            <form onSubmit={this.props.handleSubmit(this.handleFilterSubmit)}>
                <LoadingElement isLoading={isLoading}/>
                <div className="form__form-group">
                    <div className="form__form-group-field">
                        <label
                            className="checkbox-btn checkbox-btn--colored-click">
                            <input
                                className="checkbox-btn__checkbox"
                                type="checkbox"
                                id="isPermanent"
                                name="isPermanent"
                                checked={showFinished}
                                onChange={e => this.props.handleShowFinished(e.target.checked)}
                            />
                            <span className="checkbox-btn__checkbox-custom">
                                <CheckIcon/>
                            </span>
                            <span
                                className="checkbox-btn__label">{t('voucherCampaignList->labelFinishedCampaigns')}</span>
                        </label>
                    </div>
                </div>
                <div className="form react-datatable__filters">
                    <DataTableServerElement
                        theme={theme}
                        keyField="id"
                        columns={this.renderTableColumns()}
                        data={data}
                        noHeader
                        paginationServer
                        paginationTotalRows={paginationTotalRows}
                        handlePerRowsChange={this.handlePerRowsChange}
                        handlePageChange={this.handlePageChange}
                        paginationRowsPerPageOptions={rowsPerPage}
                        paginationPerPage={pageSize}
                        currentPage={currentPage}
                        receivePerRowsChange
                        withFilters
                        paginationResetDefaultPage={paginationResetDefaultPage}
                        sortServer
                        defaultSortField={defaultSortField}
                        defaultSortAsc={defaultSortAsc}
                        handleDataSort={this.handleDataSort}
                        conditionalRowStyles={conditionalRowStyles}
                        progressPending={this.props.progressPending}
                    />
                </div>
                {this.renderPreviewModal()}
            </form>
        )
    }
}

export default VoucherCampaignsListDataTable;