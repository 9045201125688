import React from "react";
import {connect} from "react-redux";
import {Button, Card, CardBody, Container} from "reactstrap";
import {fetchAllPointsOfSale} from "../../../redux/actions/posActions";
import {createVoucherCampaign} from "../../../redux/actions/voucherActions";
import {store} from "react-notifications-component";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import VoucherForm from "./VoucherForm/";
import Alert from "../../../shared/components/common/Alert";
import axios from "axios";
import LoadingElement from "../../../shared/components/common/Loading";

class VoucherCreate extends React.Component {
    state = {
        offerTypes: {
            data: [],
            done: false
        },
        benefitTypes: {
            data: [],
            done: false
        },
        tiers: {
            data: [],
            done: false
        },
        voucherCodeFormats: {
            data: [],
            done: false
        },
        barcodeFormats: {
            data: [],
            done: false
        }
    };

    async componentDidMount() {
        const partnerId = this.props.match.params.partnerId;
        this.props.fetchAllPointsOfSale(partnerId);
        await this.fetchTiers();
        await this.fetchCampaignBenefitType();
        await this.fetchCampaignOfferType();
        await this.fetchVoucherCodeFormat();
        await this.fetchBarcodeFormat();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message && this.props.message) {
            store.addNotification({
                title: this.props.t("notification:success->title"),
                message: this.props.t("notification:success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                },
            });
        }
    }

    async fetchCampaignBenefitType() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetCampaignBenefitType', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({benefitTypes: {data: response.data, done: true}});
            }
        } catch (e) {
            this.renderError(e);
        }
    };

    async fetchCampaignOfferType() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetCampaignOfferType', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({offerTypes: {data: response.data, done: true}});
            }
        } catch (e) {
            this.renderError(e);
        }
    };

    async fetchTiers() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetTiers', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({tiers: {data: response.data, done: true}});
            }
        } catch (e) {
            this.renderError(e);
        }
    };

    async fetchVoucherCodeFormat() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetVoucherCodeFormat', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({voucherCodeFormats: {data: response.data, done: true}});
            }
        } catch (e) {
            this.renderError(e);
        }
    };

    async fetchBarcodeFormat() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetBarCodeFormat', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({barcodeFormats: {data: response.data, done: true}});
            }
        } catch (e) {
            this.renderError(e);
        }
    };

    onSubmit = formValues => {
        this.props.createVoucherCampaign(formValues);
    };

    renderError = error => {
        const {t} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color='danger' className='alert--bordered' icon>
                            <>
                                <p className='bold-text'>{t("alert:danger->title")}</p>
                                <p>{t("alert:danger->message", {error})}</p>
                                <Button size="sm" type="button" className="mb-0"
                                        onClick={() => this.componentDidMount()}>{t('alert:danger->tryAgain')}</Button>
                            </>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    };

    render() {
        const {t, vouchersError, POS, POSDone, POSError} = this.props;
        const {benefitTypes, offerTypes, tiers, voucherCodeFormats, barcodeFormats} = this.state;

        if (!POSDone || !benefitTypes.done || !offerTypes.done || !tiers.done || !voucherCodeFormats.done || !barcodeFormats.done) {
            return <LoadingElement isLoading={true}/>;
        }

        if (POSError || vouchersError) {
            return this.renderError(POSError || vouchersError);
        }

        let beginDate = new Date(), beginTime = new Date(), endTime = new Date();
        beginDate.setHours(0,0,0);
        beginTime.setHours(0, 0, 0);
        endTime.setHours(23, 59, 0);

        return (
            <VoucherForm
                onSubmit={this.onSubmit}
                partnerName={this.props.match.params.partnerName}
                partnerId={this.props.match.params.partnerId}
                btnText={t("voucherForm->btnCreate")}
                benefitTypes={benefitTypes.data}
                offerTypes={offerTypes.data}
                POS={POS}
                tiers={tiers.data}
                voucherCodeFormats={voucherCodeFormats.data}
                barcodeFormats={barcodeFormats.data}
                initialValues={{
                    isEnabled: true,
                    isPreLoaded: false,
                    campaignOfferType: offerTypes.data[0].id,
                    beginDate,
                    voucherCampaignsActiveHours: {
                        beginTime,
                        endTime,
                        monday: true,
                        tuesday: true,
                        wednesday: true,
                        thursday: true,
                        friday: true,
                        saturday: true,
                        sunday: true
                    }
                }}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        vouchersError: state.vouchers.error,
        POS: Object.values(state.pointsOfSale.data.pointsOfSale),
        POSDone: state.pointsOfSale.done.status,
        POSError: state.pointsOfSale.error,
        message: state.vouchers.done.message
    };
};

export default compose(withTranslation(["voucher", "notification"]), connect(mapStateToProps, {
    fetchAllPointsOfSale, createVoucherCampaign
}))(VoucherCreate);
