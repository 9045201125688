import React from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { required } from "../../shared/validations";
import { signIn } from "../../redux/actions/authActions";
import { fetchLanguages } from "../../redux/actions/themeActions";
import { Button } from "reactstrap";
import InputElement from "../../shared/components/form/Input";
import vv_logo_dark from "../../shared/img/logo/vv_logo_dark.svg";
import vv_logo_light from "../../shared/img/logo/vv_logo_light.svg";

class LogInForm extends React.Component {
  componentDidMount() {
    if (!this.props.location.state) {
      this.props.fetchLanguages();
    }
  }

  onSubmit = (formValues) => {
    this.props.signIn(formValues, this.props.location.state);
  };

  render() {
    const { t, languagesDone, authError, theme, handleSubmit } = this.props;

    if (!this.props.location.state && !languagesDone) {
      return (
        <div>
          <img className='img-fluid' src={`${process.env.PUBLIC_URL}/img/load.gif`} alt='Loading' />
        </div>
      );
    }

    return (
      <div className='account'>
        <div className='account__wrapper'>
          <div className='account__card'>
            <div className='account__head'>
              {theme === "theme-dark" ? (
                <img className='img-fluid' src={vv_logo_dark} alt='Via Verde' />
              ) : (
                <img className='img-fluid' src={vv_logo_light} alt='Via Verde' />
              )}
            </div>
            <form
              className='form justify-content-center'
              onSubmit={handleSubmit(this.onSubmit)}
              noValidate
            >
              {authError && <span className='red-text my-4'>{t("errorLogin")}</span>}
              <Field
                id='username'
                name='username'
                label={t("labelUsername")}
                component={InputElement}
                type='text'
                validate={required}
              />
              <Field
                id='password'
                name='password'
                label={t("labelPassword")}
                component={InputElement}
                type='password'
                validate={required}
              />
              <Button type='submit' color='primary' className='account__btn account__btn--small'>
                {t("btnLogin")}
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languages: state.theme.data,
    languagesDone: state.theme.done.status,
    authError: state.auth.error,
    theme: state.theme.className,
    doneTheme: state.theme.done.status,
  };
};

const translationWrapped = withTranslation(["login"])(LogInForm);

const formWrapped = reduxForm({
  form: "logInForm",
})(translationWrapped);

export default connect(mapStateToProps, { signIn, fetchLanguages })(formWrapped);
