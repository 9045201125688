import React from 'react';
import {Col, Container, Row, Card, CardBody, Button} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {formValueSelector, reduxForm} from 'redux-form';
import {store} from "react-notifications-component";
import {fetchVoucherCampaigns} from '../../../../redux/actions/voucherActions';
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE} from '../variables';
import 'animate.css';
import Alert from '../../../../shared/components/common/Alert';
import VoucherCampaignsDataTable from './DataTable';
import LoadingElement from "../../../../shared/components/common/Loading";

class VoucherCampaigns extends React.Component {
    state = {
        data: [],
        isEnabledFilterOptions: [
            {value: '', label: this.props.t('common:filters->labelSelectAll')},
            {value: true, label: this.props.t('common:filters->labelSelectEnabled')},
            {value: false, label: this.props.t('common:filters->labelSelectNotEnabled')}
        ],
        rowsPerPage: ROWS_PER_PAGE,
        pageSize: PAGE_SIZE,
        currentPage: CURRENT_PAGE,
        filtered: false
    };
    
    componentDidMount() {
        this.handleFilterClear();
    }

    componentDidUpdate(prevProps) {
        const {vouchers, message, vouchersDone, error} = this.props;

        prevProps.vouchers !== vouchers && this.setState({data: vouchers});
        prevProps.message !== message && message && prevProps.vouchersDone !== vouchersDone && this.renderNotification('success');
        prevProps.error !== error && error && this.renderNotification('danger');
    }

    renderNotification(type) {
        const {t, error} = this.props;
        store.addNotification({
            title: t(`notification:${type}->title`),
            message: type === 'success' ? t(`notification:${type}->message`) : t(`notification:${type}->message`, {error}),
            type: type,
            insert: "bottom",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
                duration: 3000,
                showIcon: true
            }
        });
    }

    handlePageChange = (currentPage, pageSize) => {
        const {filtered} = this.state;
        const {filterName, filterHasActiveCampaigns, filterIsEnabled} = this.props;
        this.setState({currentPage, pageSize});
        let filters;
        filtered && (filters = {
            name: filterName,
            hasActiveCampaigns: filterHasActiveCampaigns.value,
            isEnabled: filterIsEnabled.value
        });
        this.props.fetchVoucherCampaigns(currentPage, pageSize, filters);
    };

    handleFilterData = () => {
        const {pageSize} = this.state;
        const {filterName, filterHasActiveCampaigns, filterIsEnabled} = this.props;
        
        this.setState({currentPage: CURRENT_PAGE, filtered: true});
        this.props.fetchVoucherCampaigns(CURRENT_PAGE, pageSize, {
            name: filterName,
            hasActiveCampaigns: filterHasActiveCampaigns.value,
            isEnabled: filterIsEnabled.value
        });
    }

    handleFilterClear = () => {
        const {pageSize, isEnabledFilterOptions} = this.state;
        this.props.change('filter', {
            name: '',
            hasActiveCampaigns: isEnabledFilterOptions[1],
            isEnabled: isEnabledFilterOptions[0]
        })
        this.setState({currentPage: CURRENT_PAGE, filtered: false});
        this.props.fetchVoucherCampaigns(CURRENT_PAGE, pageSize, {hasActiveCampaigns: true});
    }

    renderTableHeader() {
        const {t} = this.props;
        return (
            <div className='card__title'>
                <h5 className='bold-text'>{t("voucherCampaigns->tableTitle")}</h5>
            </div>
        );
    }

    renderTable() {
        const {data, rowsPerPage, pageSize, currentPage, isEnabledFilterOptions} = this.state;
        const {totalItems, theme} = this.props;
        
        return (
            <>
                {this.renderTableHeader()}
                <VoucherCampaignsDataTable
                    theme={theme}
                    data={data}
                    noHeader
                    paginationServer
                    paginationTotalRows={totalItems}
                    handlePerRowsChange={this.handlePerRowsChange}
                    handlePageChange={this.handlePageChange}
                    rowsPerPage={rowsPerPage}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    paginationDefaultPage={currentPage}
                    handleToggle={this.handleToggle}
                    handleFilterData={this.handleFilterData}
                    handleFilterClear={this.handleFilterClear}
                    isEnabledFilterOptions={isEnabledFilterOptions}
                />
            </>
        )
    }

    renderError(error) {
        const {t} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color="danger" className="alert--bordered" icon>
                            <p className="bold-text">{t('alert:danger->title')}</p>
                            <p className="mb-2">{t('alert:danger->message', {error})}</p>
                            <Button size="sm" type="button" className="mb-0" onClick={() => this.handleFilterData()}>{t('alert:danger->tryAgain')}</Button>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    }

    render() {
        const {t, vouchersDone, vouchersError} = this.props;
        
        if (!vouchersDone) {
            return <LoadingElement isLoading={true}/>;
        }
        
        if (vouchersError) {
            return this.renderError(vouchersError);
        }

        return (
            <Container>
                <Row>
                    <Col>
                        <h3 className="page-title mb-4">{t('voucherCampaigns->pageTitle')}</h3>
                        <Card>
                            <CardBody>
                                {this.renderTable()}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const selector = formValueSelector('voucherForm');

const mapStateToProps = (state) => {
    return {
        vouchers: Object.values(state.vouchers.data.vouchers),
        totalItems: state.vouchers.data.totalItems,
        vouchersDone: state.vouchers.done.status,
        vouchersError: state.vouchers.error,
        message: state.vouchers.done.message,
        theme: state.theme.className,
        filterName: selector(state, 'filter.name'),
        filterHasActiveCampaigns: selector(state, 'filter.hasActiveCampaigns'),
        filterIsEnabled: selector(state, 'filter.isEnabled')
    };
};

const translationWrapped = withTranslation(['voucher', 'common', 'modal', 'notification'])(VoucherCampaigns);

const formWrapped = reduxForm({
    form: 'voucherForm',
})(translationWrapped);

export default connect(mapStateToProps, {fetchVoucherCampaigns})(formWrapped);