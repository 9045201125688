import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Card, CardBody, ButtonToolbar, Button} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import {
    fetchVoucherCampaignList,
    toggleVoucherCampaign,
    deleteVoucherCampaign,
    exportVoucherCampaign
} from '../../../../redux/actions/voucherActions';
import {store} from "react-notifications-component";
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE} from '../variables';
import axios from "axios";
import {camelCase} from "lodash";
import Alert from '../../../../shared/components/common/Alert';
import VoucherCampaignListDataTable from './DataTable';
import LoadingElement from "../../../../shared/components/common/Loading";

class VoucherCampaignsList extends React.Component {
    constructor(props) {
        super(props);
        const {t} = props;

        this.state = {
            isLoading: false,
            isActionLoading: false,
            data: [],
            partnerName: props.match.params.partnerName,
            partnerId: props.match.params.partnerId,
            isEnabledFilterOptions: [
                {value: '', label: t('common->labelAll')},
                {value: true, label: t('common->labelYes')},
                {value: false, label: t('common->labelNo')}
            ],
            filterBenefitTypeOptions: [],
            timePeriodFilterOptions: [
                {value: '', label: t('common->labelAlways')},
                {value: true, label: t('common->labelYes')},
                {value: false, label: t('common->labelNo')}
            ],
            daysHoursFilterOptions: [
                {value: 'monday', label: t('common->labelMonday')},
                {value: 'tuesday', label: t('common->labelTuesday')},
                {value: 'wednesday', label: t('common->labelWednesday')},
                {value: 'thursday', label: t('common->labelThursday')},
                {value: 'friday', label: t('common->labelFriday')},
                {value: 'saturday', label: t('common->labelSaturday')},
                {value: 'sunday', label: t('common->labelSunday')}
            ],
            filterHighlightedOptions: [
                {value: '', label: t('common->labelAll')},
                {value: true, label: t('common->labelYes')},
                {value: false, label: t('common->labelNo')}
            ],
            showFinished: false,
            filterName: null,
            filterCampaignBenefitType: null,
            filterDateTime: null,
            filterBeginDate: null,
            filterEndDate: null,
            filterHighlighted: null,
            filterIsEnabled: null,
            rowsPerPage: ROWS_PER_PAGE,
            pageSize: PAGE_SIZE,
            currentPage: CURRENT_PAGE,
            paginationResetDefaultPage: false,
            defaultSortField: null,
            defaultSortAsc: true,
            orderColumns: [],
            orderColumn: null,
            orderDesc: false,
        };

        this.timer = null;
    }

    async componentDidMount() {
        this.setState({isLoading: true});
        await this.fetchCampaignBenefitType();
        await this.fetchOrderColumn();
        await this.handleFilterClear();
        this.setState({isLoading: false});
    }

    componentDidUpdate(prevProps) {
        const {vouchers, message, vouchersDone, error} = this.props;

        prevProps.vouchers !== vouchers && this.setState({data: vouchers});
        prevProps.message !== message && message && prevProps.done !== vouchersDone && this.renderNotification('success');
        prevProps.error !== error && error && this.renderNotification('danger');
    }

    async fetchCampaignBenefitType() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetCampaignBenefitType', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                const {t} = this.props;
                let benefitTypes;
                benefitTypes = response.data.map(benefitType => (
                    {
                        label: t(`common->labelBenefitType${benefitType.name}`),
                        value: benefitType.id,
                    }
                ));
                benefitTypes.unshift({label: t('common->labelAll'), value: ''});
                this.setState({filterBenefitTypeOptions: benefitTypes});
            }
        } catch (e) {
            this.renderError(e);
        }

    };

    async fetchOrderColumn() {
        try {
            const response = await axios.get('/VoucherCampaigns/GetOrderColumn', {
                headers: {language: localStorage.getItem('i18nextLng')},
            });
            if (response) {
                this.setState({orderColumns: response.data});
            }
        } catch (e) {
            this.renderError(e);
        }
    };

    renderNotification(type) {
        const {t, error} = this.props;
        store.addNotification({
            title: t(`notification:${type}->title`),
            message: type === 'success' ? t(`notification:${type}->message`) : t(`notification:${type}->message`, {error}),
            type: type,
            insert: "bottom",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
                duration: 3000,
                showIcon: true
            }
        });
    }

    handleToggle = async row => {
        const {partnerId, currentPage, pageSize, orderColumn, orderDesc, filterName, filterCampaignBenefitType, filterBeginDate, filterEndDate, filterDateTime, filterIsEnabled, filterIsHighlighted, showFinished} = this.state;

        this.setState({isActionLoading: true});
        await this.props.toggleVoucherCampaign(row);
        await this.props.fetchVoucherCampaignList(partnerId, currentPage, pageSize, {
            orderColumn,
            orderDesc,
            name: filterName,
            campaignBenefitType: filterCampaignBenefitType,
            beginDate: filterBeginDate,
            endDate: filterEndDate,
            dateTime: filterDateTime,
            highlighted: filterIsHighlighted,
            isEnabled: filterIsEnabled,
            showFinished
        });
        this.setState({isActionLoading: false});
    };

    handleModalDelete = async id => {
        const {
            partnerId,
            orderColumn,
            orderDesc,
            showFinished,
            pageSize,
            currentPage,
            filterName,
            filterCampaignBenefitType,
            filterBeginDate,
            filterEndDate,
            filterDateTime,
            filterIsEnabled,
            filterIsHighlighted
        } = this.state;
        
        this.setState({isActionLoading: true});
        await this.props.deleteVoucherCampaign(id, 'list');
        await this.props.fetchVoucherCampaignList(partnerId, currentPage, pageSize, {
            orderColumn,
            orderDesc,
            name: filterName,
            campaignBenefitType: filterCampaignBenefitType,
            beginDate: filterBeginDate,
            endDate: filterEndDate,
            dateTime: filterDateTime,
            highlighted: filterIsHighlighted,
            isEnabled: filterIsEnabled,
            showFinished
        });
        this.setState({isActionLoading: false});
    };

    handlePageChange = (currentPage, pageSize) => {
        const {partnerId, orderColumn, orderDesc, showFinished, filterName, filterCampaignBenefitType, filterBeginDate, filterEndDate, filterIsEnabled, filterDateTime, filterIsHighlighted} = this.state;

        this.setState({isLoading: true, currentPage, pageSize});
        this.timer = setTimeout(async () => {
            await this.props.fetchVoucherCampaignList(partnerId, currentPage, pageSize, {
                orderColumn,
                orderDesc,
                name: filterName,
                campaignBenefitType: filterCampaignBenefitType,
                beginDate: filterBeginDate,
                endDate: filterEndDate,
                dateTime: filterDateTime,
                highlighted: filterIsHighlighted,
                isEnabled: filterIsEnabled,
                showFinished
            });
            this.setState({isLoading: false});
        }, 0);
    };

    handleDataSort = async (sortColumn, order) => {
        const {partnerId, currentPage, pageSize, filterName, filterCampaignBenefitType, filterIsEnabled, filterIsHighlighted, beginDate, endDate, dateTime, showFinished} = this.state;
        //page change its called first than sort function
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.setState({
            isLoading: true,
            currentPage: CURRENT_PAGE,
            orderColumn: sortColumn.id,
            orderDesc: order === 'desc',
            defaultSortField: camelCase(sortColumn.name),
            defaultSortAsc: order === 'asc'
        });
        if (currentPage) {
            await this.props.fetchVoucherCampaignList(partnerId, CURRENT_PAGE, pageSize, {
                orderColumn: sortColumn.id,
                orderDesc: order === 'desc',
                name: filterName,
                campaignBenefitType: filterCampaignBenefitType,
                beginDate,
                endDate,
                dateTime,
                highlighted: filterIsHighlighted,
                isEnabled: filterIsEnabled,
                showFinished
            });
        }
        this.setState({isLoading: false});
    };

    handleFilterData = async filters => {
        const {showFinished, partnerId, currentPage, pageSize, orderColumn, orderDesc} = this.state;
        let dateTime = {}, newBeginDate, newEndDate;

        if (filters.dateTime && filters.dateTime.length) {
            filters.dateTime.map(item => dateTime[item.value] = true);
        }

        if (filters.beginDate) {
            newBeginDate = new Date(filters.beginDate);
            newBeginDate.setHours(0, 0, 0);
        }

        if (filters.endDate) {
            newEndDate = new Date(filters.endDate);
            newEndDate.setHours(23, 59, 59);
        }

        this.setState(prevState => ({
            currentPage: CURRENT_PAGE,
            paginationResetDefaultPage: !prevState.paginationResetDefaultPage,
            filtered: true,
            isLoading: true,
            filterName: filters.name ? filters.name.trim() : null,
            filterCampaignBenefitType: filters.campaignBenefitType ? filters.campaignBenefitType.value : null,
            filterDateTime: dateTime,
            filterBeginDate: newBeginDate,
            filterEndDate: newEndDate,
            filterIsHighlighted: filters.highlighted ? filters.highlighted.value : null,
            filterIsEnabled: filters.active ? filters.active.value : null
        }));
        if (currentPage === 1) {
            await this.props.fetchVoucherCampaignList(partnerId, CURRENT_PAGE, pageSize, {
                page: CURRENT_PAGE,
                pageSize,
                orderColumn,
                orderDesc,
                name: filters.name ? filters.name.trim() : null,
                campaignBenefitType: filters.campaignBenefitType ? filters.campaignBenefitType.value : null,
                beginDate: newBeginDate,
                endDate: newEndDate,
                dateTime,
                highlighted: filters.highlighted ? filters.highlighted.value : null,
                isEnabled: filters.active ? filters.active.value : null,
                showFinished
            });
        }
        this.setState({isLoading: false});
    };

    handleFilterClear = async () => {
        const {partnerId, orderColumn, orderDesc, currentPage, pageSize, filterBenefitTypeOptions, isEnabledFilterOptions, filterHighlightedOptions, showFinished} = this.state;
        this.setState(prevState => ({
            isLoading: true,
            currentPage: CURRENT_PAGE,
            paginationResetDefaultPage: !prevState.paginationResetDefaultPage,
            filtered: false,
            filterName: null,
            filterCampaignBenefitType: null,
            filterDateTime: null,
            filterBeginDate: null,
            filterEndDate: null,
            filterIsHighlighted: null,
            filterIsEnabled: null,
        }));
        this.props.change('filter', {
            campaignBenefitType: filterBenefitTypeOptions[0],
            highlighted: filterHighlightedOptions[0],
            active: isEnabledFilterOptions[0]
        });

        if (currentPage === 1) {
            await this.props.fetchVoucherCampaignList(partnerId, CURRENT_PAGE, pageSize, {
                orderColumn,
                orderDesc,
                showFinished
            });
        }
        this.setState({isLoading: false});

    };

    handleShowFinished = async checked => {
        const {partnerId, currentPage, pageSize, orderColumn, orderDesc, filterName, filterCampaignBenefitType, filterBeginDate, filterEndDate, filterIsEnabled, filterIsHighlighted} = this.state;

        this.setState(prevState => ({
            isLoading: true,
            showFinished: checked,
            paginationResetDefaultPage: !prevState.paginationResetDefaultPage,
        }));
        if (currentPage === 1) {
            await this.props.fetchVoucherCampaignList(partnerId, currentPage, pageSize, {
                orderColumn,
                orderDesc,
                name: filterName,
                campaignBenefitType: filterCampaignBenefitType,
                beginDate: filterBeginDate,
                endDate: filterEndDate,
                highlighted: filterIsHighlighted,
                isEnabled: filterIsEnabled,
                showFinished: checked
            });
        }
        this.setState({isLoading: false});
    };

    handleExport = async () => {
        const {partnerId, currentPage, pageSize, orderColumn, orderDesc, showFinished, filterName, filterCampaignBenefitType, filterBeginDate, filterEndDate, filterIsEnabled, filterIsHighlighted} = this.state;
        const filters = {
            orderColumn,
            orderDesc,
            name: filterName,
            campaignBenefitType: filterCampaignBenefitType,
            beginDate: filterBeginDate,
            endDate: filterEndDate,
            highlighted: filterIsHighlighted,
            isEnabled: filterIsEnabled,
            showFinished
        };

        this.setState({isActionLoading: true});
        await this.props.exportVoucherCampaign(partnerId, currentPage, pageSize, filters);
        this.setState({isActionLoading: false});
    };

    renderTableHeader() {
        const {t, location} = this.props;
        const {partnerName} = this.state;

        return (
            <div className='card__title'>
                <h5 className='bold-text'>{t("voucherCampaignList->tableTitle")} - {partnerName}</h5>
                <ButtonToolbar className='products-list__btn-toolbar-top'>
                    <Link className='btn btn-primary products-list__btn-add' to={`${location.pathname}/create`}>
                        {t("voucherCampaignList->createNew")}
                    </Link>
                    <Button type="button" color="success"
                            onClick={this.handleExport}>{t('voucherCampaignList->btnExport')}</Button>
                </ButtonToolbar>
            </div>
        );
    }

    renderTable() {
        const {
            isLoading,
            partnerId,
            data,
            rowsPerPage,
            pageSize,
            currentPage,
            showFinished,
            isEnabledFilterOptions,
            filterBenefitTypeOptions,
            daysHoursFilterOptions,
            filterHighlightedOptions,
            filterBeginDate,
            filterEndDate,
            orderColumns,
            defaultSortField,
            defaultSortAsc,
            paginationResetDefaultPage
        } = this.state;
        const {t, totalItems, theme, location} = this.props;

        return (
            <>
                {this.renderTableHeader()}
                <VoucherCampaignListDataTable
                    theme={theme}
                    t={t}
                    partnerId={partnerId}
                    data={data}
                    noHeader
                    paginationServer
                    paginationTotalRows={totalItems}
                    handlePerRowsChange={this.handlePerRowsChange}
                    handlePageChange={this.handlePageChange}
                    rowsPerPage={rowsPerPage}
                    pageSize={pageSize}
                    currentPage={currentPage}
                    withFilters
                    paginationDefaultPage={currentPage}
                    handleToggle={this.handleToggle}
                    handleFilterData={this.handleFilterData}
                    handleFilterClear={this.handleFilterClear}
                    handleShowFinished={this.handleShowFinished}
                    handleModalDelete={this.handleModalDelete}
                    paginationResetDefaultPage={paginationResetDefaultPage}
                    isEnabledFilterOptions={isEnabledFilterOptions}
                    filterBenefitTypeOptions={filterBenefitTypeOptions}
                    daysHoursFilterOptions={daysHoursFilterOptions}
                    filterHighlightedOptions={filterHighlightedOptions}
                    location={location}
                    showFinished={showFinished}
                    beginDate={filterBeginDate}
                    endDate={filterEndDate}
                    orderColumns={orderColumns}
                    handleDataSort={this.handleDataSort}
                    defaultSortField={defaultSortField}
                    defaultSortAsc={defaultSortAsc}
                    handleSubmit={this.props.handleSubmit}
                    progressPending={isLoading}
                />
            </>
        )
    }

    renderError(error) {
        const {t} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color="danger" className="alert--bordered" icon>
                            <p className="bold-text">{t('alert:danger->title')}</p>
                            <p>{t('alert:danger->message', {error})}</p>
                            <Button size="sm" type="button" className="mb-0"
                                    onClick={() => this.handleFilterData()}>{t('alert:danger->tryAgain')}</Button>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    }

    render() {
        const {isLoading, isActionLoading} = this.state;
        const {t, vouchersError} = this.props;

        if (vouchersError) {
            return this.renderError(vouchersError);
        }

        return (
            <Container>
                <LoadingElement isLoading={isLoading || isActionLoading}/>
                <h3 className="page-title mb-4">{t('voucherCampaignList->pageTitle')}</h3>
                <Card>
                    <CardBody>
                        {this.renderTable()}
                    </CardBody>
                </Card>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        vouchers: Object.values(state.vouchers.data.vouchers),
        totalItems: state.vouchers.data.totalItems,
        vouchersDone: state.vouchers.done.status,
        vouchersError: state.vouchers.error,
        message: state.vouchers.done.message,
        theme: state.theme.className
    };
};

const translationWrapped = withTranslation(['voucher', 'common', 'modal', 'notification'])(VoucherCampaignsList);

const formWrapped = reduxForm({
    form: 'voucherForm',
})(translationWrapped);

export default connect(mapStateToProps, {
    fetchVoucherCampaignList,
    toggleVoucherCampaign,
    deleteVoucherCampaign,
    exportVoucherCampaign
})(formWrapped);