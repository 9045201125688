import React from 'react';
import {Row, Col, Container, Card, CardBody, Button, ButtonToolbar} from 'reactstrap';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {store} from 'react-notifications-component';
import {
    fetchVoucherCampaigns,
    fetchVouchersList,
    listVouchersExport
} from '../../../../redux/actions/voucherActions';
import {
    fetchPartners
} from '../../../../redux/actions/partnerActions';
import {ROWS_PER_PAGE, CURRENT_PAGE, PAGE_SIZE} from '../variables';
import {greaterDate, required} from '../../../../shared/validations';
import 'animate.css';
import axios from 'axios';
import moment from 'moment';
import {camelCase} from 'lodash';
import Alert from '../../../../shared/components/common/Alert';
import VoucherMonitoringDataTable from './DataTable';
import LoadingElement from '../../../../shared/components/common/Loading';
import DateTimePickerElement from '../../../../shared/components/form/DateTimePicker';
import SelectElement from '../../../../shared/components/form/Select';
import HiddenElement from '../../../../shared/components/form/Hidden';

class VoucherMonitoring extends React.Component {
    state = {
        data: [],
        isLoading: false,
        isExporting: false,
        voucherStateOptions: [],
        rowsPerPage: ROWS_PER_PAGE,
        pageSize: PAGE_SIZE,
        currentPage: CURRENT_PAGE,
        paginationResetDefaultPage: false,
        filterCampaign: null,
        filterPartner: null,
        filterVoucherCode: null,
        filterNIF: null,
        filterState: null,
        filtered: false,
        searched: false,
        searchBeginDate: null,
        searchEndDate: null,
        partnerOptions: [],
        orderColumns: [],
        orderColumn: null,
        orderDesc: false,
        defaultSortField: null,
        defaultSortAsc: true,
    };

    timer = null;

    async componentDidMount() {
        this.setState({isLoading: true});
        try {
            const response = await axios.get('/VoucherCampaigns/GetVoucherState');
            if (response) {
                let voucherStateOptions = response.data.map(voucherState => (
                    {
                        label: this.props.t(`voucherMonitoring->labelVoucherState${voucherState.name}`),
                        value: voucherState.id,
                        id: voucherState.id
                    }
                ));
                voucherStateOptions.unshift({
                    label: this.props.t('voucherMonitoring->labelVoucherStateAll'),
                    value: '',
                    id: null
                });
                this.props.change('search', {state: voucherStateOptions[0]});
                this.props.change('filter', {state: voucherStateOptions[0]});
                this.setState({voucherStateOptions, filterState: voucherStateOptions[0], isLoading: false});
            }
        } catch (e) {
            this.renderError(e);
            this.setState({isLoading: false});
        }
        try {
            let response = await axios.get('/VoucherCampaigns/GetOrderColumnListVouchers');
            if (response) {
                this.setState({
                    orderColumns: response.data,
                    orderColumn: response.data[0].id,
                    defaultSortField: camelCase(response.data[0].name)
                });
            }
        } catch (e) {
            this.renderError(e);
            this.setState({isLoading: false});
        }
    }

    componentDidUpdate(prevProps) {
        const {vouchers, message, vouchersDone, error} = this.props;

        prevProps.vouchers !== vouchers && this.setState({data: vouchers});
        prevProps.message !== message && message && prevProps.vouchersDone !== vouchersDone && this.renderNotification('success');
        prevProps.error !== error && error && this.renderNotification('danger');
    }

    renderNotification(type) {
        const {t, error} = this.props;
        store.addNotification({
            title: t(`notification:${type}->title`),
            message: type === 'success' ? t(`notification:${type}->message`) : t(`notification:${type}->message`, {error}),
            type: type,
            insert: "bottom",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "zoomOut"],
            dismiss: {
                duration: 3000,
                showIcon: true
            }
        });
    }

    handlePageChange = async (currentPage, pageSize) => {
        const {filtered, searchBeginDate, searchEndDate, orderColumn, orderDesc, filterCampaign, filterPartner, filterVoucherCode, filterNIF, filterState} = this.state;

        this.setState({currentPage, pageSize, isLoading: true});
        this.timer = setTimeout(async () => {
            await this.props.fetchVouchersList({
                page: currentPage,
                pageSize: pageSize,
                orderColumn,
                orderDesc,
                beginDate: searchBeginDate,
                endDate: searchEndDate,
                campaign: filtered && filterCampaign ? filterCampaign : null,
                partnerId: filtered && filterPartner ? filterPartner : null,
                voucherCode: filtered ? filterVoucherCode : null,
                nif: filtered ? filterNIF : null,
                state: filterState.id
            });
            this.setState({isLoading: false});
        }, 0);
    };

    handleDataSort = async (sortColumn, order) => {
        const {currentPage, pageSize, filtered, searchBeginDate, searchEndDate, filterCampaign, filterPartner, filterVoucherCode, filterNIF, filterState} = this.state;
        //page change its called first than sort function
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.setState({
            isLoading: true,
            currentPage: CURRENT_PAGE,
            orderColumn: sortColumn.id,
            orderDesc: order === 'desc',
            defaultSortField: camelCase(sortColumn.name),
            defaultSortAsc: order === 'asc'
        });
        if (currentPage) {
            await this.props.fetchVouchersList({
                page: CURRENT_PAGE,
                pageSize,
                orderColumn: sortColumn.id,
                orderDesc: order === 'desc',
                beginDate: searchBeginDate,
                endDate: searchEndDate,
                campaign: filtered && filterCampaign ? filterCampaign : null,
                partnerId: filtered && filterPartner ? filterPartner : null,
                voucherCode: filtered ? filterVoucherCode : null,
                nif: filtered ? filterNIF : null,
                state: filterState.id,
            });
        }
        this.setState({isLoading: false});
    };

    handleFilterData = async filters => {
        const {currentPage, pageSize, searchBeginDate, searchEndDate, orderColumn, orderDesc} = this.state;

        this.setState(prevState => ({
            currentPage: CURRENT_PAGE,
            paginationResetDefaultPage: !prevState.paginationResetDefaultPage,
            filtered: true,
            isLoading: true,
            filterCampaign: filters.campaign ? filters.campaign.label : null,
            filterPartner: filters.partner ? filters.partner.partnerId : null,
            filterVoucherCode: filters.voucherCode ? filters.voucherCode.trim() : null,
            filterNIF: filters.nif ? filters.nif.trim() : null,
            filterState: filters.state,
        }));
        if (currentPage === 1) {
            await this.props.fetchVouchersList({
                page: CURRENT_PAGE,
                pageSize: pageSize,
                orderColumn,
                orderDesc,
                beginDate: searchBeginDate,
                endDate: searchEndDate,
                campaign: filters.campaign && filters.campaign.label,
                partnerId: filters.partner && filters.partner.partnerId,
                voucherCode: filters.voucherCode && filters.voucherCode.trim(),
                nif: filters.nif && filters.nif.trim(),
                state: filters.state.id,
            });
        }

        this.setState({isLoading: false});
    };

    handleFilterClear = async () => {
        const {currentPage, pageSize, voucherStateOptions, searchBeginDate, searchEndDate, orderColumns} = this.state;

        this.props.change('filter', {state: voucherStateOptions[0]});
        this.setState(prevState => ({
            currentPage: CURRENT_PAGE,
            paginationResetDefaultPage: !prevState.paginationResetDefaultPage,
            filtered: false,
            isLoading: true,
            orderColumn: orderColumns[0].id
        }));
        if (currentPage === 1) {
            await this.props.fetchVouchersList({
                page: CURRENT_PAGE,
                pageSize,
                beginDate: searchBeginDate,
                endDate: searchEndDate,
                orderColumn: orderColumns[0].id
            });
        }

        this.setState({isLoading: false});
    };

    handleExport = async () => {
        const {filtered, currentPage, pageSize, searchBeginDate, searchEndDate, orderColumn, orderDesc, filterCampaign, filterPartner, filterVoucherCode, filterNIF, filterState} = this.state;
        let formValues;

        this.setState({isExporting: true});
        formValues = {
            page: currentPage,
            pageSize: pageSize,
            orderColumn,
            orderDesc,
            beginDate: searchBeginDate,
            endDate: searchEndDate,
            campaign: filtered && filterCampaign ? filterCampaign : null,
            partnerId: filtered && filterPartner ? filterPartner : null,
            voucherCode: filtered ? filterVoucherCode : null,
            nif: filtered ? filterNIF : null,
            state: filterState.id
        };
        await this.props.listVouchersExport(formValues);
        this.setState({isExporting: false});
    };

    handleSearchClear = () => {
        const {voucherStateOptions} = this.state;

        this.props.change('search', {state: voucherStateOptions[0]});
        this.props.change('filter', {state: voucherStateOptions[0]});
        this.setState({data: [], searched: false, searchBeginDate: null, searchEndDate: null});
    };

    handleSearchSubmit = async formValues => {
        const {pageSize, searchBeginDate, searchEndDate, orderColumn, voucherStateOptions} = this.state;
        let newBeginDate, newEndDate;

        if (searchBeginDate) {
            newBeginDate = new Date(searchBeginDate);
            newBeginDate.setHours(0, 0, 0);
        }
        if (searchEndDate) {
            newEndDate = new Date(searchEndDate);
            newEndDate.setHours(23, 59, 59);
        }
        this.setState({
            isLoading: true,
            searched: false,
            searchBeginDate: newBeginDate,
            searchEndDate: newEndDate,
        });
        formValues.search.beginDate = newBeginDate;
        formValues.search.endDate = newEndDate;
        this.props.change('filter', {state: voucherStateOptions[0]});
        await this.props.fetchVouchersList({
            page: CURRENT_PAGE,
            pageSize,
            orderColumn: orderColumn,
            beginDate: newBeginDate,
            endDate: newEndDate,
            state: formValues.search.state.id
        });

        this.setState({
            isLoading: false,
            searched: true
        });
    };

    renderSearchBar() {
        const {t} = this.props;
        const {voucherStateOptions, searchBeginDate, searchEndDate} = this.state;

        return (
            <>
                <div className="card__title">
                    <h5 className="bold-text">{t('voucherMonitoring->formTitleSearch')}</h5>
                </div>
                <form className="form d-block" onSubmit={this.props.handleSubmit(this.handleSearchSubmit)}>
                    <Row>
                        <Col xs={3}>
                            <Field
                                name="search.beginDate"
                                id="search.beginDate"
                                component={DateTimePickerElement}
                                label={t('voucherMonitoring->labelVoucherPeriod')}
                                dateFormat="dd/MM/yyyy"
                                startDate={searchBeginDate}
                                onChange={e => this.setState({searchBeginDate: e})}
                                validate={required}
                            />
                        </Col>
                        <Col xs={3}>
                            <Field
                                name="search.endDate"
                                id="search.endDate"
                                component={DateTimePickerElement}
                                label={t('voucherMonitoring->labelVoucherPeriodUntil')}
                                dateFormat="dd/MM/yyyy"
                                startDate={searchEndDate}
                                minDate={searchBeginDate}
                                onChange={e => this.setState({searchEndDate: e})}
                                validate={required}
                            />
                            <Field
                                name="dateCheck"
                                id="dateCheck"
                                component={HiddenElement}
                                hiddenValue=""
                                validate={(searchEndDate && moment(searchBeginDate).diff(moment(searchEndDate), 'days', true) >= 0) ? [greaterDate] : []}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>
                            <Field
                                component={SelectElement}
                                name="search.state"
                                id="search.state"
                                label={t('voucherMonitoring->labelState')}
                                options={voucherStateOptions}
                                defaultValue={voucherStateOptions[0]}
                            />
                        </Col>
                    </Row>
                    <p className="mt-0 mb-4">{t('voucherMonitoring->labelVoucherPeriodNote')}</p>
                    <Button
                        type="button"
                        outline
                        color="danger"
                        className="mb-0"
                        onClick={() => this.handleSearchClear('reset')}
                    >
                        {t('voucherMonitoring->btnClear')}
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        className="mb-0"
                    >
                        {t("voucherMonitoring->btnSearch")}
                    </Button>
                </form>
            </>
        );
    }

    renderTable() {
        const {data, isLoading, rowsPerPage, pageSize, currentPage, voucherStateOptions, searched, defaultSortAsc, defaultSortField, orderColumns, paginationResetDefaultPage} = this.state;
        const {t, totalItems, theme} = this.props;

        return (
            searched ?
                <>
                    <div className="card__title">
                        <h5 className="bold-text">{t('voucherMonitoring->formTitleDataTableTitle', {count: totalItems})}</h5>
                        <ButtonToolbar className='products-list__btn-toolbar-top'>
                            <Button type="button" color="success"
                                    onClick={this.handleExport}>{t('voucherCampaignList->btnExport')}</Button>
                        </ButtonToolbar>
                    </div>
                    <VoucherMonitoringDataTable
                        theme={theme}
                        data={data}
                        noHeader
                        paginationServer
                        paginationTotalRows={totalItems}
                        handlePerRowsChange={this.handlePerRowsChange}
                        handlePageChange={this.handlePageChange}
                        rowsPerPage={rowsPerPage}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        paginationResetDefaultPage={paginationResetDefaultPage}
                        handleToggle={this.handleToggle}
                        handleFilterData={this.handleFilterData}
                        handleFilterClear={this.handleFilterClear}
                        voucherStateOptions={voucherStateOptions}
                        defaultSortField={defaultSortField}
                        defaultSortAsc={defaultSortAsc}
                        orderColumns={orderColumns}
                        handleDataSort={this.handleDataSort}
                        handleSubmit={this.props.handleSubmit}
                        progressLoading={isLoading}
                    />
                </>
                :
                isLoading ?
                    <p className="text-center">{t('voucherMonitoring->labelSearching')}</p>
                    :
                    <p className="text-center">{t('voucherMonitoring->labelNoSearch')}</p>
        )
    }

    renderError(error) {
        const {t} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color="danger" className="alert--bordered" icon>
                            <p className="bold-text">{t('alert:danger->title')}</p>
                            <p className="mb-2">{t('alert:danger->message', {error})}</p>
                            <Button size="sm" type="button" className="mb-0"
                                    onClick={() => this.handleFilterData()}>{t('alert:danger->tryAgain')}</Button>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    }

    render() {
        const {t, vouchersError} = this.props;
        const {isLoading, isExporting} = this.state;

        if (vouchersError) {
            return this.renderError(vouchersError);
        }

        return (
            <Container>
                <LoadingElement isLoading={isLoading || isExporting}/>
                <h1 className="page-title mb-4">{t('voucherMonitoring->pageTitle')}</h1>
                <Card>
                    <CardBody>
                        {this.renderSearchBar()}
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        {this.renderTable()}
                    </CardBody>
                </Card>

            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        vouchers: Object.values(state.vouchers.data.vouchers),
        totalItems: state.vouchers.data.totalItems,
        vouchersDone: state.vouchers.done.status,
        vouchersError: state.vouchers.error,
        message: state.vouchers.done.message,
        partners: Object.values(state.partners.data.partners),
        partnersDone: state.partners.done.status,
        partnersError: state.partners.error,
        theme: state.theme.className
    };
};

const translationWrapped = withTranslation(['voucher', 'common', 'modal', 'notification'])(VoucherMonitoring);

const formWrapped = reduxForm({
    form: 'voucherForm',
})(translationWrapped);

export default connect(mapStateToProps, {
    fetchVouchersList,
    fetchPartners,
    fetchVoucherCampaigns,
    listVouchersExport
})(formWrapped);