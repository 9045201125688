import React from "react";
import axios from "axios";
import history from "../../history";

export const FETCH_SERVICE = "FETCH_SERVICE";
export const FETCH_SERVICES = "FETCH_SERVICES";
export const CREATE_SERVICE = "CREATE_SERVICE";
export const DONE_SERVICE = "DONE_SERVICE";
export const ERROR_SERVICE = "ERROR_SERVICE";
export const PENDING_SERVICE = "PENDING_SERVICE";
export const EDIT_SERVICE = "EDIT_SERVICE";
export const TOGGLE_SERVICE = "TOGGLE_SERVICE";
export const DELETE_SERVICE = "DELETE_SERVICE";

export const fetchServices = (page, pageSize, name) => async (dispatch) => {
    dispatch({type: PENDING_SERVICE});
    try {
        const params = {"page": page, "pageSize": pageSize, "language": "", "name": name};
        const response = await axios.get("/ServiceTypes/GetServiceTypes", {
            headers: {language: "pt-PT"},
            params: params,
        });
        if (response) {
            dispatch({type: FETCH_SERVICES, payload: response.data});
            dispatch({type: DONE_SERVICE, payload: null});
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICE, payload: e.message});
    }
};

export const fetchService = (id) => async (dispatch) => {
    dispatch({type: PENDING_SERVICE});
    try {
        const response = await axios.get(`/ServiceTypes/GetById/${id}`);
        if (response) {
            dispatch({type: FETCH_SERVICE, payload: response.data});
            dispatch({type: DONE_SERVICE});
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICE, payload: e.message});
    }
};

export const createService = formValues => async dispatch => {
    try {
        const response = await axios.post('/ServiceTypes', formValues);
        if (response) {
            dispatch({type: CREATE_SERVICE, payload: 'operationSuccess'});
            history.replace("/vvservices/services");
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICE, payload: e.message});
    }
};


export const editService = formValues => async dispatch => {
    try {
        const response = await axios.put('/ServiceTypes', formValues);
        if (response) {
            dispatch({type: EDIT_SERVICE, payload: 'operationSuccess'});
            history.replace('/vvservices/services');
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICE, payload: e.message});
    }
};

export const toggleService = formValues => async dispatch => {
    dispatch({type: PENDING_SERVICE});
    try {
        const response = await axios.patch('/ServiceTypes', {Id: formValues.id, IsEnabled: !formValues.isEnabled});
        if (response) {
            formValues.isEnabled = !formValues.isEnabled;
            dispatch({type: TOGGLE_SERVICE, payload: 'operationSuccess'});
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICE, payload: e.message});
    }
};

export const deleteService = (id, type, pageSize, currentPage) => async (dispatch) => {
    dispatch({type: PENDING_SERVICE});
    try {
        const response = await axios.delete(`/ServiceTypes/${id}`);
        if (response) {
            dispatch({type: DELETE_SERVICE, payload: "operationSuccess"});
            if (type === "list") {
                dispatch(fetchServices(currentPage, pageSize));
            } else {
                history.replace("/vvservices/services");
            }
        }
    } catch (e) {
        dispatch({type: ERROR_SERVICE, payload: e.message});
    }
};

