import React from "react";
import {Route, Switch} from "react-router-dom";
import Partner from "./Partner";
import TollBalance from "./TollBalance";
import Voucher from "./Voucher";

export default () => (
    <Switch>
        <Route path="/vv/partner" component={Partner}/>
        <Route path="/vv/tollbalance" component={TollBalance}/>
        <Route path="/vv/voucher" component={Voucher}/>
    </Switch>
);
