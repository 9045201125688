import _ from 'lodash';
import {
    CREATE_PARTNER,
    DELETE_PARTNER,
    DONE_PARTNER,
    EDIT_PARTNER,
    ERROR_PARTNER,
    FETCH_PARTNER,
    FETCH_PARTNERS,
    PENDING_PARTNER,
    TOGGLE_PARTNER
} from '../actions/partnerActions';

const initialState = {
    done: {
        status: false,
        message: null
    },
    data: {
        partners: [],
        totalItems: null
    },
    error: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PARTNERS:
            return {
                ...state,
                data: {partners: _.mapKeys(action.payload.partners, 'id'), totalItems: action.payload.total}
            };
        case FETCH_PARTNER:
            return {...state, data: {partners: {[action.payload.id]: action.payload}}};
        case CREATE_PARTNER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case EDIT_PARTNER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case TOGGLE_PARTNER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DELETE_PARTNER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DONE_PARTNER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case PENDING_PARTNER:
            return {...state, done: {status: false, message: null}, error: null};
        case ERROR_PARTNER:
            return {...state, done: {status: true, message: null}, error: action.payload};
        default:
            return state;
    }
}