import _ from 'lodash';
import {
    CREATE_BANNER,
    DELETE_BANNER,
    DONE_BANNER,
    EDIT_BANNER,
    ERROR_BANNER,
    FETCH_BANNER,
    FETCH_BANNERS,
    PENDING_BANNER,
    TOGGLE_BANNER
} from '../actions/bannersActions';

const initialState = {
    done: {
        status: false,
        message: null
    },
    data: {
        banners: [],
        totalItems: null
    },
    error: null
};

export default (state = initialState, action) => {
    
    switch (action.type) {
        case FETCH_BANNERS:
            return {
                ...state,
                data: { banners: _.mapKeys(action.payload.banners, 'id'), totalItems: action.payload.total }
            };
        case FETCH_BANNER:
            return {...state, data: {banners: {[action.payload.id]: action.payload}}};
        case CREATE_BANNER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case EDIT_BANNER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case TOGGLE_BANNER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DELETE_BANNER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case DONE_BANNER:
            return {...state, done: {status: true, message: action.payload}, error: null};
        case PENDING_BANNER:
            return {...state, done: {status: false, message: null}, error: null};
        case ERROR_BANNER:
            return {...state, done: {status: true, message: null}, error: action.payload};
        default:
            return state;
    }
}