import React from "react";
import {Field} from "redux-form";
import {Row, Col, Button} from "reactstrap";
import {withTranslation} from "react-i18next";
import InputElement from "../../../../shared/components/form/Input";

class PartnerAddProductForm extends React.Component {
    state = {
        newProd: {},
    };

    addProduct = () => {
        this.setState({ newProd: {} })
        this.props.onAddProduct(this.state.newProd);
    }

    render() {
        const {newProd} = this.state;
        const {t, newProdFormValues} = this.props;

        return (
            <>
                <p className='bold-text'>{t("partnerForm->labelNewProduct")}</p>
                <Row className="align-items-end">
                    <Col sm={8}>
                        <Field
                            name="prodName"
                            id="prodName"
                            component={InputElement}
                            label={t('partnerForm->tableColumnName')}
                            onChange={e => this.setState(state => ({
                                newProd: {
                                    ...state.newProd,
                                    prodName: e.target.value
                                }
                            }))}
                        />
                    </Col>
                    <Col sm={3}>
                        <Field
                            name="prodReference"
                            id="prodReference"
                            component={InputElement}
                            label={t('partnerForm->tableColumnReference')}
                            onChange={e => this.setState(state => ({
                                newProd: {
                                    ...state.newProd,
                                    prodReference: e.target.value
                                }
                            }))}
                            className={newProdFormValues && newProdFormValues.referenceNotValid ? 'mb-0' : ''}
                            onBlur={() => this.props.checkProductsValues(-1, 'reference', newProd)}
                        />
                        {
                            newProdFormValues && newProdFormValues.referenceNotValid &&
                            <span className="form__form-group-error">
                                {t('partnerForm->labelCustomErrorSameValue', {
                                    attribute: t('partnerForm->tableColumnReference'),
                                    attributePlural: 's'
                                })}
                            </span>
                        }
                    </Col>
                    <Col sm={1}>
                        <Button
                            id="btnAddProduct"
                            color="primary"
                            type="button"
                            onClick={this.addProduct}
                            disabled={!newProd.prodName || !newProd.prodReference || (newProdFormValues && newProdFormValues.referenceNotValid)}
                        >
                            {t('partnerForm->btnAdd')}
                        </Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withTranslation("partner")(PartnerAddProductForm);


