import React from "react";
import {Col, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import PlusIcon from "mdi-react/PlusIcon";
import MinusIcon from "mdi-react/MinusIcon";
import Alert from "../../../../shared/components/common/Alert";

class VoucherPOS extends React.Component {
    state = {
        filterAvailablePOSText: '',
        filterSelectedPOSText: '',
    };
    
    render() {
        const {t, availablePOS, selectedPOS, isEditable} = this.props;
        const {filterAvailablePOSText, filterSelectedPOSText} = this.state;
        const filteredAvailablePOS = availablePOS.filter(pos => pos.value.toLowerCase().includes(filterAvailablePOSText));
        const filteredSelectedPOS = selectedPOS.filter(pos => pos.value.toLowerCase().includes(filterSelectedPOSText));

        return (
            <>
                <Row>
                    <Col sm={6}>
                        <Alert color="info" className="alert--neutral alert-period" icon>
                            <p>
                                {t('voucherForm->labelInfoNotePOSMessage')}
                            </p>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <span>{t('voucherForm->labelAvailable') + ` (${availablePOS.length})`}</span>
                            {
                                isEditable &&
                                <button className="panel__btn" type="button"
                                        onClick={() => this.props.addAllPOS('pos')}>{t('voucherForm->labelSelectAll')}</button>
                            }
                            
                        </div>
                        <div className="form">
                            <div className="form__form-group products-list__search">
                                <label htmlFor="search" className="sr-only">
                                    {t('voucherForm->labelSearchTextPlaceholder')}
                                </label>
                                <input
                                    type="text"
                                    id="filterAvailablePOSText"
                                    name="filterAvailablePOSText"
                                    value={this.state.filterAvailablePOSText}
                                    onChange={e => this.setState({filterAvailablePOSText: e.target.value})}
                                    placeholder={t('voucherForm->labelSearchTextPlaceholder')}
                                    autoComplete="off"
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <ul className="list">
                            {filteredAvailablePOS.map(pos =>
                                <li className="list__item" key={pos.key}>
                                    <span>{pos.value}</span>
                                    {
                                        isEditable &&
                                        <button
                                            className="panel__btn"
                                            type="button"
                                            onClick={() => this.props.addPOS(pos, 'pos')}
                                        >
                                            <PlusIcon
                                                className="list__item-icon"/>
                                        </button>
                                    }
                                </li>
                            )}
                        </ul>
                    </Col>
                    <Col sm={6}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <span>{t('voucherForm->labelSelected') + ` (${selectedPOS.length})`}</span>
                            {
                                isEditable &&
                                <button className="panel__btn" type="button"
                                        onClick={() => this.props.removeAllPOS('pos')}>{t('voucherForm->labelCleanSelection')}</button>
                            }
                        </div>
                        <div className="form">
                            <div className="form__form-group products-list__search">
                                <label htmlFor="search" className="sr-only">
                                    {t('voucherForm->labelSearchTextPlaceholder')}
                                </label>
                                <input
                                    type="text"
                                    id="filterSelectedPOSText"
                                    name="filterSelectedPOSText"
                                    value={this.state.filterSelectedPOSText}
                                    onChange={e => this.setState({filterSelectedPOSText: e.target.value})}
                                    placeholder={t('voucherForm->labelSearchTextPlaceholder')}
                                    autoComplete="off"
                                    disabled={!isEditable}
                                />
                            </div>
                        </div>
                        <ul className="list">
                            {filteredSelectedPOS.map(pos =>
                                <li className="list__item" key={pos.key}>
                                    <span>{pos.value}</span>
                                    {
                                        isEditable &&
                                        <button className="panel__btn" type="button"
                                                onClick={() => this.props.removePOS(pos, 'pos')}><MinusIcon
                                            className="list__item-icon"/></button>
                                    }
                                </li>
                            )}
                        </ul>
                    </Col>
                </Row>
            </>
        )
    }
}

export default withTranslation('voucher')(VoucherPOS);



