export const compareValues = (field, order = 'asc') => {
    return (a, b) => {
        let aux, varA, varB;
        field && field.includes('.') && (aux = field.split('.'));
        /*if (!a.hasOwnProperty(field) || !b.hasOwnProperty(field)) {
            // property doesn't exist on either object
            return 0;
        }*/
        
        if (aux) {
            varA = (typeof a[aux[0]][aux[1]] === 'string') ? a[aux[0]][aux[1]].toUpperCase() : a[aux[0]][aux[1]];
            varB = (typeof b[aux[0]][aux[1]] === 'string') ? b[aux[0]][aux[1]].toUpperCase() : b[aux[0]][aux[1]];
        } else {
            varA = (typeof a[field] === 'string') ? a[field].toUpperCase() : a[field];
            varB = (typeof b[field] === 'string') ? b[field].toUpperCase() : b[field];
        }
        
        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}

export const convertDateTime = (date) => {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
};