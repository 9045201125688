import React from "react";
import {Field} from "redux-form";
import InputElement from "../../../../shared/components/form/Input";
import SelectElement from "../../../../shared/components/form/Select";
import DateTimePickerElement from "../../../../shared/components/form/DateTimePicker";

export const DataTableFiltersCampaignName = () => {
    return (
        <Field
            name="filter.name"
            id="filter.name"
            component={InputElement}
        />
    );
};

export const DataTableFiltersBenefitType = ({filterBenefitTypeOptions}) => {
    return (
        <Field
            name="filter.campaignBenefitType"
            id="filter.campaignBenefitType"
            component={SelectElement}
            options={filterBenefitTypeOptions}
            defaultValue={filterBenefitTypeOptions[0]}
        />
    );
};

export const DataTableFiltersTimePeriod = ({beginDate, endDate}) => {
    return (
        <>
            <Field
                name="filter.beginDate"
                id="filter.beginDate"
                component={DateTimePickerElement}
                dateFormat="dd/MM/yyyy"
                startDate={beginDate}
            />
            <Field
                name="filter.endDate"
                id="filter.endDate"
                component={DateTimePickerElement}
                dateFormat="dd/MM/yyyy"
                startDate={endDate}
            />
        </>
    );
};

export const DataTableFiltersDateTime = ({t, daysHoursFilterOptions}) => {
    return (
        <Field
            name="filter.dateTime"
            id="filter.dateTime"
            component={SelectElement}
            options={daysHoursFilterOptions}
            placeholder={t('common->labelAlways')}
            isMulti
        />
    );
};

export const DataTableFiltersHighlighted = ({filterHighlightedOptions}) => {
    return (
        <Field
            name="filter.highlighted"
            id="filter.highlighted"
            component={SelectElement}
            options={filterHighlightedOptions}
        />
    );
};

export const DataTableFiltersActive = ({isEnabledFilterOptions}) => {
    return (
        <Field
            name="filter.active"
            id="filter.active"
            component={SelectElement}
            options={isEnabledFilterOptions}
        />
    );
};