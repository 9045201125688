import React from 'react';
import {Route, Switch} from 'react-router-dom';
import PartnerList from '../../../../Campaign/Partner/PartnerList/';
import PartnerCreate from '../../../../Campaign/Partner/PartnerCreate';
import PartnerEdit from '../../../../Campaign/Partner/PartnerEdit';

export default () => (
    <Switch>
        <Route path="/vv/partner" exact component={PartnerList}/>
        <Route path="/vv/partner/create" component={PartnerCreate} />
        <Route path="/vv/partner/:partnerId/edit/:id" component={PartnerEdit} />
    </Switch>
);

