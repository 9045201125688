import React from "react";
import {connect} from "react-redux";
import {fetchOptions, removeOptions} from "../../../redux/actions/selectOptionActions";
import {createLocation} from "../../../redux/actions/locationActions";
import {fetchLanguages} from "../../../redux/actions/themeActions";
import {store} from "react-notifications-component";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import ContentForm from "./LocationForm";
import {Card, CardBody, Container} from "reactstrap";
import Alert from "../../../shared/components/common/Alert";
import {Link} from "react-router-dom";

class LocationCreate extends React.Component {
    componentDidMount() {
        this.props.fetchOptions('/ServiceTypes/GetAll', {headers: {language: 'pt-PT'}});
    }

    componentDidUpdate() {
        if (this.props.message) {
            store.addNotification({
                title: this.props.t("success->title"),
                message: this.props.t("success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.removeOptions();
    }

    onSubmit = formValues => {
        this.props.createLocation(formValues);
    };

    renderError = () => {
        const {t, serviceTypesOptionsError, languagesError} = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color='danger' className='alert--bordered' icon>
                            <>
                                <p className='bold-text'>{t("alert:danger->title")}</p>
                                <p>{t("alert:danger->message", {error: serviceTypesOptionsError || languagesError})}</p>
                                <Link to='/vvservices/location'>{t("alert:danger->goBack")}</Link>
                            </>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    };

    render() {
        const {t, serviceTypesOptions, serviceTypesOptionsDone, serviceTypesOptionsError, languages, languagesDone, languagesError} = this.props;
        if (!serviceTypesOptionsDone || !languagesDone) {
            return (
                <div className='d-flex justify-content-center'>
                <div>
                  <img
                    width='100'
                    height='100'
                    src={`${process.env.PUBLIC_URL}/img/load.gif`}
                    alt='Loading'
                  />
                </div>
              </div>
            );
        }
        if (serviceTypesOptionsError || languagesError) {
            return this.renderError();
        }

        return (
            <ContentForm
                onSubmit={this.onSubmit}
                serviceTypesOptions={Object.values(serviceTypesOptions)}
                btnText={t('locationForm->btnCreate')}
                languages={languages}
                initialValues={{isEnabled: true}}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        serviceTypesOptions: state.selectOptions.data,
        serviceTypesOptionsDone: state.selectOptions.done,
        serviceTypesOptionsError: state.selectOptions.error,
        languages: state.theme.data,
        languagesDone: state.theme.done.status,
        languagesError: state.theme.error
    };
};

export default compose(
    withTranslation(["location", "notification"]),
    connect(mapStateToProps, {fetchOptions, removeOptions, createLocation, fetchLanguages})
)(LocationCreate);
