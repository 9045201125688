import React from 'react';
import {Route, Switch} from 'react-router-dom';
import LocationList from '../../../VVServices/Location/LocationList';
import LocationCreate from '../../../VVServices/Location/LocationCreate';
import LocationEdit from '../../../VVServices/Location/LocationEdit';

export default () => (
    <Switch>
        <Route path="/vvservices/location" exact component={LocationList}/>
        <Route path="/vvservices/location/create" component={LocationCreate}/>
        <Route path="/vvservices/location/edit/:id" component={LocationEdit}/>
    </Switch>
);