import React from 'react';
import 'animate.css';
import DataTableElement from '../../../../shared/components/common/DataTable';
import Moment from "react-moment";
import {ROWS_PER_PAGE, PAGE_SIZE} from '../variables';

class VoucherImportDataTable extends React.Component {
    renderTableColumns() {
        const {t} = this.props;

        return [
            {
                name: t('voucherImport->tableColumnId'),
                selector: 'id',
                sortable: true,
            },
            {
                name: t('voucherImport->tableColumnTotalVouchers'),
                selector: 'number',
                sortable: true,
            },
            {
                name: t('voucherForm->labelBeginDate'),
                selector: 'startDate',
                cell: row => row.startDate ? <Moment format="DD/MM/YYYY" date={row.startDate} /> : t('common:labelNotAvailable')
            },
            {
                name: t('voucherForm->labelEndDate'),
                selector: 'expirationDate',
                cell: row => row.expirationDate ? <Moment format="DD/MM/YYYY" date={row.expirationDate} /> : t('common:labelNotAvailable')
            },
        ]
    }

    render() {
        const {data, theme} = this.props;

        return (
            <>
                <div className="form react-datatable__filters">
                    <DataTableElement
                        theme={theme}
                        keyField="id"
                        columns={this.renderTableColumns()}
                        data={data}
                        noHeader
                        paginationRowsPerPageOptions={ROWS_PER_PAGE}
                        paginationPerPage={PAGE_SIZE}
                    />
                </div>
            </>
        )
    }
}

export default VoucherImportDataTable;