import React from "react";
import { DATATABLE_DARK_MODE_STYLES } from "../../../pages/variables";
import { compareValues } from "../../../pages/App/utils/functions";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { withTranslation } from "react-i18next";

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
    <div className="custom-control custom-checkbox">
        <input
            type="checkbox"
            className="custom-control-input"
            ref={ref}
            {...rest}
        />
        <label className="custom-control-label" onClick={onClick} />
    </div>
));

class DataTableElement extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            rowsPerPage: props.paginationRowsPerPageOptions,
            pageSize: props.paginationPerPage,
            currentPage: props.currentPage,
            fromSort: false,
            selectedRows: []
        };
    }

    static propTypes = {
        theme: PropTypes.string.isRequired,
        keyField: PropTypes.string,
        data: PropTypes.array.isRequired,
        columns: PropTypes.array.isRequired,
        progressPending: PropTypes.bool,
        defaultSortField: PropTypes.string,
        defaultSortAsc: PropTypes.bool,
        persistTableHead: PropTypes.bool,
        noHeader: PropTypes.bool,
        selectableRows: PropTypes.bool,
        pagination: PropTypes.bool,
        paginationServer: PropTypes.bool,
        paginationDefaultPage: PropTypes.number,
        paginationResetDefaultPage: PropTypes.bool,
        paginationTotalRows: PropTypes.number,
        paginationRowsPerPageOptions: PropTypes.array,
        paginationPerPage: PropTypes.number,
        currentPage: PropTypes.number,
        withFilters: PropTypes.bool,
        receivePerRowsChange: PropTypes.bool
    };

    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        if (prevProps.data !== data) {
            this.setState({ data });
        }
    }

    handlePageChange = (currentPage) => {
        const { paginationServer } = this.props;
        const { pageSize } = this.state;

        this.setState({ currentPage });
        paginationServer && this.props.handlePageChange(currentPage, pageSize);
    };

    handlePerRowsChange = (pageSize, currentPage) => {
        const { receivePerRowsChange } = this.props;
        this.setState({ pageSize, currentPage });
        receivePerRowsChange && this.props.handlePerRowsChange(currentPage, pageSize);
    };

    handleDataSort(rows, field, order) {
        let sortedData = [];
        //orderBy(sortedData, rows.selector, order)

        rows.map(item => item.id !== -1 && sortedData.push(item));

        sortedData.sort(compareValues(field, order));
        if (this.props.withFilters) {
            sortedData.unshift({
                id: -1,
                internalId: -1
            });
        }

        return sortedData;
    }

    render() {
        const { t, theme, keyField, columns, defaultSortField, defaultSortAsc, progressPending, noHeader, contextActions, selectableRows, selectableRowDisabled, onSelectedRowsChange, clearSelectedRows, paginationServer, paginationDefaultPage, paginationResetDefaultPage, paginationTotalRows, contextMessage, withFilters } = this.props;
        const { data, rowsPerPage, pageSize } = this.state;
        const paginationComponentOptions = {
            rowsPerPageText: t('common:table->rowsPerPageText'),
            rangeSeparatorText: t('common:table->rangeSeparatorText')
        };

        if (withFilters && data.length === 0) {
            data.push({ id: -1, internalId: -1 });
        }

        return (
            <>
                <DataTable
                    keyField={keyField}
                    customStyles={theme === 'theme-dark' ? DATATABLE_DARK_MODE_STYLES : {}}
                    columns={columns}
                    data={data}
                    defaultSortField={defaultSortField}
                    defaultSortAsc={defaultSortAsc}
                    noDataComponent={t('common:table->noDataComponent')}
                    progressPending={progressPending}
                    noHeader={noHeader}
                    overflowY
                    selectableRows={selectableRows}
                    selectableRowDisabled={selectableRowDisabled}
                    selectableRowsComponent={CustomCheckbox}
                    onSelectedRowsChange={onSelectedRowsChange}
                    clearSelectedRows={clearSelectedRows}
                    contextActions={contextActions}
                    contextMessage={contextMessage}
                    pagination={(data.length >= 1 && data[0].id !== -1)}
                    paginationServer={paginationServer}
                    paginationDefaultPage={paginationDefaultPage}
                    paginationResetDefaultPage={paginationResetDefaultPage}
                    paginationTotalRows={paginationTotalRows}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationRowsPerPageOptions={rowsPerPage}
                    paginationPerPage={pageSize}
                    sortFunction={(rows, field, order) => this.handleDataSort(rows, field, order)}
                    conditionalRowStyles={this.props.conditionalRowStyles}
                />
                {
                    !progressPending && data.length === 1 && data[0].id === -1 &&
                    <div className="d-block w-100">
                        <p className="text-center">{t('common:table->noDataComponent')}</p>
                    </div>
                }
            </>
        );
    }
}

export default withTranslation('common')(DataTableElement);