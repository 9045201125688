import React from "react";
import {Field} from "redux-form";
import axios from "axios";
import AutoComplete from "../../../../shared/components/form/AutoComplete";
import InputElement from "../../../../shared/components/form/Input";
import SelectElement from "../../../../shared/components/form/Select";

export class DataTableFiltersCampaignName extends React.Component {
    state = {
        campaignOptions: []
    };

    fetchPartnersOptions = async value => {
        if (value.trim()) {
            try {
                const response = await axios.get(`/VoucherCampaigns/GetCampaignAutoComplete/${value}`, {
                    headers: {language: localStorage.getItem('i18nextLng')},
                });
                if (response) {
                    this.setState({campaignOptions: response.data});
                }
            } catch (e) {
                console.log(e)
            }
        }
    };

    render() {
        const {t} = this.props;
        const {campaignOptions} = this.state;
        const options = campaignOptions.map(campaign => {
            return {
                label: campaign.text,
                value: campaign.id
            }
        });

        return (
            <Field
                name="filter.campaign"
                id="filter.campaign"
                component={AutoComplete}
                isClearable
                placeholder={t('voucherForm->labelProductPlaceholder')}
                async
                disableCreateOption={true}
                options={options}
                handleInputChange={value => this.fetchPartnersOptions(value)}
            />
        );
    }
}

export class DataTableFiltersPartnerName extends React.Component {
    state = {
        partnerOptions: []
    };

    fetchPartnersOptions = async value => {
        if (value.trim()) {
            try {
                const params = {page: 1, pageSize: 10, name: value, isEnabled: true};
                const response = await axios.get('/Partners/GetPartners', {
                    headers: {language: localStorage.getItem('i18nextLng')},
                    params
                });
                if (response) {
                    this.setState({partnerOptions: response.data.partners});
                }
            } catch (e) {
                console.log(e)
            }
        }
    };

    render() {
        const {t} = this.props;
        const {partnerOptions} = this.state;
        const options = partnerOptions.map(partner => {
            return {
                label: partner.name,
                value: partner.id,
                partnerId: partner.partnerId
            }
        });

        return (
            <Field
                name="filter.partner"
                id="filter.partner"
                component={AutoComplete}
                placeholder={t('voucherForm->labelProductPlaceholder')}
                isClearable
                async
                disableCreateOption={true}
                options={options}
                handleInputChange={value => this.fetchPartnersOptions(value)}
            />
        );
    }
}

export const DataTableFiltersVoucherCode = () => {
    return (
        <Field
            name="filter.voucherCode"
            id="filter.voucherCode"
            component={InputElement}
        />
    );
};

export const DataTableFiltersNIF = () => {
    return (
        <Field
            name="filter.nif"
            id="filter.nif"
            component={InputElement}
        />
    );
};

export const DataTableFiltersVoucherState = ({voucherStateOptions}) => {
    return (
        <Field
            name="filter.state"
            id="filter.state"
            component={SelectElement}
            options={voucherStateOptions}
            defaultValue={voucherStateOptions[0]}
        />
    );
};