import React from "react";
import { Switch } from "react-router-dom";
import MainWrapper from "../MainWrapper";
import WrappedRoutes from "./WrappedRoutes";
import history from "../../../history";
import { Router as RouterDOM } from "react-router";
import LogIn from "../../LogIn/LogInForm";
import { connect } from "react-redux";
import { verifyToken } from "../../../redux/actions/authActions";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

class Router extends React.Component {
  componentDidMount() {
    this.props.verifyToken();
  }

  render() {
    const { auth, done } = this.props;
    if (!done) {
      return (
        <div className='d-flex justify-content-center'>
          <div>
            <img
              width='100'
              height='100'
              src={`${process.env.PUBLIC_URL}/img/load.gif`}
              alt='Loading'
            />
          </div>
        </div>
      );
    }
    return (
      <MainWrapper>
        <main>
          <RouterDOM history={history}>
            <Switch>
              <PublicRoute path='/login' component={LogIn} isAuthenticated={auth.isAuthenticated} />
              <PrivateRoute
                path='/'
                component={WrappedRoutes}
                isAuthenticated={auth.isAuthenticated}
              />
            </Switch>
          </RouterDOM>
        </main>
      </MainWrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.data,
    done: state.auth.done.status,
  };
};

export default connect(mapStateToProps, { verifyToken })(Router);
