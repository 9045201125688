import React from "react";
import { connect } from "react-redux";
import { Button, Card, CardBody, Container } from "reactstrap";
import { fetchAllPointsOfSale } from "../../../redux/actions/posActions";
import { fetchProducts } from "../../../redux/actions/productActions";
import { fetchTiers, fetchStockTypes, createTollBalanceCampaign } from "../../../redux/actions/tollBalanceActions";
import { store } from "react-notifications-component";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import TollBalanceForm from "./TollBalanceForm/";
import Alert from "../../../shared/components/common/Alert";
import LoadingElement from "../../../shared/components/common/Loading";

class TollBalanceCreate extends React.Component {
    componentDidMount() {
        const partnerId = this.props.match.params.partnerId;
        this.props.fetchAllPointsOfSale(partnerId);
        this.props.fetchProducts(partnerId, 1, 1000);
        this.props.fetchStockTypes();
        this.props.fetchTiers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.message !== this.props.message && this.props.message) {
            store.addNotification({
                title: this.props.t("notification:success->title"),
                message: this.props.t("notification:success->message"),
                type: "success",
                insert: "bottom",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "zoomOut"],
                dismiss: {
                    duration: 3000,
                    showIcon: true,
                },
            });
        }
    }

    onSubmit = formValues => {
        this.props.createTollBalanceCampaign(formValues, '/create');
    };

    renderError(error) {
        const { t } = this.props;

        return (
            <Container>
                <Card>
                    <CardBody>
                        <Alert color='danger' className='alert--bordered' icon>
                            <>
                                <p className='bold-text'>{t("alert:danger->title")}</p>
                                <p>{t("alert:danger->message", { error })}</p>
                                <Button size="sm" type="button" className="mb-0"
                                    onClick={() => this.componentDidMount()}>{t('alert:danger->tryAgain')}</Button>
                            </>
                        </Alert>
                    </CardBody>
                </Card>
            </Container>
        );
    }


    render() {
        const { t, POS, POSDone, POSError, products, productsDone, productsError, tiers, tiersDone, stockUnitTypes, stockUnitTypesDone } = this.props;
        if (!POSDone || !productsDone || (!tiersDone || tiers.length === 0) || (!stockUnitTypesDone || stockUnitTypes.length === 0)) {
            return <LoadingElement isLoading={true} />;
        }

        if (POSError || productsError) {
            return this.renderError(POSError || productsError);
        }

        let beginDate = new Date();
        beginDate.setHours(0, 0, 0)

        return (
            <TollBalanceForm
                onSubmit={this.onSubmit}
                partnerName={this.props.match.params.partnerName}
                partnerId={this.props.match.params.partnerId}
                btnText={t("tollBalanceForm->btnCreate")}
                POS={POS}
                products={products}
                tiers={tiers}
                stockUnitTypes={stockUnitTypes}
                initialValues={{ isEnabled: true, isPermanent: true, monday: true, tuesday: true, wednesday: true, thursday: true, friday: true, saturday: true, sunday: true, beginDate }}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        POS: Object.values(state.pointsOfSale.data.pointsOfSale),
        POSDone: state.pointsOfSale.done.status,
        POSError: state.pointsOfSale.error,
        products: Object.values(state.products.data.products),
        productsDone: state.products.done.status,
        productsError: state.products.error,
        tiers: Object.values(state.tollBalances.data.tiers),
        tiersDone: state.tollBalances.done.status,
        stockUnitTypes: Object.values(state.tollBalances.data.stockUnitTypes),
        stockUnitTypesDone: state.tollBalances.done.status,
    };
};

export default compose(
    withTranslation(["tollBalance", "notification"]),
    connect(mapStateToProps, { fetchAllPointsOfSale, fetchProducts, fetchTiers, fetchStockTypes, createTollBalanceCampaign })
)(TollBalanceCreate);