import React from 'react';
import {Route, Switch} from 'react-router-dom';
import ServiceCatList from '../../../VVServices/Categories/ServiceCatList';
import ServiceCatCreate from '../../../VVServices/Categories/ServiceCatCreate';
import ServiceCatEdit from '../../../VVServices/Categories/ServiceCatEdit';

export default () => (
    <Switch>
        <Route path="/vvservices/categories" exact component={ServiceCatList}/>
        <Route path="/vvservices/categories/create" component={ServiceCatCreate}/>
        <Route path="/vvservices/categories/edit/:id" component={ServiceCatEdit}/>
    </Switch>
);