import React from "react";
import {connect} from "react-redux";
import {Field, reduxForm, formValueSelector, getFormSyncErrors} from "redux-form";
import {Link} from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    ButtonToolbar,
    Button,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import {required, maxLength50} from "../../../shared/validations";
import classnames from "classnames";
import {withTranslation} from "react-i18next";
import InputElement from "../../../shared/components/form/Input";
import CheckBoxElement from "../../../shared/components/form/CheckBox";
import SketchColorPicker from "../../../shared/components/form/SketchColorPicker";
import HiddenElement from "../../../shared/components/form/Hidden";
import ModalConfirmation from "../../../shared/components/common/ModalConfirmation";

class ServiceCatForm extends React.Component {
    state = {
        activeTab: 0,
        languages: this.props.languages,
    };

    onSubmit = (formValues) => {
        let formToPost = {
            ...formValues,
            color1: formValues.color1.hex || formValues.color1,
            color2: formValues.color2.hex || formValues.color2,
            order: parseInt(formValues.order),
        };
        this.props.onSubmit(formToPost);
    };

    handleModalDelete = (id) => {
        this.props.onDelete(id);
    };

    toggleTab = (tab) => {
        const {activeTab} = this.state;
        activeTab !== tab && this.setState({activeTab: tab});
    };

    checkErrorInTabs() {
        const {formSyncErrors} = this.props;

        if (formSyncErrors.translations) {
            formSyncErrors.translations.some((item, i) => 
                item && this.state.activeTab !== i && this.setState({activeTab: i})
            )
        }
    }

    renderTabHeader() {
        const {activeTab, languages} = this.state;

        return languages.map((item, index) => {
            return (
                <NavItem key={index}>
                    <NavLink className={classnames({active: activeTab === index})} onClick={() => this.toggleTab(index)}>
                        {item.label}
                    </NavLink>
                </NavItem>
            );
        });
    }

    renderTabContent() {
        const {languages} = this.state;
        const {t} = this.props;
        
        return languages.map((item, index) => {
            return (
                <TabPane key={index} tabId={index}>
                    <Field
                        name={`translations[${index}].language`}
                        id={`translations[${index}].language`}
                        component={HiddenElement}
                        hiddenValue={item.value}
                    />
                    <Field
                        name={`translations[${index}].name`}
                        id={`translations[${index}].name`}
                        component={InputElement}
                        label={t("serviceCatForm->labelName")}
                        validate={[required, maxLength50]}
                    />
                </TabPane>
            );
        });
    }

    renderColGeneral() {
        const {
            t,
            initialValues: {isEnabled},
        } = this.props;

        return (
            <Card>
                <CardBody>
                    <div className='card__title'>
                        <h5 className='bold-text'>{t("serviceCatForm->formTitle1")}</h5>
                    </div>

                    <Field
                        name='isEnabled'
                        id='isEnabled'
                        component={CheckBoxElement}
                        defaultChecked={isEnabled}
                        label={t("serviceCatForm->labelEnabled")}
                        className='colored-click'
                    />

                    <Field
                        name='order'
                        id='order'
                        component={InputElement}
                        label={t("serviceCatForm->labelOrder")}
                        numeric
                        validate={required}
                    />

                    <Field
                        name='color1'
                        id='color1'
                        component={SketchColorPicker}
                        label={t("serviceCatForm->colorValue")}
                        validate={required}
                    />

                    <Field
                        name='color2'
                        id='color2'
                        component={SketchColorPicker}
                        label={t("serviceCatForm->colorEndValue")}
                        validate={required}
                    />
                </CardBody>
            </Card>
        );
    }

    renderColTranslations() {
        const {t} = this.props;
        const {activeTab} = this.state;

        return (
            <Card>
                <CardBody>
                    <div className='card__title'>
                        <h5 className='bold-text'>{t("serviceCatForm->formTitle2")}</h5>
                    </div>
                    <div className='tabs tabs--justify tabs--bordered-bottom'>
                        <div className='tabs__wrap'>
                            <Nav tabs>{this.renderTabHeader()}</Nav>
                            <TabContent activeTab={activeTab}>{this.renderTabContent()}</TabContent>
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }

    render() {
        const {t, id, btnText, type, handleSubmit} = this.props;
        
        return (
            <Container>
                <form className='form d-block' onSubmit={handleSubmit(this.onSubmit)} noValidate>
                    <Row>
                        <Col md={6}>
                            {this.renderColGeneral()}
                        </Col>
                        <Col md={6}>
                            {this.renderColTranslations()}
                        </Col>
                    </Row>
                    <ButtonToolbar className='form__button-toolbar'>
                        <Button color='primary' type='submit' onClick={() => this.checkErrorInTabs()}>
                            {btnText}
                        </Button>
                        {type === "edit" && (
                            <ModalConfirmation
                                backdrop='static'
                                color='danger'
                                firstBtnText={t("modal:modal->buttonNo")}
                                secondBtnText={t("modal:modal->buttonYes")}
                                title={t("serviceCatList->modalDeleteTitle")}
                                btn={t("serviceCatForm->btnDelete")}
                                message={<p>{t("serviceCatList->modalDeleteMessage")}</p>}
                                handleOnClick={() => this.handleModalDelete(id)}
                            />
                        )}
                        <Link className='btn btn-secondary' to='/vvservices/categories'>
                            {t("serviceCatForm->btnCancel")}
                        </Link>
                    </ButtonToolbar>
                </form>
            </Container>
        );
    }
}

const selector = formValueSelector("serviceCatForm");

const mapStateToProps = (state) => {
    return {
        id: selector(state, 'id'),
        formSyncErrors: getFormSyncErrors('serviceCatForm')(state)
    };
};
const translationWrapped = withTranslation(["servicesCat", "modal"])(ServiceCatForm);

const formWrapped = reduxForm({
    form: "serviceCatForm",
})(translationWrapped);

export default connect(mapStateToProps)(formWrapped);
